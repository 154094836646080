import React, {useRef, useState} from "react";

import "./MyManagerPage.sass"
import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";
import {useTranslation} from "react-i18next";
import KeyValuePaire from "../../components/KeyValuePaire/KeyValuePaire";
import {useAppSelector} from "../../hooks";
import {ManagerPosition} from "../../enums/ManagerPosition";
import {Button, ButtonToolbar, Form, InputPicker, Modal, Input, Schema} from "rsuite";
import complainServices from "../../services/complainServices";
import {FormInstance} from "rsuite/esm/Form/Form";

const Textarea = React.forwardRef((props, ref: any) => <Input {...props} as="textarea" ref={ref} />);

const { StringType, NumberType } = Schema.Types;


const TextField = React.forwardRef((props: any, ref: any) => {
    const { name, label, message, accepter, ...rest } = props;
    return (
        <Form.Group controlId={`${name}`}>
            <Form.Control name={name} accepter={accepter} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const MyManagerPage:React.FC = () => {
    const {t} = useTranslation();

    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});
    const errorField = t('form.requiredFieldMsg')
    const model = Schema.Model({
        name: NumberType().isRequired(errorField),
        msg: StringType().isRequired(errorField)
    });

    const managers = useAppSelector(state => state.user.value.managers)

    const [open, setOpen] = useState(false);
    const [isComplaintSent, setIsComplaintSent] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () =>{
        setOpen(false)
        setIsComplaintSent(false)
    }

    const selectData = managers.map(item => ({
        label: item.name,
        value: item.id
    }));

    const [complainBody, setComplainBody] = useState({
        managerId: 0,
        text: ''
    })

    const setName = (id: number , event: any) => {
        if (id) setComplainBody({...complainBody, managerId: id})
    }

    const setMsg = (value: string , event: any) => {
        if (value) setComplainBody({...complainBody, text: value})
    }



    const sendComplain = () => {

        if (formRef.current!.check()) {
            complainServices.addComplain(complainBody).then( () => {
                setIsComplaintSent(true)
            })
        }

    }

    return (
        <div className="my-managers-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-wrapper">
                    <div className="page-title">
                        <h2>{t('profile.MyManagers')}</h2>
                    </div>

                    {managers.map((item, index) => {
                        return (
                            <div className="me-manager-wrapper" key={index}>
                                <h3>
                                    {item.position == ManagerPosition.Manager ? t('managerPosition.manager') : null}
                                    {item.position == ManagerPosition.RegionalRepresentative ? t('managerPosition.regionalRepresentative') : null}
                                </h3>
                                <div>
                                    <div className="manager-wrapper-info manager-wrapper-name">
                                        <KeyValuePaire label={t('form.firstName')} value={item.name} />
                                    </div>
                                    <div className="manager-wrapper-info manager-wrapper-phone">
                                        <KeyValuePaire label={t('finishedOrders.phone')} value={item.phone} />
                                    </div>
                                    <div className="manager-wrapper-info manager-wrapper-email">
                                        <KeyValuePaire label={t('form.email')} value={item.email} />
                                    </div>

                                    <div className="info-manager-item">
                                        {item.phone ? <a href={`tel:${item.phone}`} className="btn-circle btn-phone"></a> : null}
                                        {item.email ? <a href={`mailto:${item.email}`} className="btn-circle btn-email"></a> : null}
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>


                <div className="question-wrapper">
                    <div>{t('complaint.complaintTitle')}</div>
                    <Button onClick={handleOpen} className="btn btn-lg">{t('complaint.sendComplaint')}</Button>
                </div>

                <Modal open={open} onClose={handleClose} className="modal-complain">
                    <Modal.Header>
                        <Modal.Title>{t('complaint.complaint')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isComplaintSent ? <h5 className="text-center">{t('complaint.complaintSent')}</h5> : <Form
                            ref={formRef}
                            model={model}
                            onCheck={setFormError}>
                            <TextField
                                name="name"
                                placeholder={t('complaint.chooseManager')}
                                accepter={InputPicker}
                                data={selectData}
                                onChange={setName}
                            />
                            <TextField
                                name="msg"
                                className="complain-msg"
                                placeholder={t('complaint.msg')}
                                accepter={Textarea}
                                onChange={setMsg}
                            />
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button
                                        disabled={Object.keys(formError).length ? true: false}
                                        type="submit"
                                        onClick={sendComplain}
                                        className="btn btn-lg"
                                        appearance="primary">
                                        {t('complaint.send')}
                                    </Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>}


                    </Modal.Body>
                </Modal>


            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default MyManagerPage