import React, {useEffect, useState} from "react";

import "./TableBottom.sass"
import {Dropdown, FlexboxGrid, Pagination} from "rsuite";
import Paging from "../../interfaces/Pagination";
import {useTranslation} from "react-i18next";

const TableBottom:React.FC<{onPageSizeChange: Function, onActivePageChange: Function, totalItemCount: number, pageSize?: number, currentPage: number}> = (
    {onPageSizeChange, onActivePageChange, totalItemCount, pageSize, currentPage}) => {
    const {t} = useTranslation();

    const [paging, setPaging] = useState<Paging>({
        totalPageCount: totalItemCount / (pageSize ?? 25),
        totalItemCount: totalItemCount,
        currentPageNumber: currentPage ?? 1,
        pageSize: pageSize ?? 25
    })

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)
    const [isMobileView, setIsMobileView] = useState(false)
    const [paginationMaxButtons, setPaginationMaxButtons] = useState(5)
    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
            setPaginationMaxButtons(3)
        }
        else {
            setIsMobileView(false)
            setPaginationMaxButtons(5)
        }

    }, [deviceWidth])

    useEffect(() => {
        setPaging({...paging, totalItemCount: totalItemCount, totalPageCount: totalItemCount / paging.pageSize})
    }, [totalItemCount]);

    const onChangePageSizeKey = (key: string, event: any) => {
        setPaging({...paging, pageSize: +key})

        onPageSizeChange(key)
    }
    const setActivePage = (page: number) => {
        setPaging({...paging, currentPageNumber: page})
        onActivePageChange(page)
    }

    return (
        <div>
            <FlexboxGrid justify="space-between">
            {!isMobileView ?
                <FlexboxGrid.Item className="show-items-wrapper">
                    {t('order.showProducts')}:
                    <Dropdown
                        onSelect={onChangePageSizeKey}
                        activeKey={paging.pageSize}
                        title={paging.pageSize}
                        placement="topStart"
                    >
                        <Dropdown.Item eventKey={25} onSelect={onChangePageSizeKey}>25</Dropdown.Item>
                        <Dropdown.Item eventKey={50} onSelect={onChangePageSizeKey}>50</Dropdown.Item>
                        <Dropdown.Item eventKey={75} onSelect={onChangePageSizeKey}>75</Dropdown.Item>
                        <Dropdown.Item eventKey={100} onSelect={onChangePageSizeKey}>100</Dropdown.Item>
                        <Dropdown.Item eventKey={125} onSelect={onChangePageSizeKey}>125</Dropdown.Item>
                    </Dropdown>
                </FlexboxGrid.Item> : null }


                <Pagination
                    prev
                    last
                    next
                    first
                    maxButtons={paginationMaxButtons}
                    className={"pagination-wrapper"}
                    total={paging.totalItemCount}
                    limit={paging.pageSize}
                    activePage={paging.currentPageNumber}
                    onChangePage={setActivePage} />
            </FlexboxGrid>
        </div>
    )
}
export default TableBottom