import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import AuthProvider from "../../services/AuthProvider";


const Auth: React.FC<{allowedPermission: string}> = ({ allowedPermission }) => {
    const location = useLocation();

    return AuthProvider.getPermissions().find((perm) => perm.toLowerCase() == allowedPermission.toLowerCase()) ? (
        <Outlet />
    ) : (
        <Navigate to="/" state={{ from: location }} replace />
    );
};

export default Auth;