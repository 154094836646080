import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SelectedProperty} from "../interfaces/Filters/SelectedFilters";

const initVal: SelectedProperty[] = []

export const chosenFilterSlice = createSlice({
    name: 'chosenFilters',
    initialState: {
        value: initVal,
    },
    reducers: {
        setChosenFiltersObj: (state, action: PayloadAction<SelectedProperty[]>) => {
            state.value = action.payload
        }
    }
})

export const { setChosenFiltersObj } = chosenFilterSlice.actions

export default chosenFilterSlice.reducer