import React, {useEffect, useState} from "react";
import {Col, Row} from "rsuite";

import './ComparisonPropertiesRow.sass'
import {ComparisonProduct} from "../../interfaces/ComparisonCategory";


const ComparisonPropertiesRow: React.FC<{propertyName: string, category: ComparisonProduct[], propertyIndex: number, showDifference: boolean}> = ({propertyName, category, propertyIndex, showDifference}) => {

    const lowercaseFirstLetter = (str: string) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }



    let propertyValue = '';

        let tempIsEqual = true;
        category.forEach((product, idx) => {
            if (idx == 0){
                propertyValue = product.properties[lowercaseFirstLetter(propertyName)]
            }

            if (propertyValue !== product.properties[lowercaseFirstLetter(propertyName)]) {
                tempIsEqual = false
            }
        })
    const [isEqual, setIsEqual] = useState(tempIsEqual)


    return !showDifference || (showDifference && !isEqual)  ? (
         <Row className="comparison-property-product">
                {category.map((product, idx) => {
                        return(
                            <Col key={idx} xs={24} sm={8} md={6}>
                                {idx == 0 ? <strong className="comparison-property_name">{propertyName}</strong> : null }
                                <div key={propertyIndex}>
                                    <strong className="comparison-property_value">{product.properties[lowercaseFirstLetter(propertyName)]}</strong>
                                </div>
                            </Col>
                        )
                    }
                )}
            </Row>

    ) : null
}

export default ComparisonPropertiesRow