import React, {useState, useEffect, Suspense, lazy} from "react";
import {
    createColumnHelper,
    /*flexRender,
    getCoreRowModel,
    useReactTable,*/
} from '@tanstack/react-table'
import {useTranslation} from "react-i18next";

import SelectedFiltersModel from "../../dto/products/SelectedFiltersModel"

/*import Product from "../../interfaces/Product/Product";*/
/*import {NavLink} from "react-router-dom";*/
import {Button, Dropdown, FlexboxGrid, Modal} from "rsuite";

import Table from "../Table/Table";
/*const Table = lazy(() => import('../Table/Table'));*/

/*import ProductCountTable from "../ProductCountTable/ProductCountTable";*/
import Breadcrumbs from "../../blocks/Breadcrumbs/Breadcrumbs"
import Subscription from "../Subscription/Subscription";
/*import AddToComare from "../AddToComare/AddToComare";*/




/*import SubscribeModal from "../SubscribeModal/SubscribeModal";*/
import {useAppDispatch, useAppSelector} from "../../hooks";
import Pagination from "../../interfaces/Pagination";
import {setPagination} from "../../slices/paginationSlice";
import {setProductOrder} from "../../slices/productOrderSlice"

import {ProductOrderBy} from "../../enums/ProductOrder"

import "./ProductList.sass"
import {ProductEntity} from "../../interfaces/Product/ProductEntity";
import {setStateFilters} from "../../slices/filterStateSlice";
import Loading from "../Loading/Loading";
import subscriptionservices from "../../services/subscriptionservices";
import SubscriptionModel from "../../interfaces/SubscriptionModel";
import {useParams, useSearchParams} from "react-router-dom";
import FormSubscribeModal from "../SubscribeModal/FormSubscribeModal";
import ExelOrderModal from "../ExelOrderModal/ExelOrderModal";

const Tile = lazy(() => import('../Tile/Tile'));

const columnHelper = createColumnHelper<ProductEntity>()


const ProductList: React.FC<{products:ProductEntity[]}> = ({products}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    let {categoryId} = useParams();

    const paging = useAppSelector<Pagination>((state) => state.pagination.value);
    const productOrderBy = useAppSelector<ProductOrderBy>(state => state.productOrder.value);
    const itemsFilterCount = useAppSelector(state => state.chosenFilters.value)
    const categoryName = useAppSelector(state => state.categoryName.value)

    const onChangePageSize = (key: string, event: any) => {
        dispatch(setPagination({
            "totalPageCount": paging.totalPageCount,
            "totalItemCount": paging.totalItemCount,
            "currentPageNumber": 1,
            "pageSize": +key,
        }))
        setSearchParams('page=1')
    }

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)

    const [isMobileView, setIsMobileView] = useState(true)

    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    const orderObject = []
    orderObject[ProductOrderBy.ByNameAsc] = t('sort.alphabetUp')
    orderObject[ProductOrderBy.ByNameDesc] = t('sort.alphabetDown')
    orderObject[ProductOrderBy.Recommended] = t('sort.popular')
    orderObject[ProductOrderBy.ByPriceDesc] = t('sort.priceUpFirst')
    orderObject[ProductOrderBy.ByPriceAsc] = t('sort.priceDownFirst')
    orderObject[ProductOrderBy.Discounted] = t('sort.discounted')
    orderObject[ProductOrderBy.ByDateDesc] = t('sort.byDateDesc')

    const onChangeProductOrder = (key: string, event: any) => {
        dispatch(setProductOrder(+key))
    }

    const [sortKey, setSortKey] = useState('')
    const onChangeProductOrderKey = (key: string, event: any) => {
        setSortKey(key)
    }

    const [changePageSize, setChangePageSize] = useState('')
    const onChangePageSizeKey = (key: string, event: any) => {
        setChangePageSize(key)
    }


    const [data, setData] = useState(products)


    useEffect(() => {
        setData(products)
    }, [products])

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [viewCatalog, setViewCatalog] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const param = searchParams.get("view")
        if(param == 'tile'){
            setViewCatalog(false)
        }

    }, [])


    const viewCatalogTile = () => {
        setViewCatalog(false)
        setSearchParams("view=tile")
    }
    const viewCatalogTable = () => {
        setViewCatalog(true)
        setSearchParams("view=table")
    }

    /*const filterInfo = useAppSelector(state => state.filters.value)
    const chosenFilters = useAppSelector(state => state.chosenFilters.value)

    const subscribe = () => {
        const data: SubscriptionModel = {
            type: 0,
            phone: '',
            filters: {
            "fromPrice": filterInfo.priceFrom,
            "toPrice": filterInfo.priceTo,
            "sortOrder": 0,
            "id": null,
            "categoryId": null,
            "propertyFilters": chosenFilters,
            "customFilters": {
                "trended": true,
                "similarClients": true,
                "usuallyBuyWith": 0,
                "similarClientsBuyWith": 0,
                "newItemsFor": {
                    "from": 'string',
                    "to": 'string'
                },
                "iBoughtFor": {
                    "from": 'string',
                    "to": 'string'
                },
                "iBoughtInOrder": 0
            },
            "page": 0,
            "itemsPerPage": 0,
        }
        }

        subscriptionservices.addSubscription(data).then()
    }*/


    const [openModalExel, setOpenModalExel] = useState(false)

    const showModalExelOrder = () => {
        setOpenModalExel(true)
    }
    const handleCloseExelModal = () => {
        setOpenModalExel(false)
    }

    let breadcrumbs = [
        {'link': '/',
            'linkText': t('main')
        },
        {
            'link': null,
            'linkText': categoryName
        }
        ]


    return (
        <div className={"page-category"}>
            <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

            <div className="show-grid title-catalog-wrapper">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <h1>{categoryName ? categoryName : t('allProducts')} <span>{paging.totalItemCount}</span></h1>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Subscription disableNewProducts={false} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
            <div className="show-grid info-catalog-wrapper">

                {isMobileView ? <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item className={"products-filter-wrapper"}>
                        <button
                            className={"btn btn-lg"}
                            onClick={() => dispatch(setStateFilters(true))}>
                            {t('filters.title')}
                            {itemsFilterCount.length != 0 ? <span className="total-chosen-filters">{itemsFilterCount.length}</span> : null}
                        </button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className={"products-oderby-wrapper"}>
                        <Dropdown className={"btn btn-lg"} activeKey={sortKey} onSelect={onChangeProductOrder} title={orderObject[productOrderBy]}>
                            <Dropdown.Item eventKey={ProductOrderBy.Recommended} onSelect={onChangeProductOrderKey}>{t('sort.popular')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByNameAsc} onSelect={onChangeProductOrderKey}>{t('sort.alphabetUp')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByNameDesc} onSelect={onChangeProductOrderKey}>{t('sort.alphabetDown')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByPriceAsc} onSelect={onChangeProductOrderKey}>{t('sort.priceDownFirst')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByPriceDesc} onSelect={onChangeProductOrderKey}>{t('sort.priceUpFirst')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.Discounted} onSelect={onChangeProductOrderKey}>{t('sort.discounted')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByDateDesc} onSelect={onChangeProductOrderKey}>{t('sort.byDateDesc')}</Dropdown.Item>
                        </Dropdown>
                    </FlexboxGrid.Item>
                </FlexboxGrid> : null}

                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={2}>
                        {viewCatalog ? <Button onClick={handleOpen}>{t('tableSettings')}</Button> : ''}
                    </FlexboxGrid.Item>
                    {!isMobileView ? <FlexboxGrid.Item >
                        {t('sort.sort')}:&nbsp;
                        <Dropdown onSelect={onChangeProductOrder} activeKey={sortKey} title={orderObject[productOrderBy]}>
                            <Dropdown.Item eventKey={ProductOrderBy.Recommended} onSelect={onChangeProductOrderKey}>{t('sort.popular')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByNameAsc} onSelect={onChangeProductOrderKey}>{t('sort.alphabetUp')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByNameDesc} onSelect={onChangeProductOrderKey}>{t('sort.alphabetDown')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByPriceAsc} onSelect={onChangeProductOrderKey}>{t('sort.priceDownFirst')}</Dropdown.Item>
                            <Dropdown.Item eventKey={ProductOrderBy.ByPriceDesc} onSelect={onChangeProductOrderKey}>{t('sort.priceUpFirst')}</Dropdown.Item>
                            {/*<Dropdown.Item eventKey={ProductOrderBy.Discounted} onSelect={onChangeProductOrderKey}>{t('sort.discounted')}</Dropdown.Item>*/}
                            <Dropdown.Item eventKey={ProductOrderBy.ByDateDesc} onSelect={onChangeProductOrderKey}>{t('sort.byDateDesc')}</Dropdown.Item>
                        </Dropdown>
                    </FlexboxGrid.Item> : null}


                    <FlexboxGrid.Item>
                        <Button onClick={showModalExelOrder} className={"exel-order"}>{t('tableProducts.exelOrder')}</Button>
                    </FlexboxGrid.Item>

                    <ExelOrderModal openModalExel={openModalExel} handleCloseExelModal={handleCloseExelModal} />

                    <FlexboxGrid.Item>
                        {t('tableProducts.onPage')}:&nbsp;
                        <Dropdown onSelect={onChangePageSize} activeKey={changePageSize} title={paging.pageSize + ' ' + t('products')}>
                            <Dropdown.Item eventKey={25} onSelect={onChangePageSizeKey}>25 {t('products')}</Dropdown.Item>
                            <Dropdown.Item eventKey={50} onSelect={onChangePageSizeKey}>50 {t('products')}</Dropdown.Item>
                            <Dropdown.Item eventKey={75} onSelect={onChangePageSizeKey}>75 {t('products')}</Dropdown.Item>
                            <Dropdown.Item eventKey={100} onSelect={onChangePageSizeKey}>100 {t('products')}</Dropdown.Item>
                            <Dropdown.Item eventKey={125} onSelect={onChangePageSizeKey}>125 {t('products')}</Dropdown.Item>
                        </Dropdown>
                    </FlexboxGrid.Item>

                    {!isMobileView ? <FlexboxGrid.Item className={"view-wrapper"}>
                        <button onClick={viewCatalogTable} className={viewCatalog ? "tableView active" : "tableView"}></button>
                        <button onClick={viewCatalogTile} className={viewCatalog ? "tileView" : "tileView active"}></button>
                    </FlexboxGrid.Item> : null}


                </FlexboxGrid>
            </div>


            {viewCatalog ? <Table data={data} openModal={open} handleClose={handleClose} pagination={true} /> : <Suspense fallback={<Loading />}><Tile data={data} pagination={true} /></Suspense> }


        </div>
    )
}

export default ProductList;