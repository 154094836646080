import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Category, ViewedCategories} from "../../interfaces/Category";
import CategoriesList from "./CategoriesList"

import "./CategoryListMenu.sass"
import {recentlyViewedCategorySlice} from "../../slices/recentlyViewedCategorySlice";
import {useAppSelector} from "../../hooks";
import {NavLink} from "react-router-dom";


const CategoryListMenu: React.FC<{
    children: Category[],
    closeMenu: Function,
    mobileView: boolean,
}> = ({children, closeMenu, mobileView}) => {
    const {t} = useTranslation();

    const [initCategoryList, setInitCategoryList] = useState<Category[]>([...children])

    const recentlyViewedCategory = useAppSelector<ViewedCategories[]>((state) => state.recentlyViewedCategory.value)


    const goToFirstLevel = () => {
        setInitCategoryList([...children])
    }
    return (
        <div className={"category-list-menu"}>

            <div className={"category-menu child-0"}>
                <CategoriesList
                    children={initCategoryList}
                    level={0} closeMenu={closeMenu}
                    mobileView={mobileView}
                    goToFirstLevel={goToFirstLevel}
                />
            </div>

            <div className={"recent-list_category"}>
                <h3>{t('recentlyViewed')}</h3>
                <ul>
                {recentlyViewedCategory.map((category, idx) => {
                    return (
                        <li key={idx}><NavLink to={`/category/${category.categoryId}?recentlyViewed=true`}>{category.categoryName}</NavLink></li>
                    )
                })}
                </ul>
            </div>
        </div>

    )
}
export default CategoryListMenu