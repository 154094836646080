import React, {Fragment} from "react";
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
    Row,
} from '@tanstack/react-table'

import Subscriptions from "../../dto/Subscriptions";
import {useTranslation} from "react-i18next";
import Type from "./Cells/Type/Type";
import TableSubscriptionProducts from "./TableSubscriptionProducts/TableSubscriptionProducts";
import GenericButton from "../Buttons/GenericButton";

type TableProps<TData> = {
    data: TData[]
    columns: ColumnDef<TData>[]
    renderSubComponent: (props: { row: Row<TData> }) => React.ReactElement
    getRowCanExpand: (row: Row<TData>) => boolean
}

const TableSubscription2:React.FC<{data: Subscriptions[], deleteSubscription: Function}> = ({data, deleteSubscription}) => {
    const {t} = useTranslation();




    const columns: ColumnDef<Subscriptions>[] = [
        {
            id: 'category',
            accessorFn: row => row.category,
            cell: ({ row, getValue }) => <span className="subscriptions-category">{getValue<string>()}</span>,
            header: () => <span className="category">{t('subscriptions.category')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'properties',
            accessorFn: row => row.properties,
            cell: ({ row, getValue }) => <span className="subscriptions-properties-wrapper">
                {getValue<string[]>().length != 0 ? getValue<string[]>().map((item, index) => {
                    return (
                        <span key={index} className="subscriptions-properties">{item}</span>
                    )
                }) : '—'}
            </span>,
            header: () => <span className="property-item">{t('subscriptions.properties')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'type',
            accessorFn: row => row.type,
            cell: ({ row, getValue }) => <Type type={row.original.type}/>,
            header: () => <span>{t('subscriptions.type')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'date',
            accessorFn: row => row.date.date,
            cell: ({ row, getValue }) => <span>{getValue<string>()}</span>,
            header: () => <span>{t('subscriptions.dateCreation')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'products',
            accessorFn: row => row.products,
            cell: ({ row }) => {
                return row.original.products.length !== 0 ? (
                    <button
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: 'pointer' },
                        }}
                    >
                        <span className="show-hide">{row.getIsExpanded() ? 'Скрыть' : 'Показать'}</span>
                    </button>
                ) : (
                    ''
                )
            },
            header: () => <span>{t('subscriptions.products')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'delete',
            accessorFn: row => row.date.date,
            cell: ({ row, getValue }) => <GenericButton generic={"delete"} onClick={() => deleteSubscription(row.original.id)} isDisabled={false} showText={false} />,
            header: () => <span></span>,
            footer: props => props.column.id,
        },
    ]

    function Table({
                       data,
                       columns,
                       renderSubComponent,
                       getRowCanExpand,
                   }: TableProps<Subscriptions>): JSX.Element {
        const table = useReactTable<Subscriptions>({
            data,
            columns,
            getRowCanExpand,
            getCoreRowModel: getCoreRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
        })

        return (
            <div className="table-responsive table-subscriptions">
                <table>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <div>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </div>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <Fragment key={row.id}>
                                <tr>
                                    {/* first row is a normal row */}
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                                {row.getIsExpanded() && (
                                    <tr>
                                        {/* 2nd row is a custom 1 cell row */}
                                        <td colSpan={row.getVisibleCells().length}>
                                            {renderSubComponent({ row })}
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderSubComponent = ({ row }: { row: Row<Subscriptions> }) => {
        return (
            <TableSubscriptionProducts data={row.original.products} subscriptionId={row.original.id} />
        )
    }


    return (
        <div>
            <Table
                data={data}
                columns={columns}
                getRowCanExpand={() => true}
                renderSubComponent={renderSubComponent}
            />
        </div>
    )
}
export default TableSubscription2