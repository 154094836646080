import React, {useEffect, useState} from "react";
import {Table} from "rsuite";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ReturnProductModel from "../../dto/return/ReturnProductModel";

import NoImage from '../../assets/img/no-img_small.svg';

const { Column, HeaderCell, Cell } = Table;

const CellImage:React.FC<{ rowData?: {[key: string]: string}, dataKey: string}> = (
    {rowData, dataKey,  ...props}) => (
    <Cell {...props}>
        <div className="img">
            <img src={rowData!['photoUrl'] ? rowData!['photoUrl'] : NoImage }  width={"38px"} alt=""/>
        </div>
    </Cell>
)

const TableCreatedReturn:React.FC<{data: ReturnProductModel[] }> = ({data}) => {

    const {t} = useTranslation();

    const [deviceHeight, setDeviceHeight] =  useState(window.innerHeight)

    const navigate = useNavigate()

    useEffect(() => {
        let height = deviceHeight - 380
        setDeviceHeight(height)
    }, [])

    return (
        <div className="table-return-wrapper">
            <div className="table-responsive">
                <Table
                    data={data}
                    id="table"
                    wordWrap="break-word"
                    onRowClick={(data) => {
                        navigate('/product/' + data.productId)
                    }}
                >

                    <Column width={130} verticalAlign="middle">
                        <HeaderCell>{t('productsTableHeader.vendorCode')}</HeaderCell>
                        <Cell dataKey="vendorCode" className="vendor-code-cell" />
                    </Column>

                    <Column width={60} verticalAlign="middle">
                        <HeaderCell>{t('productsTableHeader.url')}</HeaderCell>
                        <CellImage dataKey="photoUrl" />
                    </Column>

                    <Column flexGrow={4} verticalAlign="middle">
                        <HeaderCell>{t('productsTableHeader.name')}</HeaderCell>
                        <Cell dataKey="productName" className="product-name-cell" />
                    </Column>

                    <Column flexGrow={3} verticalAlign="middle">
                        <HeaderCell>{t('tableHeader.comment')}</HeaderCell>
                        <Cell dataKey="comment" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell>{t('tableHeader.count')}</HeaderCell>
                        <Cell dataKey="count" />
                    </Column>

                </Table>
            </div>
        </div>
    )
}
export default TableCreatedReturn