import React from "react";
import {Modal} from "rsuite";
import {useTranslation} from "react-i18next";

import ShippingAddress from "../../../interfaces/ShippingAddress";
import OrderProductsDelivery from "../../OrderProducts/OrderProductsDelivery/OrderProductsDelivery";

import "./ProfileShippingModal.sass"


const ProfileShippingModal: React.FC<{editShippingData: ShippingAddress | null, openModal: boolean, handleCloseShippingModal: Function, addShippingData: Function}> = (
    {editShippingData, openModal, handleCloseShippingModal, addShippingData}) => {
    const { t } = useTranslation();

    const closeModal = (data: ShippingAddress) => {
        addShippingData(data)
        handleCloseShippingModal()
    }


    return (
        <Modal
            open={openModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            onClose={() => handleCloseShippingModal()}
            className="modal-shipping">
            <Modal.Header>
                <Modal.Title id="modal-title">{t('order.addressShipping')}</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-description">
                 <OrderProductsDelivery
                     onChange={closeModal}
                     shippingData={editShippingData}
                     showPayOnDelivery={true}
                     weight={0}
                 />
            </Modal.Body>
        </Modal>
    )
}
export default ProfileShippingModal