import React from "react";
import { NavLink } from "react-router-dom";

import { Breadcrumb } from 'rsuite';

import "./Breadcrumbs.sass"

const Breadcrumbs: React.FC<{breadcrumbs: {link: string | null, linkText?: string}[]}> = (breadcrumbs) => {
    const MyBreadcrumb = () => (
        <Breadcrumb className={"breadcrumbs-wrapper"}>
            {breadcrumbs.breadcrumbs.map((item, index) => {
                return (
                    <Breadcrumb.Item key={index} active>
                        {item.link ? <NavLink to={item.link}  className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }>{item.linkText}</NavLink> : item.linkText}

                    </Breadcrumb.Item>
                )
            })}
            {/*<Breadcrumb.Item>
                <NavLink to="/"  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                }>Главная</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Breadcrumb</Breadcrumb.Item>*/}
        </Breadcrumb>
    );
    return (
        <div>
            <MyBreadcrumb  />
        </div>
    )
}
export default Breadcrumbs