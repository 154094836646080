import ShippingAddress from "../interfaces/ShippingAddress";
import PaginatedResult from "../dto/PaginatedResult";
import ApiHttpHandler from "./apiHttpHandler";

const handler = new ApiHttpHandler();

const getDeliveryAddress = (idDeliveryAddress: number) => {
    return handler.get(`delivery-addresses/${idDeliveryAddress}`)
}

const editDeliveryAddress = (data: ShippingAddress) => {
    return handler.put(`delivery-addresses/${data.id}`, data)
}

const getDeliveryAddresses = (searchTerm: string, page: number, pageSize: number): Promise<PaginatedResult<ShippingAddress>> => {
    return handler.get(`delivery-addresses?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}`)
}

const addDeliveryAddress = (data: ShippingAddress) => {
    return handler.post(`delivery-addresses`, data)
}

const deleteDeliveryAddress = (idDeliveryAddress: number) => {
    return handler.delete(`delivery-addresses/${idDeliveryAddress}`)
}

export default {
    getDeliveryAddress,
    editDeliveryAddress,
    getDeliveryAddresses,
    addDeliveryAddress,
    deleteDeliveryAddress
}