import React from "react";
import Header from "../../../blocks/Header/Header";
import {useTranslation} from "react-i18next";

import "./UpdateData.sass"
import adminServices from "../../../services/adminServices";

const UpdateData:React.FC = () => {
    const {t} = useTranslation();

    const updateData = (actionType: string) => {
        adminServices.updateData(actionType).then()
    }

    return (
        <div className="help-page-wrapper page-wrapper update-page">
            <Header></Header>
            <main>
                <div className="page-container">
                    <h1>Admin: {t('updates.updates')}</h1>
                    <div className="white-box">
                        <h6>{t('updates.updateAvailability')}:</h6>
                        <button onClick={() => updateData('updateAvailability')} className="btn-lg btn">{t('updates.updateAvailability')}</button>

                        <hr/>

                        <h6>{t('updates.updateExchangeRate')}:</h6>
                        <button onClick={() => updateData('updateExchangeRate')} className="btn-lg btn">{t('updates.updateExchangeRate')}</button>

                        <hr/>

                        <h6>{t('updates.updateCategories')}:</h6>
                        <button onClick={() => updateData('updateCategories')} className="btn-lg btn">{t('updates.updateCategories')}</button>

                        <hr/>

                        <h6>{t('updates.updateProducts')}:</h6>
                        <button onClick={() => updateData('updateProducts')} className="btn-lg btn">{t('updates.updateProducts')}</button>

                        <hr/>

                        <h6>{t('updates.updateUsers')}:</h6>
                        <button onClick={() => updateData('updateUsers')} className="btn-lg btn">{t('updates.updateUsers')}</button>

                        <hr/>

                        <h6>{t('updates.importImages')}:</h6>
                        <button onClick={() => updateData('importImages')} className="btn-lg btn">{t('updates.importImages')}</button>

                        <hr/>

                        <h6>{t('updates.reloadImages')}:</h6>
                        <button onClick={() => updateData('reloadImages')} className="btn-lg btn">{t('updates.reloadImages')}</button>

                    </div>
                </div>
            </main>
        </div>
    )
}
export default UpdateData