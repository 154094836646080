import React, {useState} from "react";
import {Modal} from "rsuite";
import {useTranslation} from "react-i18next";
import FormSubscribeModal from "./FormSubscribeModal";

const SubscribeModal: React.FC<{openModal: boolean, disableNewProducts: boolean, handleCloseSubscription: Function}> = (
    {openModal, disableNewProducts, handleCloseSubscription}) => {
    const { t } = useTranslation();

    const [isSubscribed, setIsSubscribed] = useState(true)

    return (
        <div className="subscribe-modal-wrapper">
            <Modal className={"subscribe-wrapper"} open={openModal} onClose={() => handleCloseSubscription()}>
                <Modal.Header>
                    <Modal.Title>
                        {isSubscribed ? t('isSubscribed.notSubscribed') : t('isSubscribed.subscribedTitle')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="inline-block border border-black shadow rounded">
                        <p className={"description"}>
                            {isSubscribed ? t('isSubscribed.notSubscribedDescription') : t('isSubscribed.subscribedDescription')}
                        </p>

                        {isSubscribed ? <FormSubscribeModal disableNewProducts={disableNewProducts} handleCloseSubscription={handleCloseSubscription} setIsSubscribed={setIsSubscribed} /> : ''}

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default SubscribeModal