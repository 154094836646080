import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ProductEntity} from "../interfaces/Product/ProductEntity";

const initialVal: ProductEntity[] = [];

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setProducts: (state, action: PayloadAction<ProductEntity[]>) => {
            state.value = action.payload
        }
    }
  }
);

export const { setProducts } = productSlice.actions


export default productSlice.reducer