import React, {useEffect, useState} from "react";

import productService from "../../services/productService";
import AutocompleteResults from "./AutocompleteResults";
import {use} from "i18next";
import {AutocompleteInterface} from "../../interfaces/Autocomplete";

import './Autocomplete.sass'
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

const Autocomplete: React.FC<{
    openSearch: Function,
    closeActiveSearch:  (data: boolean) => void,
    activeSearch: boolean,
    setCategoryList: Function }> = ({openSearch, closeActiveSearch, activeSearch, setCategoryList}) => {

    let timeoutHandle: ReturnType<typeof setTimeout>

    const [showResults, setShowResults] = useState(false)

    const [results, setResults] = useState<AutocompleteInterface>({products: [], categories: [], totalProducts: 0})

    const [message, setMessage] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!activeSearch && searchParams.get('query')){
            setShowResults(false)
            //setSearchParams('')
            setMessage('')
        }
    }, [activeSearch])


    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value
        setMessage(e.currentTarget.value);

        setSearchParams('query=' + e.currentTarget.value)

        if(val.length >= 3){
            clearTimeout(timeoutHandle)
            timeoutHandle = setTimeout(((query: string) => {
                productService.getAutocomplete(query).then(data => {
                    setResults(data)
                    setShowResults(true)
                })
            }).bind(null, val), 500)
        }
        else {
            setShowResults(false)
        }
    }

    const closeAutocomplete = () => {
        closeActiveSearch(false)
        setShowResults(false)
        setMessage('')
    }

    const toggleSearch = () => {
        openSearch(true)
        setCategoryList(false)
    }

    const { t } = useTranslation();

    const searchText = t('autocomplete.search')


    return (
        <section className={"autocomplete-block"}>
            <div className={"autocomplete-wrapper"}>
                <input
                    className={"search"}
                    type="text"
                    placeholder={searchText}
                    value={message}
                    onClick={toggleSearch}
                    onChange={handleChange}
                />
                {showResults ? <AutocompleteResults
                    data={results}
                    querySearch={message}
                    closeActiveSearch={closeActiveSearch}
                    setShowResults={setShowResults}
                /> : ''}
            </div>
            {activeSearch ? <button className={"closeAutocomlete"} onClick={closeAutocomplete}></button> : null}
            {activeSearch ? <div className={"overflow"} onClick={closeAutocomplete}></div> : null}
        </section>
    )
}
export default Autocomplete