import React, {useEffect, useState} from "react";


import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import SearchBlock from "../../components/SearchBlock/SearchBlock";
import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";

import returnServices from "../../services/returnServices";
import TableReturn from "../../components/TableReturn/TableReturn";
import {useTranslation} from "react-i18next";
import TableBottom from "../../components/TableBottom/TableBottom";
import {use} from "i18next";
import reclamationServices from "../../services/reclamationServices";
import ShortReturnModel from "../../dto/ShortReturnModel";
import {useNavigate, useSearchParams} from "react-router-dom";

import "./ReturnPage.sass"

const ReturnPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();


    const [searchParams, setSearchParams] = useSearchParams();
    let [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const param = searchParams.get("activetab")
        if(param){
            setActiveTab(+param)
        }

    }, [])

    const [tabReload, setTabReload] = useState(false)
    const reload = (index: number) => {
        setActiveTab(index)
        setSearchParams("activetab=" + index)
        if(index == 0) {
            setPageReclamationSize(pageReclamationSize)
        }
    }

    const [returns, setReturns] = useState<ShortReturnModel[]>([])

    const [valueSearchNewReturn, setValueSearchNewReturn] = useState('')

    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const changePageSize = (key: number) => {
        setPageSize(key)
    }
    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }

    const [valueSearchReturn, setValueSearchReturn] = useState('')
    const searchReturn = (value: string, event: any) => {
        setValueSearchReturn(value)
    }

    useEffect(() => {
        returnServices.getReturns(valueSearchReturn, currentPage, pageSize).then((data) => {
            setReturns(data.items)
            setTotalItems(data.totalItemCount);
        })
    }, [valueSearchReturn, currentPage, pageSize, tabReload])


    const searchNewReturn = (value: string, event: any) => {
        setValueSearchNewReturn(value)
    }

    const createNewReturn = () => {

    }

    const [reclamations, setReclamations] = useState<ShortReturnModel[]>([])

    const searchReclamations = (value: string, event: any) => {
        setValueSearchReclamations(value)
    }

    const [valueSearchReclamations, setValueSearchReclamations] = useState('')

    const [pageReclamationSize, setPageReclamationSize] = useState(25)
    const [currentReclamationPage, setCurrentReclamationPage] = useState(1)
    const [totalReclamationItems, setTotalReclamationItems] = useState(1)
    const changeReclamationPageSize = (key: number) => {
        setPageReclamationSize(key)
    }
    const changeReclamationActivePage = (key: number) => {
        setCurrentReclamationPage(key)
    }

    useEffect(() => {
        reclamationServices.getReclamations(valueSearchReclamations, currentReclamationPage, pageReclamationSize).then((data) => {
            setReclamations(data.items)
            setTotalReclamationItems(data.totalItemCount);
        })
    }, [valueSearchReclamations, currentReclamationPage, pageReclamationSize, tabReload])

    const createReclamation = () => {
        navigate('/create-reclamation');
    }



    const createReturn = () => {
        navigate('/create-return');
    }

    return (
        <div className="return-page-wrapper my-orders-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <Tabs selectedIndex={activeTab} onSelect={(activeTab) => reload(activeTab)}>
                        <TabList>
                            <Tab className="reclamation-tab react-tabs__tab">{t('return.reclamation')}</Tab>
                            <Tab>{t('return.return')}</Tab>
                        </TabList>

                        <TabPanel>

                            <SearchBlock
                                search={searchReclamations}
                                searchPlaceHolder={t('return.search')}
                                buttonClick={createReclamation}
                                buttonText={t('return.createReclamation')}
                                buttonState={false}
                            />

                            <div className="main-table">
                                <TableReturn data={reclamations} type={'reclamations'} />
                            </div>

                            <TableBottom
                                onPageSizeChange={changeReclamationPageSize}
                                onActivePageChange={changeReclamationActivePage}
                                totalItemCount={totalReclamationItems}
                                currentPage={currentReclamationPage}
                                pageSize={pageReclamationSize}
                            />

                        </TabPanel>
                        <TabPanel>

                            <SearchBlock
                                search={searchReturn}
                                searchPlaceHolder={t('return.search')}
                                buttonClick={createReturn}
                                buttonText={t('return.createReturn')}
                                buttonState={false}
                            />
                            <div className="main-table">
                                <TableReturn data={returns} type={'return'} />
                            </div>

                            <TableBottom
                                onPageSizeChange={changePageSize}
                                onActivePageChange={changeActivePage}
                                totalItemCount={totalItems}
                                currentPage={currentPage}
                                pageSize={pageSize}
                            />

                        </TabPanel>
                    </Tabs>
                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default ReturnPage