import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const categoryNameSlice = createSlice({
    name: 'categoryName',
    initialState: {
        value: ''
    },
    reducers: {
        setCategoryName: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        }
    }

})

export const {setCategoryName} = categoryNameSlice.actions

export default categoryNameSlice.reducer