import React, {useState} from "react";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import NoImage from "../../../assets/img/no-img_small.svg";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SubscriptionProductModel} from "../../../interfaces/SubscriptionProductModel";
import GenericButton from "../../Buttons/GenericButton";
import subscriptionservices from "../../../services/subscriptionservices";
import {useAppSelector} from "../../../hooks";
import ProductAvailable from "../../ProductAvailable/ProductAvailable";

const columnHelper = createColumnHelper<SubscriptionProductModel>();

const TableSubscriptionProducts:React.FC<{data: SubscriptionProductModel[], subscriptionId: number}> = ({data, subscriptionId}) => {
    const { t } = useTranslation();
    const wsRate = useAppSelector<number>((state) => state.wsRate.value)

    const deleteItem = (subscriptionId: number, id: number) => {
        subscriptionservices.deleteSubscriptionProduct(subscriptionId, id).then()
        let index = data.map((product) => product.id).indexOf(id)
        data.splice(index, 1)
    }


    const columnsCell = [
        columnHelper.accessor('vendorCode', {
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.vendorCode')}</span>,
        }),
        columnHelper.accessor(row => row.photoUrl, {
            id: 'url',
            cell: info => <img src={info.getValue() != null ?  info.getValue() : NoImage} width={"38px"} />,
            header: () => <span>{t('productsTableHeader.url')}</span>,
        }),
        columnHelper.accessor(row => row.name, {
            id: 'name',
            cell: info => <NavLink to={'/product/' + info.row.original.id} className={"name"}>{info.getValue()}</NavLink>,
            header: () => <span>{t('productsTableHeader.name')}</span>,
        }),
        columnHelper.accessor(row => row.recommendedRetailPrice, {
            id: 'rrc',
            cell: info => <span>{Math.round(info.getValue() * wsRate)} {t('uah')}</span>,
            header: () => <span>{t('productsTableHeader.rrc')}</span>,
        }),
        columnHelper.accessor(row => row.price, {
            id: 'price',
            cell: info => <span>{Number(info.getValue()).toFixed(2)} $</span>,
            header: () => <span>{t('productsTableHeader.price')}</span>,
        }),
        columnHelper.accessor(row => row.available, {
            id: 'left',
            cell: info => <span><ProductAvailable count={info.getValue()} dateAvailable={info.row.original.dateAvailable}></ProductAvailable></span>,
            header: () => <span>{t('productsTableHeader.left')}</span>,
        }),
        columnHelper.accessor(row => row.income, {
            id: 'income',
            cell: info => <span>{ (Number(info.row.original.recommendedRetailPrice) - Number(info.row.original.price)).toFixed(2)}  $</span>,
            header: () => <span>{t('productsTableHeader.income')}</span>,
        }),
        columnHelper.accessor(row => row.delete, {
            id: 'delete',
            cell: info => <GenericButton generic={"delete"} onClick={() => deleteItem(subscriptionId, info.row.original.id)} isDisabled={false} showText={false} />,
            header: () => <span></span>,
        })
    ]

    const [columns] = useState<typeof columnsCell>(() => [
        ...columnsCell,
    ])

    const [columnVisibility, setColumnVisibility] = useState({})


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    })

    return (
        <div className="table-responsive inner-table">
            <table>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th className={header.id} key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} className={cell.column.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}
export default TableSubscriptionProducts