import React from "react";

import Header from "../../blocks/Header/Header";

import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks";
import User from "../../interfaces/User";

import "./BalancePage.sass"
import {Tooltip, Whisper} from "rsuite";


const BalancePage:React.FC = () => {
    const {t} = useTranslation();
    const user = useAppSelector<User>(state => state.user.value)

    return (
        <div className="balance-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <h2>{t('order.topUpBalance')}</h2>

                    <div className="balance-page-info-wrapper">
                        <div className="balance-page-info">
                            <h3>{t('order.paymentDetails')}</h3>
                            {user.managers[0]?.bankDetails ? <div>

                                {user.managers[0]?.bankDetails.name
                                    ?
                                    <Whisper
                                        trigger="click"
                                        placement={'top'}
                                        speaker={
                                            <Tooltip>{t('orderSingle.copied')}</Tooltip>
                                        }
                                    >
                                    <div className="copy-text" onClick={() => navigator.clipboard.writeText(user.managers[0]?.bankDetails.name)}>
                                        {user.managers[0]?.bankDetails.name}
                                    </div>
                                    </Whisper>
                                    : null}
                                {user.managers[0]?.bankDetails.accountNumber ?
                                    <Whisper
                                        trigger="click"
                                        placement={'top'}
                                        speaker={
                                            <Tooltip>{t('orderSingle.copied')}</Tooltip>
                                        }
                                    >
                                    <div className="copy-text" onClick={() => navigator.clipboard.writeText(user.managers[0]?.bankDetails.accountNumber)}>
                                        IBAN {user.managers[0]?.bankDetails.accountNumber}
                                    </div>
                                    </Whisper>
                                    : null}
                                {user.managers[0]?.bankDetails.taxNumber ?
                                    <Whisper
                                        trigger="click"
                                        placement={'top'}
                                        speaker={
                                            <Tooltip>{t('orderSingle.copied')}</Tooltip>
                                        }
                                    >
                                    <div className="copy-text" onClick={() => navigator.clipboard.writeText(user.managers[0]?.bankDetails.taxNumber)}>
                                        Код ЄДРПОУ {user.managers[0]?.bankDetails.taxNumber}
                                    </div>
                                    </Whisper>
                                    : null}
                                {user.managers[0]?.bankDetails.bankNumber ?
                                    <Whisper
                                        trigger="click"
                                        placement={'top'}
                                        speaker={
                                            <Tooltip>{t('orderSingle.copied')}</Tooltip>
                                        }
                                    >
                                    <div className="copy-text" onClick={() => navigator.clipboard.writeText(user.managers[0]?.bankDetails.bankNumber)}>
                                        МФО {user.managers[0]?.bankDetails.bankNumber}
                                    </div>
                                    </Whisper>
                                    : null}

                            </div>: null}
                        </div>
                    </div>
                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}

export default BalancePage