import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {ProductEntity} from "../../../interfaces/Product/ProductEntity";
import TileProduct from "../../../components/Tile/TileProduct";
import {Col} from "rsuite";
import ShortProductModel from "../../../dto/products/ShortProductModel";


import "./ProductSliderUsuallyBoughtTogether.sass"

const ProductSliderUsuallyBoughtTogether: React.FC<{productInfo:ShortProductModel[]}> = ({productInfo}) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1275,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1020,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
         <div className="slider-usually_bought_together">
             <Slider {...settings}>
                 {productInfo.map((item, id) => {
                     return (
                             <Col key={id} xs={24} sm={8} md={4}>
                             <TileProduct
                                 key={id}
                                 product={ProductEntity.CreateProductEntityShort(item)}
                                 showAdditionalControls={false}
                                 deleteFromCompareList={null}
                             />
                             </Col>

                     )
                 })}
             </Slider>
         </div>
    );
}
export default  ProductSliderUsuallyBoughtTogether;
