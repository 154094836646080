import BaseHttpHandler from "./baseHttpHandler";
import DeliveryResponseWrapper from "../dto/delivery/DeliveryResponseWrapper";

export default class DeliveryHttpHandler extends BaseHttpHandler {

    constructor(){
        super(process.env.REACT_APP_DELIVERY_API_URL!, false, 'text/plain')
    }

    public fetchData(url: string, method: string, body?: string): Promise<any>{
        return this.doRequest(url, method, body).then(data => {
            return (data as DeliveryResponseWrapper).data;
        });
    }
}