import React, {useEffect, useRef, useState} from "react";
import {Form, Modal, Schema, Toggle} from "rsuite";
import {useTranslation} from "react-i18next";

import PhoneInput from "../../Form/PhoneInput/PhoneInput";


import "./ProfileContactPerson.sass"
import contactPersonServices from "../../../services/contactPersonServices";
import {ContactPerson} from "../../../interfaces/ContactPerson";

function TextField(props: any) {
    const { fieldName, label, value, setValue, accepter, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                accepter={accepter} {...rest}
            />
        </Form.Group>
    );
}

const ProfileContactPerson: React.FC<{editContactPersonData: ContactPerson | null, openModal: boolean, handleCloseContactPerson: Function}> = ({editContactPersonData, openModal, handleCloseContactPerson}) => {

    const { t } = useTranslation();
    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});

    const [contactDataFirstName, setContactDataName] = useState(editContactPersonData?.firstName)
    const [contactDataLastName, setContactDataLastName] = useState(editContactPersonData?.lastName)
    const [contactDataMiddleName, setContactDataMiddleName] = useState(editContactPersonData?.middleName)
    const [contactDataPhone, setContactDataPhone] = useState(editContactPersonData?.phone)

    const [formValue, setFormValue] = useState<Record<string, any>>({
        lastName: contactDataLastName,
        firstName: contactDataFirstName,
        middleName: contactDataMiddleName,
        phone: contactDataPhone,
        id: editContactPersonData ? editContactPersonData.id : 0
    });

    const handleSubmit = () => {


        if (!formValue.phone || formValue.phone.length != 12){
            setErrorPhoneVisible(true)
        }


        if (!formRef.current.check() || !contactDataPhone || contactDataPhone.length != 12) {
            console.error('Form Error', formRef.current);

            return;
        }

        if (editContactPersonData !== null) {

            setFormValue(prevState => ({...prevState, id: editContactPersonData.id, phone: contactDataPhone}))

            contactPersonServices.editContactPersons(editContactPersonData.id, contactPersonModel).then((data) => {

                handleCloseContactPerson(contactPersonModel, false)

            })


        } else {

            contactPersonServices.addContactPerson(contactPersonModel).then((data) => {

                setFormValue(prevState => ({...prevState, phone: contactDataPhone}))

                contactPersonModel.id = data

                handleCloseContactPerson(contactPersonModel, true)

            })
        }

    };


    const [errorPhoneVisible, setErrorPhoneVisible] = useState(false);
    const errorField = t('form.requiredFieldMsg')


    let contactPersonModel: ContactPerson = {
        firstName: formValue.firstName,
        middleName: formValue.middleName,
        lastName: formValue.lastName,
        phone: contactDataPhone ? contactDataPhone : '',
        id: formValue.id
    }

    const lastName = t('form.lastName')
    const firstName = t('form.firstName')
    const middleName = t('form.middleName')

    const { StringType } = Schema.Types;
    const errorSymbols = t('form.errorSymbols')
    const model = Schema.Model({
        firstName: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
        lastName:  StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
        middleName:  StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
    });

    const setContactPhone = (phoneValue: string) => {
        let phone = phoneValue.replace(/[\(\)\-\+\_ ]/g, '');

        setFormValue(prevState => ({...prevState, phone: phone}))
        setContactDataPhone(phone)

        if(phone.length != 12){
            setErrorPhoneVisible(true)
        } else {
            setErrorPhoneVisible(false)

        }
    }


    return (
        <Modal open={openModal}
                className={"modal-contact-data"}
               aria-labelledby="modal-title"
               aria-describedby="modal-description"
               onClose={() => handleCloseContactPerson()}>
            <Modal.Header>
                <Modal.Title id="modal-title">{t('profile.ContactPersons')}</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-description">
                <Form model={model}
                      ref={formRef}
                      onChange={(fv: Record<string, any>, event?: React.SyntheticEvent) => setFormValue(fv)}
                      onCheck={setFormError}
                      formValue={formValue}
                >
                    <div className={formError.hasOwnProperty('lastName') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField fieldName="lastName"
                                   label={lastName}
                                   value={contactDataLastName}
                                   setValue={setContactDataLastName}
                                   key={'lastName'}
                        />
                    </div>
                    <div className={formError.hasOwnProperty('firstName') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField fieldName="firstName" label={firstName} value={contactDataFirstName} setValue={setContactDataName} key={'firstName'} />
                    </div>
                    <div className="input-wrapper">
                        <TextField fieldName="middleName" label={middleName} value={contactDataMiddleName} setValue={setContactDataMiddleName} key={'middleName'} />
                    </div>
                    <div className={errorPhoneVisible ? "input-wrapper has-error" : "input-wrapper"}>
                        <label>{t('form.phone')}</label>
                        <Form.Group controlId={'contact-data_phone'}>
                            <PhoneInput valueFunction={setContactPhone} valuePhone={contactDataPhone} showError={errorPhoneVisible} />
                        </Form.Group>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn btn-lg">{t('btn.save')}</button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export default ProfileContactPerson