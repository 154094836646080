import ApiHttpHandler from "./apiHttpHandler";
import {VisibilityState} from "@tanstack/react-table";

const handler = new ApiHttpHandler();

const updateTableSettings = (visibilityState: VisibilityState) => {
    return handler.put(`tables-settings`, visibilityState)
}

export default {
    updateTableSettings
}