import ApiHttpHandler from "./apiHttpHandler";
import UserModel from "../dto/login/UserModel";

const handler = new ApiHttpHandler();

const setNotificationsStatus = (status: string) => {
    return handler.put(`user/send-notifications/${status}`)
}

const getMyProfile = (): Promise<UserModel> => {
    return handler.get('user/');
}

const updateUserData = (contactDataFirstName: string,
                        contactDataLastName: string,
                        contactDataMiddleName: string | null,
                        contactDataPhone: string): Promise<UserModel> => {
    return handler.put(`user/`, {
        "firstName": contactDataFirstName,
        "lastName": contactDataLastName,
        "middleName": contactDataMiddleName,
        "phone": contactDataPhone
    })
}

const updateNPKey = (key: string): Promise<{"isKeyValid": boolean, npClientName: string}> => {
    return handler.put(`user/check-np-key`, {
        "key": key
    })
}

const updateUserNPKeyState = (state: boolean) => {
    return handler.put(`user/np-settings`, {
        "useNPNameByDefault": state
    })
}

const deleteNpKey = () => {
    return handler.put(`user/delete-np-key`)
}

export default {
    setNotificationsStatus,
    getMyProfile,
    updateUserData,
    updateNPKey,
    updateUserNPKeyState,
    deleteNpKey
};