import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import Product from "../interfaces/Product/Product";
import {CartOrder, CartProduct} from "../interfaces/CartProduct";

const initialVal: CartOrder = {orderId:0, orderItems: []};

export const cartSlice = createSlice({
    name: 'reserve',
    initialState: {
        value: initialVal,
    },
    reducers: {
        clearReserve: (state, action: PayloadAction) => {
            state.value = initialVal
        },
        addToReserve: (state, action: PayloadAction<{product: Product, activeOrder: number}>) => {
            const cartProduct: CartProduct = {count: 1, product: action.payload.product}
            state.value.orderItems.push(cartProduct)
        },
        removeFromReserve: (state, action: PayloadAction<{productId: number, activeOrder: number}>) => {
                state.value.orderItems = state.value.orderItems.filter((s, i) => (s.product.id != action.payload.productId))
        },
        updateCountReserve: (state, action: PayloadAction<{productId: number, activeOrder: number, count: number}>) => {
                const orderItem = state.value.orderItems.find((item) => item.product.id == action.payload.productId)

                if (orderItem){
                    orderItem.count = action.payload.count
                }

        },
    }
});
export const { addToReserve, removeFromReserve, updateCountReserve, clearReserve } = cartSlice.actions;

export default cartSlice.reducer