import React, {useEffect, useRef, useState} from "react";
import {DatePicker, Form, Input, Schema, SelectPicker} from "rsuite";

import Map from "../../../Map/Map";

import "./DeliveryPickup.sass"
import {useTranslation} from "react-i18next";
import moment from "moment";
import PaymentAndPickupData from "../../../../interfaces/PaymentAndPickupData";
import orderService from "../../../../services/orderService";
import WorkingDayModel from "../../../../dto/order/WorkingDayModel";

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const DeliveryPickup: React.FC<{onChange: (data: PaymentAndPickupData) => void, paymentAndPickupData?: PaymentAndPickupData | null}> = ({onChange, paymentAndPickupData}) => {
    const {t} = useTranslation();
    const carModel = t('delivery.carModel')
    const carNumber = t('delivery.carNumber')
    const deliveryDate = t('delivery.deliveryDate')
    const deliveryTime = t('delivery.time')

    const [showMap, setShowMap] = useState(false)
    const [pickup, setPickup] = useState<PaymentAndPickupData>({
        carModel: paymentAndPickupData ? paymentAndPickupData.carModel : '',
        carNumber: paymentAndPickupData ? paymentAndPickupData.carNumber : '',
        pickupDate: paymentAndPickupData ? paymentAndPickupData.pickupDate : '',
        pickupTime: paymentAndPickupData ? paymentAndPickupData.pickupTime : ''
    })

    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});

    const { StringType, DateType } = Schema.Types;
    const errorField = t('form.requiredFieldMsg')
    const model = Schema.Model({
        carModel: StringType().isRequired(errorField),
        carNumber: StringType().isRequired(errorField),
        pickupDate: DateType().isRequired(errorField),
        pickupTime: DateType().isRequired(errorField),
    });
    const toggleMap = () => {
        setShowMap(show => !show);
    }

    const [workingHours, setWorkingHours] = useState<WorkingDayModel[]>([])
    const [pickupDate, setPickupDate] = useState<Date | null>(null)

    useEffect(() => {
        orderService.getWorkingHours().then(data => {
            setWorkingHours(data) //.map(d => { return {...d, date: new Date(d.date)}}))
        })
    }, [])
    /*const time = ['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30',
        '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30',
        '19:00', '19:30', '20:00', '20:30'].map(
        item => ({ label: item, value: item })
    );*/

    const carModelChange = (value: string , event: any) => {
        if (value) setPickup({...pickup, carModel: value})
    }
    const carNumberChange = (value: string , event: any) => {
        if (value) setPickup({...pickup, carNumber: value})
    }

    const pickupDateChange = (value: Date , event: any) => {
        setPickupDate(value)
        let momentDate = moment(value);
        if (value) setPickup({...pickup, pickupDate: momentDate.format('DD.MM.YYYY')})
    }

    const pickupTimeChange = (value: string , event: any) => {
        let momentDate = moment(value);
        if (value) setPickup({...pickup, pickupTime: momentDate.format('HH:mm')})
        //if (value) setPickup({...pickup, pickupTime: value})
    }

    const [isSaved, setIsSaved] = useState(false)
    const saveData = () => {
        if (formRef.current!.check()) {
            onChange({...pickup})
            setIsSaved(true)
        }
    }

    const isDateDisabled = (date: Date): boolean => {
        const day = workingHours.find((w) => moment(w.date).isSame(moment(date), 'day'))
        return !(day && day.isWorking)
    }

    const isHourHidden = (hour:number, date:Date): boolean => {
        if (pickupDate == null) return true;
        const day = workingHours.find((w) => moment(w.date).isSame(moment(pickupDate), 'day'))
        if(day) {

            const from = moment(day.startTime, ['H:m'])
            const to = moment(day.endTime, ['H:m'])
            if (hour >= from.hour() && hour < to.hour()) return false
        }
        return true;
    }

    const isMinuteHidden = (minute: number, date: Date): boolean => {
        return minute % 15 != 0;
    }

    return (
        <div className="delivery-services-item delivery-pickup-wrapper">
            {isSaved ? null : <div>
                {t('delivery.pickup')}: <b>Київська область, м. Біла Церква, проспект Незалежності, буд. 85</b>
            {showMap ? <div><a onClick={toggleMap}>{t('order.hide')}</a></div>: <div className="map-btn"><a onClick={toggleMap}>{t('delivery.onMap')}</a></div>}
            {showMap ? <Map address={'Vul. Nezalezhnosti, 85, Bila Tserkva, Kyivs\'ka oblast, 09100'}></Map>: null}

            <Form model={model} ref={formRef} onCheck={setFormError}>

                <div className="input-wrapper input-car_model">
                    <Field
                        name="carModel"
                        label={carModel}
                        accepter={Input}
                        onChange={carModelChange}
                    />
                </div>


                <div className="input-wrapper input-car_number">
                    <Field
                        name="carNumber"
                        label={carNumber}
                        accepter={Input}
                        onChange={carNumberChange}
                    />
                </div>

                <div className="delivery_date">{t('delivery.setDateAndTime')}</div>


                <div className="input-wrapper selectpicker input-delivery_date">
                    <Field
                        name="pickupDate"
                        label={deliveryDate}
                        accepter={DatePicker}
                        shouldDisableDate={isDateDisabled}
                        oneTap
                        placeholder={t('delivery.dateFormat')}
                        onChange={pickupDateChange}
                        format={('dd.MM.yyyy')}
                    />
                </div>

                <div className="input-wrapper selectpicker input-delivery_date">
                    <Field
                        name="pickupTime"
                        label={deliveryTime}
                        accepter={DatePicker}
                        format="HH:mm"
                        placeholder={t('transactions.format_time')}
                        hideHours={isHourHidden}
                        hideMinutes={isMinuteHidden}
                        onChange={pickupTimeChange}
                    />
                </div>
                <button disabled={Object.keys(formError).length ? true: false} type="submit" className="btn btn-lg" onClick={saveData}> {t('order.save')}</button>
            </Form>
            </div>}
        </div>
    )
}
export default DeliveryPickup