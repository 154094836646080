import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ProductOrderBy} from "../enums/ProductOrder"


export const productOrderSlice = createSlice({
    name: 'productOrder',
    initialState: {
        value: ProductOrderBy.Recommended as ProductOrderBy,
    },
    reducers: {
        setProductOrder: (state, action: PayloadAction<ProductOrderBy>) => {
            state.value = action.payload
        }
    }
})
export const { setProductOrder } = productOrderSlice.actions

export default productOrderSlice.reducer