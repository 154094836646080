import React from "react";
import {DeliveryPayer} from "../../enums/DeliveryPayer";
import {useTranslation} from "react-i18next";

const DeliveryPayerComponent:React.FC<{deliveryPayer: DeliveryPayer}> = ({deliveryPayer}) => {
    const {t} = useTranslation();

    return (
        <span>
            {deliveryPayer == DeliveryPayer.Receiver ? t('deliveryPayer.receiver') : null}
            {deliveryPayer == DeliveryPayer.Sender ? t('deliveryPayer.sender') : null}
        </span>
    )
}

export default DeliveryPayerComponent