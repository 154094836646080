import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FilterInterface} from "../interfaces/Filters/FilterInterface";

const initialVal: FilterInterface = {
    priceFrom: 0,
    priceTo: 99999999999,
    properties: [],
    customFilters: []
};

export const filterSlice = createSlice ({
    name: 'filter',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setFilters: (state, action: PayloadAction<FilterInterface>) => {
            state.value = action.payload
        }
    }
}
);
export const { setFilters } = filterSlice.actions

export default filterSlice.reducer