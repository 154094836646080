import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const filterStateSlice = createSlice ({
        name: 'filterState',
        initialState: {
            value: false,
        },
        reducers: {
            setStateFilters: (state, action: PayloadAction<boolean>) => {
                state.value = action.payload
            }
        }
    }
);
export const { setStateFilters } = filterStateSlice.actions

export default filterStateSlice.reducer