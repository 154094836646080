import Product from "../../interfaces/Product/Product";
import comparisonService from "../../services/comparisonService"
import {AppDispatch} from "../../store";
import { setCompareList } from "../compareSlice";
import ComparisonCategory from "../../interfaces/ComparisonCategory";

export const addToCompareAsync = (chosenProduct: Product) => (dispatch: AppDispatch) => {
    comparisonService.postComparison(chosenProduct.id).then((data: ComparisonCategory[]) => {
        dispatch(setCompareList(data))
        }
    )
}

export const getCompareListAsync = () => (dispatch: AppDispatch) => {
    comparisonService.getComparison().then((data: ComparisonCategory[]) => {
        dispatch(setCompareList(data))
    })
}

export const deleteCompareListAsync = () => (dispatch: AppDispatch) => {
    comparisonService.getComparison().then((data: ComparisonCategory[]) => {
        dispatch(setCompareList(data))
    })
}

