import React, {useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {EditPageLang} from "../../../interfaces/Admin/EditPages";
import adminServices from "../../../services/adminServices";
import {useTranslation} from "react-i18next";

const PageEditor:React.FC<{pageInfo: EditPageLang, name: string, slug: string, pageId: number}> = ({pageInfo, name, slug, pageId}) => {

    const {t} = useTranslation();
    const editorRef = useRef<any>();
    const log = () => {
        if (editorRef.current) {

            adminServices.savePage([{"name": name,
                "slug": slug,
                "content": editorRef.current.getContent(),
                "language": pageInfo.language,
                "id": pageId}]
            )

        }
    };
    return (
        <div>
            <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY!}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={pageInfo.content}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help | code',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <button
                className="btn btn-lg"
                onClick={log}>{t('editPage.save')}</button>
        </div>
    )
}
export default PageEditor