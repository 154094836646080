import React, {useEffect, useState} from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import VideoImage from '../../../assets/img/video.webp';

import "./ProductSlider.sass"
import {futimes} from "fs";

const ProductSlider: React.FC<{
    openModalSlider: Function,
    images: string[],
    initialSlide: number,
    setInitialSlide: Function}> = ({
                                                            openModalSlider,
                                                            images,
                                                            initialSlide,
                                                            setInitialSlide}) => {

    const [state, setState] = useState<{nav1: Slider | null, nav2: Slider | null}>({nav1: null, nav2: null})
    let slider1: Slider | null = null
    let slider2: Slider | null = null


    useEffect(() => {
        setState({nav1: slider1, nav2: slider2})
    }, [])
        return (
            <div className="slider-main_wrapper">
                <Slider
                    className="slider-main"
                    asNavFor={state.nav2 ?? undefined }
                    ref={slider => (slider1 = slider)}
                    infinite={images.length <= 4 ?false : true}
                    initialSlide={initialSlide}
                    beforeChange={(currentSlide: number, nextSlide: number) => setInitialSlide(nextSlide)}
                >
                    {images.map((item, id) => {
                        let hasVideo = false
                        let videoId = ''
                        if (item.includes('youtube')) {
                            hasVideo = true

                            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                            let match = item.match(regExp);


                            if(match&&match[7].length==11) {videoId = match[7]};


                        } else {hasVideo = false}

                        return (
                            <div key={id} onClick={() => openModalSlider()}>
                                {hasVideo ? <div className="video-wrapper">
                                    <iframe width="100" height="100"
                                                    src={"https://www.youtube.com/embed/" + videoId}
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                                   allowFullScreen></iframe>
                                </div> : <img src={item} />}

                            </div>
                        )
                    })}

                </Slider>
                <div className="slider-thumb_wrapper">
                    <Slider
                        asNavFor={state.nav1 ?? undefined}
                        ref={slider => (slider2 = slider)}
                        slidesToShow={4}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        infinite={images.length <= 4 ?false : true}
                    >
                        {images.map((item, id) => {
                            let hasVideo = false
                            if (item.includes('youtube')) {hasVideo = true} else {hasVideo = false}
                            return (
                                <div className="img-wrapper" key={id}>
                                    {hasVideo ? <img src={VideoImage} /> : <img src={item} />}
                                </div>
                            )
                        })}
                    </Slider>
                </div>

            </div>
        );
}
export default  ProductSlider;
