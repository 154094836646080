import React from "react";
import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Page404:React.FC = () => {
    const {t} = useTranslation();
    return (
        <div className="page-404 page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">

                    <h2>404</h2>
                    <div className="description">
                        {t('page404.description')}
                    </div>
                    <div>{t('page404.start')} <NavLink to={'/'}>Velotrade</NavLink> </div>

                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default Page404