import {AppDispatch} from "../../store";
import cartServices from "../../services/cartServices";
import {addOrder, addToCart, setCart, updateCount} from "../cartSlice"
import store from "../../store";
import {changeActiveOrder} from "../activeOrderSlice";
import Product from "../../interfaces/Product/Product";

export const getCartAsync = () => (dispatch: AppDispatch) => {
    cartServices.getCart().then(data => {
        const cart = data.map((order: any) => {
            return {
                orderId: order.id,
                orderItems: order.items.map((item: any) => {
                    return {
                        product: {
                            id: item.productId,
                            vendorCode: item.vendorCode,
                            photoUrl: null,
                            name: item.name,
                            lastPurchaseDate: null,
                            available: 0,
                            recommendedRetailPrice: item.recommendedRetailPrice,
                            price: item.price,
                            lastPurchaseCount: 0,
                            categoryId: item.categoryId
                        },
                        count: item.count
                    }
                })
            }
        })
        dispatch(setCart(cart));
    });
}

const doCreateCartOrder = (dispatch: AppDispatch) => {
    return  cartServices.createCartOrder().then(data => {
        const activeOrder = store.getState().cart.value.length
        dispatch(addOrder(data))
        dispatch(changeActiveOrder(activeOrder))
    })
}

export const addCartOrderAsync = () => (dispatch: AppDispatch) => {
    doCreateCartOrder(dispatch)
}

export const addProductToCartAsync = (product: Product) => (dispatch: AppDispatch) => {

    if(store.getState().cart.value.length == 0) {

        doCreateCartOrder(dispatch).then(() => {
            const activeOrderIndex = store.getState().activeOrder.value
            const activeOrderId = store.getState().cart.value[activeOrderIndex].orderId
            cartServices.addToCart(activeOrderId, product.id).then(data => {
                dispatch(addToCart({product: product, activeOrder: activeOrderIndex}))
            })
        })

    } else {
        const activeOrderIndex = store.getState().activeOrder.value
        const activeOrderId = store.getState().cart.value[activeOrderIndex].orderId
        cartServices.addToCart(activeOrderId, product.id).then(data => {
            dispatch(addToCart({product: product, activeOrder: activeOrderIndex}))
        })
    }
}

export const updateProductCountAsync = (productId: number, activeOrder: number, count: number) => (dispatch: AppDispatch) => {
    const activeOrderId = store.getState().cart.value[activeOrder].orderId
    cartServices.updateProductCount(activeOrderId, productId, count.toString()).then(data => {
        dispatch(updateCount({productId: productId, activeOrder: activeOrder, count: count}));
    })
}