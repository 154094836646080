import React, {SyntheticEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Header from "../../../blocks/Header/Header";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {Button, ButtonToolbar, Checkbox, Form, Input, InputGroup, SelectPicker, Toggle, Uploader} from "rsuite";
import ProductCountReturn from "../../../components/ProductCountReturn/ProductCountReturn";
import Footer from "../../../blocks/Footer";


import {ItemDataType} from "rsuite/cjs/@types/common";
import {ValueType} from "rsuite/Checkbox";
import SearchIcon from "@rsuite/icons/Search";

import {AutocompleteInterface} from "../../../interfaces/Autocomplete";
import GenericButton from "../../../components/Buttons/GenericButton";

import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro';
import ContactData from "../../../components/Form/ContactData/ContactData";
import OrderProductsShipping from "../../../components/OrderProducts/OrderProductsShipping/OrderProductsShipping";
import OrderRecipientList from "../../../components/OrderProducts/OrderRecipientList/OrderRecipientList";
import {useAppSelector} from "../../../hooks";
import User from "../../../interfaces/User";
import {ShippingReceiver} from "../../../interfaces/ShippingReceiver";
import ShippingAddress from "../../../interfaces/ShippingAddress";
import {FileType} from "rsuite/Uploader";
import ApiResponseModel from "../../../dto/ApiResponseModel";
import reclamationServices from "../../../services/reclamationServices";
import AddReclamationModel from "../../../dto/reclamations/AddReclamationModel";
import GetOrderModel from "../../../dto/order/GetOrderModel";
import PaymentAndPickupData from "../../../interfaces/PaymentAndPickupData";

import "./CreateReclamationPage.sass"
import AuthProvider from "../../../services/AuthProvider";
import CreateReclamationProduct from "./CreateReclamationProduct";
import {use} from "i18next";

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const CreateReclamationPage:React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    let currentLang: string = useAppSelector((state) => state.currentLang.value);

    const commentText = t('createReclamation.comment')
    const searchForVendorCodeText = t('createReclamation.searchForVendorCode')

    const param = searchParams.get("id")

    const [createReclamation, setCreateReclamation] = useState(true)
    const [ordersForReclamations, setOrdersForReclamations] = useState<GetOrderModel[]>([])

    useEffect(() => {
        reclamationServices.getOrderForReclamation().then(data => {
            setOrdersForReclamations(data)
        })
    },[])

    useEffect(() => {
        if (param){
            const orderItem = ordersForReclamations.find((itemOrder) => itemOrder.id == +param)
            setIsOrderChosen(true)
            if(orderItem) {
                setOrderId(orderItem.id)
                setSelectedOrder(orderItem)
                setIsOrderChosen(true)
                setProductsList(orderItem.items.map((item) => ({
                    id: item.productId,
                    count: item.count,
                    name: item.productName,
                    vendorCode: item.vendorCode,
                    images: [],
                    comment: item.comment
                })))
            }
        }

    }, [param, ordersForReclamations, currentLang])

    const orders = ordersForReclamations.map(
        item => ({ label: item.id + ' от ' + item.orderDate, value: item.id})
    );

    const [selectedOrder, setSelectedOrder] = useState<GetOrderModel>()
    const [isOrderChosen, setIsOrderChosen] = useState(false)

    const [orderId, setOrderId] = useState(0)

    const [productsList, setProductsList] = useState<{
        id: number,
        count: number,
        name: string,
        vendorCode: string,
        images: {
            "googleId": string,
            "url": string
        }[],
        comment: string
    }[]>([])

    const selectOrderForReclamations = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
        const orderItem = ordersForReclamations.find((itemOrder) => itemOrder.id == item.value)
        if(orderItem) {
            setOrderId(orderItem.id)
            setSelectedOrder(orderItem)
            setIsOrderChosen(true)
            setProductsList(orderItem.items.map((item) => ({
                id: item.productId,
                count: item.count,
                name: item.productName,
                vendorCode: item.vendorCode,
                images: [],
                comment: item.comment
            })))
        }

    }

    const [productsForReturn, setProductsForReturn] = useState<number[]>([])


    const addProductToReturn = (value: ValueType | undefined, checked: boolean) => {
        if(checked) {
            setProductsForReturn([...productsForReturn, value as number])
        } else {
            setProductsForReturn(productsForReturn.filter(item => item !== value))

        }
    }

    const [comment, setComment] = useState<string | null>(null)

    const createComment = (productId: number, value: ValueType | undefined) => {

        let product = productsList.find(i => i.id == productId)

        if (product){
            if (value){
                product.comment = value.toString()
                setButtonState(false)
            }
            else {
                product.comment = ''
                setButtonState(true)
            }
        }

    }

    const changeProductCount = (productId: number, counter: number) => {
        let prod = productsList.find((item) => item.id == productId)
        if(prod) {
            prod.count = counter
        }
    }

    const [allProductsChosen, setAllProductsChosen] = useState(true)

    const checkAllProducts = (checked: boolean, event: any) => {
        if(checked) {
            setProductsForReturn(productsList.map((item) => item.id))

            setAllProductsChosen(false)
            if(selectedOrder) {
                setProductsList(selectedOrder.items.map((item) => ({
                    id: item.productId,
                    count: item.count,
                    name: item.productName,
                    vendorCode: item.vendorCode,
                    images: [],
                    comment: item.comment
                })))
            }
        }
        else {

            setAllProductsChosen(true)
            setProductsForReturn([])
        }

    }

    const user = useAppSelector<User>(state => state.user.value)

    const [toggleShippingRecipient, setToggleShippingRecipient] = useState(true)
    const shippingRecipient = (state: boolean) => {
        setToggleShippingRecipient(state);
    }

    const [idChosenRecipient, setIdChosenRecipient] = useState<number | null>(null)
    const chosenRecipient = (recipient: number) => {
        setIdChosenRecipient(recipient)
        setNewReceiver(null)
    }

    const [isAddNewReceiver, setIsAddNewReceiver] = useState(false)
    const [newReceiver, setNewReceiver] = useState<ShippingReceiver | null>(null)
    const addNewReceiverData = (newReceiverData: ShippingReceiver) => {

        setNewReceiver(newReceiverData)
        setIdChosenRecipient(null)
        setToggleShippingRecipient(false)
        setIsAddNewReceiver(true)
    }

    let timeoutHandle: ReturnType<typeof setTimeout>

    const [showResults, setShowResults] = useState(false)

    const [results, setResults] = useState<AutocompleteInterface>({products: [], categories: [], totalProducts: 0})

    const [message, setMessage] = useState('')

    const onSearch = (value: string, event: any) => {
        const val = value
        setMessage(value);

        if(val.length >= 3){
            clearTimeout(timeoutHandle)
            timeoutHandle = setTimeout(((query: string) => {
                reclamationServices.getOrderForReclamation(value).then(data => {
                    setOrdersForReclamations(data)
                    setShowResults(true)
                })

                /*productService.getAutocomplete(query).then(data => {
                    setResults(data)
                    setShowResults(true)
                })*/
            }).bind(null, val), 500)

        }
        else {
            setShowResults(false)
        }
    }

    const onSetProduct = (orderItem: GetOrderModel) => {
        setIsOrderChosen(true)
        setShowResults(false)


        if(orderItem) {
            setOrderId(orderItem.id)
            setSelectedOrder(orderItem)
            setIsOrderChosen(true)
            setProductsList(orderItem.items.map((item) => ({
                id: item.productId,
                count: item.count,
                name: item.productName,
                vendorCode: item.vendorCode,
                images: [],
                comment: item.comment
            })))
        }
    }


    const goFurther = () => {
        setCreateReclamation(false)
    }

    const deleteProductFromProductsForReturn = (id: number) => {

        let newProductsForReturn = productsForReturn.filter(item => item !== id)

        setProductsForReturn(newProductsForReturn)

        if (newProductsForReturn.length == 0){
            navigate('/return');
        }


    }

    const contactDataTitle = t('order.contactData')

    const [contactFirstName, setContactFirstName] = useState(user.firstName)
    const [contactLastName, setContactLastName] = useState(user.lastName)
    const [contactMiddleName, setContactMiddleName] = useState(user.middleName)
    const [contactEmail, setContactEmail] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    let contactName = contactFirstName + ' ' + contactLastName
    if(contactMiddleName){
        contactName = + ' ' +contactMiddleName
    }
    const editContactData = (name: string, lastName: string, middleName: string, email: string, phone: string) => {
        setContactFirstName(name)
        setContactLastName(lastName)
        setContactMiddleName(middleName)
        setContactEmail(email)
        setContactPhone(phone)
    }

    const [savedAddressId, setSavedAddressId] = useState<number | null>(null)
    const [newShippingData, setNewShippingData] = useState<ShippingAddress | null>(null)
    const addSavedAddress = (address: number) => {
            setSavedAddressId(address)
            setNewShippingData(null)
    }

    const addNewDeliveryData = (shippingData: ShippingAddress) => {
        setNewShippingData(shippingData)
        setSavedAddressId(null)
    }

    const [paymentPickupData, setPaymentPickupData] = useState<PaymentAndPickupData>({
        deliveryPayer: selectedOrder?.deliveryPayer,
        orderToPay: selectedOrder?.cashOnDeliveryAmount,
        carModel: selectedOrder?.carModel,
        carNumber: selectedOrder?.carNumber,
        pickupDate: selectedOrder?.pickupDate,
        pickupTime: selectedOrder?.pickupTime
    })
    const updatePaymentPickupData = (data: PaymentAndPickupData) => {
        setPaymentPickupData(data)
    }

    useEffect(() => {
        if(selectedOrder) {
            setNewShippingData(null)
            setSavedAddressId(selectedOrder?.deliveryAddress?.id)
            setPaymentPickupData({
                deliveryPayer: selectedOrder?.deliveryPayer,
                orderToPay: selectedOrder?.cashOnDeliveryAmount,
                carModel: selectedOrder?.carModel,
                carNumber: selectedOrder?.carNumber,
                pickupDate: selectedOrder?.pickupDate,
                pickupTime: selectedOrder?.pickupTime
            })
            setNewReceiver(null)
            setIdChosenRecipient(selectedOrder?.receiver?.id)
            setContactEmail(selectedOrder?.contactEmail)
            setContactPhone(selectedOrder?.contactPhone)
        }
    }, [selectedOrder])

    const sendReturnData = () => {

        let noComment = productsList.filter((item) => productsForReturn.includes(item.id))
            .find(item => item.comment == undefined)

        let noFile = productsList.filter((item) => productsForReturn.includes(item.id))
            .find(item => item.images.length === 0)

        if(noComment){
            setButtonState(true)
        }

        if(noFile){
            setButtonState(true)
        }


        if(!noFile && !noComment){

            const data: AddReclamationModel = {
            "orderId": orderId,
            "contactName": contactName,
            "contactEmail": contactEmail,
            "contactPhone": contactPhone,
            "comment": comment,
            "deliveryPayer": 0,
            "deliveryAddressId": savedAddressId,
            "deliveryAddress": newShippingData,
            "carModel": paymentPickupData?.carModel ?? null,
            "carNumber": paymentPickupData?.carNumber ?? null,
            "pickupDate": paymentPickupData?.pickupDate ?? null,
            "pickupTime": paymentPickupData?.pickupTime ?? null,
            "receiverId": idChosenRecipient,
            "isUserReceiver": idChosenRecipient || newReceiver? false: true,
            "receiver": newReceiver,
            "items": productsList.filter((item) => productsForReturn.includes(item.id))
                .map((item) => ({
                    "productId": item.id,
                    "count": item.count,
                    "images": item.images,
                    "comment": item.comment
                }))
        }

        reclamationServices.addReclamation(data).then((dataReclamation) => {
            navigate(`/reclamation/${dataReclamation}`);
        })
        }

    }

    const onSuccessUpload = (response: object, file: FileType, id: number) => {
        setButtonState(false)
        productsList.find(i => i.id == id)?.images.push((response as ApiResponseModel).data)
        setProductsList([...productsList])
    }

    const [buttonState, setButtonState] = useState(false)



    return (
        <div className="create-return-page-wrapper my-orders-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <div className="back-link">
                        <NavLink to='/return/'>{t('back')}</NavLink>
                    </div>
                    <h2>{t('createReclamation.createReclamation')}</h2>

                    {createReclamation ? <div className="white-box">
                        <h3>{t('createReclamation.productForReclamation')}</h3>

                        <Form>
                            <div className="input-wrapper selectpicker">
                                <Field
                                    name="area"
                                    label={t('createReclamation.choseOrder')}
                                    value={selectedOrder?.id}
                                    accepter={SelectPicker}
                                    data={orders}
                                    onSelect={selectOrderForReclamations}
                                />
                            </div>
                        </Form>

                        {t('createReclamation.or')}

                        <div className="input-wrapper">

                            <InputGroup inside className="reclamation-vendorcode-search">
                                <InputGroup.Button>
                                    <SearchIcon />
                                </InputGroup.Button>
                                <Input
                                    onChange={onSearch}
                                    placeholder={searchForVendorCodeText}
                                />
                            </InputGroup>
                            {showResults ? <ul className="search-results-wrapper">
                                {ordersForReclamations.map((order, idx) => {
                                    return (
                                        <li key={idx} className="product-return-item" onClick={() => onSetProduct(order)}>
                                            <span className="product-return-code">
                                                {order.id}
                                            </span>
                                            <span className="product-return-name">
                                                {order.items.map((product, idx) => {
                                                    return (
                                                        <span key={idx}>{product.vendorCode} {product.productName}</span>
                                                    )
                                                })}
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>: ''}
                        </div>


                        {isOrderChosen ? <div className="product-return-wrapper">

                            <div>
                                <h3>{t('createReclamation.productsForReclamations')}</h3>
                                {selectedOrder?.items.map((item, idx) => {
                                    return (
                                        <div className="product-return-item" key={idx}>
                                            <div className="product-return-checkbox">
                                                <Checkbox value={item.productId} onChange={addProductToReturn} />
                                            </div>
                                            <div className="product-return-code">
                                                {item.vendorCode}
                                            </div>
                                            <div className="product-return-name">
                                                {item.productName}
                                            </div>
                                            <div className="product-return-count">
                                                <ProductCountReturn
                                                    counterStore={item.count}
                                                    onChangeProductCount={(counter: number) => changeProductCount(item.productId, counter)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="btn-wrapper product-return-btn">
                                <button
                                    disabled={productsForReturn.length == 0}
                                    onClick={goFurther}
                                    className="btn btn-lg"
                                >
                                    {t('createReclamation.next')}
                                </button>
                            </div>

                        </div> : null}

                    </div> :  <div>
                        <div className="white-box">
                        <h3>{t('createReclamation.order')} №{selectedOrder?.id}</h3>

                        <h4>{t('createReclamation.productsForReclamations')}</h4>

                        {productsList.filter((item) => productsForReturn.includes(item.id))
                        .map((item, idx) => {
                            return (
                                <CreateReclamationProduct
                                    key={idx}
                                    product={item}
                                    changeProductCount={changeProductCount}
                                    deleteProductFromProductsForReturn={deleteProductFromProductsForReturn}
                                    onSuccessUpload={onSuccessUpload}
                                    createComment={createComment}
                                    buttonState={buttonState}
                                    />
                            )
                        } ) }

                        </div>

                        <h3>{t('createReclamation.deliveryData')}</h3>

                        <div className="white-box">

                            <div className="contact-data-wrapper">

                                <div className="contact-data-body edit-mode">
                                    <ContactData title={contactDataTitle} editContactData={editContactData} profile={false}/>
                                </div>

                            </div>

                            {selectedOrder ? <div className="shipping_wrapper">
                                <OrderProductsShipping
                                    order={{
                                        "cartOrderId": 0,
                                        "id": selectedOrder.id,
                                        "tempId": selectedOrder.id,
                                        "contactName": selectedOrder.contactName,
                                        "contactEmail": selectedOrder.contactEmail,
                                        "contactPhone": selectedOrder.contactPhone,
                                        "comment": selectedOrder.comment,
                                        "isReserve": false,
                                        "deliveryPayer": 0,
                                        "cashOnDeliveryAmount": selectedOrder.cashOnDeliveryAmount,
                                        "isPickup": selectedOrder.isPickup,
                                        "carModel": selectedOrder.carModel,
                                        "carNumber": selectedOrder.carNumber,
                                        "pickupDate": selectedOrder.pickupDate,
                                        "pickupTime": selectedOrder.pickupTime,
                                        "deliveryAddressId": selectedOrder.deliveryAddress?.id,
                                        "deliveryAddress": selectedOrder.deliveryAddress,
                                        "receiverId": selectedOrder.isUserReceiver? null: selectedOrder.receiver?.id,
                                        "receiver": selectedOrder.receiver,
                                        "isUserReceiver": selectedOrder.isUserReceiver,
                                        "useNPShippingName": false,
                                        "usePaymentControl": false,
                                        "weight": selectedOrder.weight,
                                        "items": selectedOrder.items.map((item, idx) => {
                                            return {
                                                "productId": item.productId,
                                                "product": {
                                                    id: item.productId,
                                                    vendorCode: item.vendorCode,
                                                    photoUrl: item.photoUrl,
                                                    name: item.productName,
                                                    lastPurchaseDate: item.lastBoughtOn,
                                                    available: item.left,
                                                    recommendedRetailPrice: item.recommendedRetailPrice,
                                                    price: item.price,
                                                    lastPurchaseCount: item.lastBoughtCount,
                                                    categoryId: 0
                                                },
                                                "count": item.count
                                            }
                                        })
                                    }}
                                    onSavedAddressChange={addSavedAddress}
                                    onNewAddressChange={addNewDeliveryData}
                                    onPaymentPickupDataChange={updatePaymentPickupData}
                                    errorsStateShipping={true}
                                    showPayOnDelivery={false}
                                    showDeliveryPayer={false}
                                    updateOrderNpClientName={null}
                                    updatePaymentControl={null}
                                />

                                {toggleShippingRecipient ? <div className="recipient-name-wrapper">
                                    <div className="recipient-name">{contactFirstName} {contactLastName}</div>
                                    <div className="recipient-phone">{contactPhone}</div>
                                </div> : null}

                                <ButtonToolbar className="shipping-recipient">
                                    <Button size="lg" appearance="ghost" onClick={() => shippingRecipient(true)}
                                            active={toggleShippingRecipient ? true : false}>{t('order.recipientMe')}</Button>
                                    <Button size="lg" appearance="ghost" onClick={() => shippingRecipient(false)}
                                            active={toggleShippingRecipient ? false : true}>{t('order.recipientOther')}</Button>
                                </ButtonToolbar>

                                {toggleShippingRecipient ? null : <OrderRecipientList chosenRecipient={chosenRecipient} onNewRecipient={addNewReceiverData} />}

                                <button
                                    disabled={buttonState}
                                    className="btn btn-lg"
                                    type="submit"
                                    onClick={sendReturnData}
                                >
                                    {t('createReclamation.finishReclamation')}
                                </button>
                            </div>: null }

                        </div>

                    </div>}



                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default CreateReclamationPage