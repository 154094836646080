import React from "react";
import ExchangeRates from "../ExchangeRates/ExchangeRates";
import {useTranslation} from "react-i18next";

import "./EmptyCart.sass"

const EmptyCart: React.FC<{isReserve: boolean}> = ({isReserve}) => {
    const { t } = useTranslation();
    return (
        <div className={"empty-cart"}>
            <div className="title">{isReserve ? t('basket.empty_title_reserve') : t('basket.empty_title')}</div>
            <p>{isReserve ? t('basket.empty_descr_reserve') : t('basket.empty_descr')}</p>
            <ExchangeRates />
        </div>
    )
}
export default EmptyCart;