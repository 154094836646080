import React, {SyntheticEvent, useEffect, useRef, useState} from "react";

import "./DeliveryUkrpost.sass"
import ShippingAddress from "../../../../interfaces/ShippingAddress";
import PaymentAndPickupData from "../../../../interfaces/PaymentAndPickupData";
import {Form, Input, Schema, SelectPicker} from "rsuite";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../hooks";
import {CarrierType} from "../../../../enums/CarrierType";
import {DeliveryType} from "../../../../enums/DeliveryType";
import deliveryService from "../../../../services/deliveryService";
import {ItemDataType} from "rsuite/cjs/@types/common";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import velotradeDeliveryService from "../../../../services/velotradeDeliveryService";

interface SelectItem{
    label: string,
    value: string
}

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control checkAsync={false} name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const DeliveryUkrpost: React.FC<{onChange: Function, shippingAddress?: ShippingAddress | null,
    onPaymentAndPickupDataChange?: (data: PaymentAndPickupData) => void,
    showPayOnDelivery: boolean}> =
    ({onChange, shippingAddress, onPaymentAndPickupDataChange, showPayOnDelivery}) => {
        const {t} = useTranslation();
        /*const currentLang = useAppSelector<string>((state) => state.currentLang.value);*/
        const [tabIndex, setTabIndex] = useState(0);
        const [areas, setAreas] = useState<SelectItem[]>([])
        const [districts, setDistricts] = useState<SelectItem[]>([])
        const [cities, setCities] = useState<SelectItem[]>([])
        const [streets, setStreets] = useState<SelectItem[]>([])
        /*const [branches, setBranches] = useState<SelectItem[]>([])*/
        const [deliveryPayer, setDeliveryPayer] = useState([{label: t('deliveryPayer.sender'), value: 0}, {label: t('deliveryPayer.receiver'), value: 1}])
        const [selectedArea, setSelectedArea] = useState<string>();
        const [selectedDistrict, setSelectedDistrict] = useState('');
        const [selectedCity, setSelectedCity] = useState('');
        const [selectedStreet, setSelectedStreet] = useState('');
        /*const [selectedBranch, setSelectedBranch] = useState('');
        const [regionName, setRegionName] = useState('')*/

        const [address, setAddress] = useState<ShippingAddress>({
            carrierType: CarrierType.UkrPost,
            deliveryType: DeliveryType.CourierDelivery,
            id: shippingAddress ? shippingAddress.id : null,
            state: shippingAddress ? shippingAddress.state : '',
            stateId:  shippingAddress ? shippingAddress.stateId : null,
            city: shippingAddress ? shippingAddress.city : '',
            cityId: shippingAddress ? shippingAddress.cityId : null,
            district: '',
            districtId: null,
            street: shippingAddress ? shippingAddress.street : '',
            streetId: shippingAddress ? shippingAddress.streetId : null,
            house: shippingAddress ? shippingAddress.house : '',
            apartment: shippingAddress ? shippingAddress.apartment : '',
            carrierBranch: shippingAddress ? shippingAddress.carrierBranch : '',
            carrierBranchId: shippingAddress ? shippingAddress.carrierBranchId : null
        })

        const formRef = useRef<any>();
        const [formError, setFormError] = useState({});
        const [formValue, setFormValue] = useState({
            area: shippingAddress?.stateId,
            city: shippingAddress?.cityId,
            branch: shippingAddress?.carrierBranchId
        });
        const [formValueCourier, setFormValueCourier] = useState({
            area: shippingAddress?.stateId,
            city: shippingAddress?.cityId,
            street: shippingAddress?.street,
            house: shippingAddress?.house
        });
        const [formErrorAddress, setFormErrorAddress] = useState({});

        const area = t('delivery.area')
        const district = t('delivery.district')
        const city = t('delivery.city')
        const branch = t('delivery.branch')
        const street = t('delivery.street')
        const house = t('delivery.house')
        const apartment = t('delivery.apartment')
        const payer = t('delivery.payer')
        const sum = t('delivery.sum')

        const { StringType } = Schema.Types;
        const errorField = t('form.requiredFieldMsg')
        /*const model = Schema.Model({
            area: StringType().isRequired(errorField),
            city: StringType().isRequired(errorField),
            branch: StringType().isRequired(errorField),
        });*/
        const modelAddress = Schema.Model({
            area: StringType().isRequired(errorField),
            district: StringType().isRequired(errorField),
            city: StringType().isRequired(errorField),
            street: StringType().isRequired(errorField),
            house: StringType().isRequired(errorField),
            //apartment: StringType().isRequired(errorField),
        });

        useEffect(() => {
            if (shippingAddress){
                setTabIndex(shippingAddress.deliveryType )
                saveDeliveryType(shippingAddress.deliveryType)
            }
        }, [])



        useEffect(() => {
            velotradeDeliveryService.getAreas().then(data => {
                setAreas(data.map(item => ({ label: item.name, value: item.id.toString() })))

                if(shippingAddress && shippingAddress.stateId) {
                    let selected = data.find(item => item.id == +shippingAddress.stateId!);
                    if(selected) {
                        setSelectedArea(selected.id.toString())
                    }
                }
                if (data.length == 0) {
                    setCities([]);
                }
            })
        }, [])

        useEffect(() => {
            if (selectedArea)
                velotradeDeliveryService.getDistricts(+selectedArea).then(data => {
                    setDistricts(data.map(item => ({label: item.name, value: item.id.toString()})))

                    if (shippingAddress?.districtId){
                        setSelectedDistrict(shippingAddress.districtId)
                    }

                    if (data.length == 0) {
                        /*setBranches([]);*/
                    }
                })
        }, [selectedArea])


        useEffect(() => {
            if (selectedDistrict)
                velotradeDeliveryService.getCities(+selectedDistrict).then(data => {
                    setCities(data.map(item => ({label: item.name, value: item.id.toString()})))
                    if (shippingAddress?.cityId){
                        setSelectedCity(shippingAddress.cityId)
                    }
                    if (data.length == 0) {
                        /*setBranches([]);*/
                    }
                })
        }, [selectedDistrict])

        useEffect(() => {
            if (selectedCity)
                velotradeDeliveryService.getStreets(+selectedCity).then(data => {
                    setStreets(data.map(item => ({label: item.name, value: item.id.toString()})))
                    if (shippingAddress?.streetId){
                        setSelectedStreet(shippingAddress.streetId)
                    }
                    if (data.length == 0) {
                        /*setBranches([]);*/
                    }
                })
        }, [selectedCity])

        /*useEffect(() => {
            deliveryService.getBranches(selectedCity as string,'', currentLang).then(data => {

                setBranches(data.map(item => ({ label: item.name, value: item.id })))

                if (shippingAddress?.carrierBranchId){
                    setSelectedBranch(shippingAddress.carrierBranchId)
                }
            })
        }, [selectedCity])*/





        const areaChange = (value: string | null, item: any, event: SyntheticEvent<Element, Event>): void => {

            if (item.label && value) {
                setAddress({...address, state: item.label.toString(), stateId: value})
                setSelectedArea(value)
                setSelectedDistrict('')
                setSelectedCity('')
                setSelectedStreet('')

                /*if(shippingAddress){
                    console.log('jghjfhkj')
                    setAddress({...address, district: '', districtId: '', city: '', cityId: '', street: '',  streetId: ''})
                }*/
            }

            /*if (value)
                velotradeDeliveryService.getDistricts(+value).then((data) => {
                    console.log('data111', data)
                    setDistricts(data.map(item => ({label: item.name, value: item.id.toString()})))
                })*/

        }


        const districtChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
            if (item.label && value) {
                setAddress({...address, district: item.label.toString(), districtId: value})
                setSelectedDistrict(value)
            }

            /*if (value)
                velotradeDeliveryService.getCities(+value).then((data) => {
                    console.log('data111', data)
                    setCities(data.map(item => ({label: item.name, value: item.id.toString()})))
                })*/
        }

        const cityChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
            if (item.label && value) {
                setAddress({...address, city: item.label.toString(), cityId: value})
                setSelectedCity(value)
            }

            /*if (value)
                velotradeDeliveryService.getStreets(+value).then(data => {
                    setStreets(data.map(item => ({label: item.name, value: item.id.toString()})))
                })*/
        }

        const streetChange = (value: string , item: ItemDataType, event: SyntheticEvent<Element, Event>) => {


            if (item.label && value) {
                setAddress({...address, street: item.label.toString(), streetId: value})
                setSelectedStreet(value)
            }

        }


        /*const addRigion = (value: string | null, item: any, event: SyntheticEvent<Element, Event>) => {
            console.log('regionName', value)
            if(value) {
                setRegionName(value)
                console.log('regionName', value)
            }
        }*/





       /* const branchChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
            if (item.label && value) {
                setAddress({...address, carrierBranch: item.label.toString(), carrierBranchId: value})
                setSelectedBranch(value)
            }

        }*/

        const [deliveryPayerVal, setDeliveryPayerVal] = useState<number | null>(null)
        const [orderAmountVal, setOrderAmountVal] = useState<number | null>(null)

        const deliveryPayerChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>) => {
            setDeliveryPayerVal(+value!)
            if(onPaymentAndPickupDataChange) {
                onPaymentAndPickupDataChange({
                    deliveryPayer: +value!,
                    orderToPay: orderAmountVal,
                    carModel: null,
                    carNumber: null,
                    pickupDate: null,
                    pickupTime: null
                })
            }
        }

        const setOrderAmount = (value: string , event: any) => {
            setOrderAmountVal(+value)
            if(onPaymentAndPickupDataChange) onPaymentAndPickupDataChange({
                deliveryPayer: deliveryPayerVal,
                orderToPay: +value,
                carModel: null,
                carNumber: null,
                pickupDate: null,
                pickupTime: null
            })

        }

        /*const streetChange = (value: string , event: any) => {
            if (value) setAddress({...address, address: value})
        }*/

        const houseChange = (value: string , event: any) => {
            if (value) setAddress({...address, house: value})
        }

        const apartmentChange = (value: string , event: any) => {
            if (value) setAddress({...address, apartment: value})
        }

        const [isSaved, setIsSaved] = useState(false)

        const saveData = () => {
            if (formRef.current!.check()) {
                onChange({...address})
                setIsSaved(true)
            }
        }

        /*const changeTab = (index: number) => {

            setTabIndex(index)
            if(index == 0) {
                setFormError({})
                setFormValue(prevState => ({...prevState, area: selectedArea?.toString()}))
                setFormValue(prevState => ({...prevState, city: selectedCity}))
                setFormValue(prevState => ({...prevState, branch: selectedBranch}))
                setAddress(address => ({...address, house: ''}))
                setAddress(address => ({...address, street: ''}))
                setAddress(address => ({...address, apartment: ''}))
            }
            else {
                setFormErrorAddress({})
                setFormValueCourier(prevState => ({...prevState, area: selectedArea?.toString()}))
                setFormValueCourier(prevState => ({...prevState, city: selectedCity}))
                setAddress(address => ({...address, carrierBranch: ''}))
                setAddress(address => ({...address, carrierBranchId: null}))
            }
        }*/

        const saveDeliveryType = (deliveryTypeEnum: number) => {
            setAddress(({...address, deliveryType: deliveryTypeEnum}))
        }

        const focusInput = (el: any) => {

            const inputs = document.getElementsByClassName('rs-picker-search-bar-input')
            for (let i = 0; i < inputs.length; i++) {
                const slide = inputs[i] as HTMLElement;
                slide.focus();
            }
        }

    return (
        <div className="delivery-services-item delivery-ukrpost-wrapper">
            <Form model={modelAddress} ref={formRef} onCheck={setFormErrorAddress} formDefaultValue={formValueCourier}>
                <div className={formErrorAddress.hasOwnProperty('area') ? "input-wrapper selectpicker input-area has-error" : "input-wrapper selectpicker input-area"}>
                    <Field
                        name="area"
                        label={area}
                        value={selectedArea}
                        accepter={SelectPicker}
                        data={areas}
                        onSelect={areaChange}
                        onOpen={focusInput}
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('area') ? "input-wrapper selectpicker input-area has-error" : "input-wrapper selectpicker input-area"}>
                    <Field
                        name="district"
                        label={district}
                        value={selectedDistrict}
                        accepter={SelectPicker}
                        data={districts}
                        onSelect={districtChange}
                        onOpen={focusInput}
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('city') ? "input-wrapper selectpicker input-city has-error" : "input-wrapper selectpicker input-city"}>
                    <Field
                        name="city"
                        label={city}
                        value={selectedCity}
                        accepter={SelectPicker}
                        data={cities}
                        onSelect={cityChange}
                        onOpen={focusInput}
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('street') ? "input-wrapper selectpicker input-street has-error" : "input-wrapper selectpicker input-street"}>
                    <Field
                        name="street"
                        label={street}
                        data={streets}
                        accepter={SelectPicker}
                        value={selectedStreet}
                        onSelect={streetChange}
                        onOpen={focusInput}
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('house') ? "input-wrapper input-house has-error" : "input-wrapper input-house"}>
                    <Field
                        name="house"
                        label={house}
                        defaultValue={shippingAddress?.house}
                        //value={address.house}
                        accepter={Input}
                        onChange={houseChange}
                    />
                </div>

                <div className={formErrorAddress.hasOwnProperty('apartment') ? "input-wrapper input-apartment has-error" : "input-wrapper input-apartment"}>
                    <Field
                        name="apartment"
                        label={apartment}
                        defaultValue={shippingAddress?.apartment}
                        //value={address.apartment}
                        accepter={Input}
                        onChange={apartmentChange}
                    />
                </div>

                {onPaymentAndPickupDataChange ? <div className="input-wrapper selectpicker input-payer">
                    <Field
                        name="payer"
                        label={payer}
                        accepter={SelectPicker}
                        searchable={false}
                        data={deliveryPayer}
                        onSelect={deliveryPayerChange}
                        onOpen={focusInput}
                    />
                </div>: null}

                {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="input-wrapper input-sum">
                    <Field
                        name="sum"
                        label={sum}
                        accepter={Input}
                        onChange={setOrderAmount}
                    />
                </div>: null}
                {onPaymentAndPickupDataChange && showPayOnDelivery ? <div className="pay-method-info">
                    <p>{t('order.payMethod')}</p>
                </div>: null}

                <div className="btn-wrapper">
                    <button
                        disabled={Object.keys(formErrorAddress).length ? true: false}
                        type="submit"
                        className="btn btn-lg"
                        onClick={saveData}>
                        {t('order.save')}
                    </button>
                </div>
            </Form>
            {/*{isSaved ? null : <Tabs selectedIndex={tabIndex}> onSelect={(index) => changeTab(index)}
                <TabList>
                    <Tab onClick={() => saveDeliveryType(1)}>{t('delivery.addressDelivery')}</Tab>
                </TabList>

                <TabPanel>

                </TabPanel>

            </Tabs>}*/}
        </div>
    )
}
export default DeliveryUkrpost