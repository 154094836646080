import BaseHttpHandler from "./baseHttpHandler";
import NPResponseWrapper from "../dto/novaPoshta/NPResponseWrapper";

export default class NovaPoshtaHttpHandler extends BaseHttpHandler {

    constructor(){
        super(process.env.REACT_APP_NP_API_URL!, false)
    }

    public fetchData(url: string, method: string, body?: string): Promise<any>{
        return this.doRequest(url, method, body).then(data => {
            return (data as NPResponseWrapper).data;
        });
    }
}