import ApiHttpHandler from "./apiHttpHandler";
import PaginatedResult from "../dto/PaginatedResult";
import GetOrderModel from "../dto/order/GetOrderModel";
import ShortReturnModel from "../dto/ShortReturnModel";
import {EditPages, SavePage} from "../interfaces/Admin/EditPages";
import {ManagerDetails, Managers} from "../interfaces/Admin/Managers";

const handler = new ApiHttpHandler();

const getAllOrders = (searchTerm: string, page: number, pageSize: number, getReserves: boolean = false): Promise<PaginatedResult<GetOrderModel>> => {
    if(searchTerm){
        return handler.get(`admin/orders/search?term=${searchTerm}&page=${page}&pageSize=${pageSize}`)
    } else {
        return handler.get(`admin/orders?page=${page}&pageSize=${pageSize}&getReserves=${getReserves}`)
    }
}

const getAllReturns = (term: string, page: number, pageSize: number ): Promise<PaginatedResult<ShortReturnModel>> => {
    return handler.get(`admin/returns?term=${term}&page=${page}&pageSize=${pageSize}`)
}


const getAllReclamations = (term: string, page: number, pageSize: number ): Promise<PaginatedResult<ShortReturnModel>> => {
    return handler.get(`admin/reclamations?term=${term}&page=${page}&pageSize=${pageSize}`)
}

const getPages = (): Promise<EditPages[]> => {
    return handler.get(`admin/pages/`)
}

const getPage = (slug: string): Promise<SavePage[]> => {
    return handler.get(`admin/pages/${slug}`)
}

const savePage = (data: SavePage[]) => {
    return handler.put(`admin/pages`, data)
}

const getManagers = (): Promise<Managers[]> => {
    return handler.get(`admin/managers`)
}

const updateManager = (manager: ManagerDetails) => {
    return handler.put(`admin/bank-details`, manager)
}

const updateData = (actionType: string) => {
    return handler.post(`admin/actions/${actionType}`)
}

export default {
    getAllOrders,
    getAllReturns,
    getAllReclamations,
    getPages,
    getPage,
    savePage,
    getManagers,
    updateManager,
    updateData
}