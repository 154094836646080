import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialVal: number = 0;

export const activeOrderSlice = createSlice({
    name: 'activeOrder',
    initialState: {
        value: initialVal,
    },
    reducers: {
        changeActiveOrder: (state, action: PayloadAction<number>) => {
            state.value = action.payload
        },
    }
});
export const { changeActiveOrder } = activeOrderSlice.actions;

export default activeOrderSlice.reducer