import ApiHttpHandler from "./apiHttpHandler";

const handler = new ApiHttpHandler();

const getCart = () => {
    return handler.get(`cart`)
}

const createCartOrder = () => {
    return handler.post('cart')
}

const addToCart = (orderId: number, productId: number) => {
    return handler.post(`cart/${orderId}/${productId}`)
}

const updateProductCount = (orderId: number, productId: number, count: string) => {
    return handler.put(`cart/${orderId}/${productId}`, count)
}

const deleteCartProduct = (orderId: number, productId: number) => {
    return handler.delete(`cart/${orderId}/${productId}`)
}

const deleteCartOrder = (orderId: number) => {
    return handler.delete(`cart/${orderId}`)
}

export default {
    getCart,
    createCartOrder,
    addToCart,
    updateProductCount,
    deleteCartProduct,
    deleteCartOrder
}