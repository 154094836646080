import React, {useEffect, useRef, useState} from "react";
import Header from "../../../blocks/Header/Header";
import {useTranslation} from "react-i18next";
import {Panel, PanelGroup} from "rsuite";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import adminServices from "../../../services/adminServices";
import {EditPages} from "../../../interfaces/Admin/EditPages";
import PageEditor from "./PageEditor";

import './EditPage.sass'

const EditPage:React.FC = () => {
    const {t} = useTranslation();



    const [content, setContent] = useState<EditPages[]>([])

    useEffect(() => {
        adminServices.getPages().then(data => {
            setContent(data)
        })
    }, [])


    return (
        <div className="help-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-container">
                    <h1>Admin: {t('editPage.editPage')}</h1>

                    <div className="white-box">
                    <PanelGroup accordion>
                        {content.map((item, index) => {
                            return (<Panel key={index} header={item.name} eventKey={1} bordered>

                                <Tabs>
                                    <TabList>
                                        {item.translations.map((lang, idx) => {
                                            return (
                                                <Tab key={idx}>{lang.language.toUpperCase()}</Tab>
                                            )
                                        })}
                                    </TabList>

                                    {item.translations.map((content, idx) => {
                                        return (
                                            <TabPanel key={idx}>
                                                <PageEditor pageInfo={content} name={item.name} slug={item.slug} pageId={content.id} />
                                            </TabPanel>
                                        )
                                    })}

                                </Tabs>



                            </Panel>)
                        })}

                    </PanelGroup>
                    </div>

                </div>
            </main>
        </div>
    )
}
export default EditPage