import React, {SyntheticEvent, useEffect, useState} from "react";


import Header from "../../../blocks/Header/Header";
import Footer from "../../../blocks/Footer";
import {NavLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Checkbox, Form, Input, SelectPicker, Toggle} from "rsuite";
import orderService from "../../../services/orderService";
import OrderForReturnModel from "../../../dto/order/OrderForReturnModel";
import {ItemDataType} from "rsuite/cjs/@types/common";
import {ValueType} from "rsuite/Checkbox";
import ProductCountReturn from "../../../components/ProductCountReturn/ProductCountReturn";
import returnServices from "../../../services/returnServices";

import "./CreateReturnPage.sass"
import {useAppSelector} from "../../../hooks";
import pageServices from "../../../services/pageServices";

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const CreateReturnPage:React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const currentLang = useAppSelector((state) => state.currentLang.value)

    const [searchParams] = useSearchParams();
    const [ordersForReturn, setOrdersForReturn] = useState<OrderForReturnModel[]>([])

    useEffect(() => {
        orderService.getOrderForReturn().then(data => {
            const param = searchParams.get("id")
            setOrdersForReturn(data)
            if (param) {
                setIsOrderChosen(true)

                const orderItem = data.find((itemOrder) => itemOrder.id == +param)

                if (orderItem) {
                    setOrderId(orderItem.id)
                    setSelectedOrder(orderItem)
                    setIsOrderChosen(true)
                    setProductsList(orderItem.items.map((item) => ({id: item.productId, count: item.count})))
                }
            }
        })

    },[])

    /*useEffect(() => {
        if (param) {
            console.log('param', param)
            setIsOrderChosen(true)

            const orderItem = ordersForReturn.find((itemOrder) => itemOrder.id == +param)

            if(orderItem) {
                setOrderId(orderItem.id)
                setSelectedOrder(orderItem)
                setIsOrderChosen(true)
                setProductsList(orderItem.items.map((item) => ({id: item.productId, count: item.count})))
            }

        }
    }, [param, ordersForReturn])*/

    const orders = ordersForReturn.map(
        item => ({ label: item.id + ' от ' + item.date, value: item.id})
    );

    const [selectedOrder, setSelectedOrder] = useState<OrderForReturnModel>()
    const [isOrderChosen, setIsOrderChosen] = useState(false)

    const [orderId, setOrderId] = useState(0)

    const [productsList, setProductsList] = useState<{id: number, count: number}[]>([])


    const selectOrderForReturn = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>): void => {
        const orderItem = ordersForReturn.find((itemOrder) => itemOrder.id == item.value)

        if(orderItem) {
            setOrderId(orderItem.id)
            setSelectedOrder(orderItem)
            setIsOrderChosen(true)
            setProductsList(orderItem.items.map((item) => ({id: item.productId, count: item.count})))
        }

    }


    const [productsForReturn, setProductsForReturn] = useState<number[]>([])


    const addProductToReturn = (value: ValueType | undefined, checked: boolean) => {
        if(checked) {
            setProductsForReturn([...productsForReturn, value as number])
        } else {
            setProductsForReturn(productsForReturn.filter(item => item !== value))

        }
    }

    let comment:string = ''

    const createComment = (value: ValueType | undefined) => {
        comment = value as string
    }

    const changeProductCount = (productId: number, counter: number) => {
        let prod = productsList.find((item) => item.id == productId)
        if(prod) {
            prod.count = counter
        }
    }

    const [allProductsChosen, setAllProductsChosen] = useState(true)

    const checkAllProducts = (checked: boolean, event: any) => {
        if(checked) {
            setProductsForReturn(productsList.map((item) => item.id))

            setAllProductsChosen(false)
            if(selectedOrder) {
                setProductsList(selectedOrder.items.map((item) => ({id: item.productId, count: item.count})))
            }
        }
        else {

            setAllProductsChosen(true)
            setProductsForReturn([])
        }

    }


    const sendReturnData = () => {
        const data = {
            "orderId": orderId,
            "comment": comment,
            "items": productsList.filter((item) => productsForReturn.includes(item.id))
                .map((item) => ({
                "productId": item.id,
                    "count": item.count

            }))
        }

        returnServices.addReturn(data).then((data) => {
            navigate(`/return/${data}`);
        })

    }

    const [content, setContent] = useState<string>('')

    useEffect(() => {
        pageServices.getPage('return-creation').then(data => {
            setContent(data.content)
        })
    }, [currentLang, content])

    return (
        <div className="create-return-page-wrapper my-orders-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <div className="back-link">
                        <NavLink to='/my-orders'>{t('orderSingle.toOrders')}</NavLink>
                    </div>
                    <h2>{t('createReturn.createReturn')}</h2>

                    <div className="white-box">
                        <h3>{t('createReturn.chooseOrder')}</h3>
                        <Form>
                            <div className="input-wrapper selectpicker">
                                <Field
                                    name="area"
                                    label={t('createReturn.order')}
                                    value={selectedOrder?.id}
                                    accepter={SelectPicker}
                                    data={orders}
                                    onSelect={selectOrderForReturn}
                                />
                            </div>
                        </Form>


                        {isOrderChosen ? <div className="product-return-wrapper">

                            <div className="toggle-difference">
                                <Toggle onChange={checkAllProducts} className="comparison-difference" />
                                {t('createReturn.allProductsForReturn')}
                            </div>
                                {allProductsChosen ? <div>
                                    <h3>{t('createReturn.productsForReturn')}</h3>
                                    {selectedOrder?.items.map((item, idx) => {
                                        return (
                                            <div className="product-return-item" key={idx}>
                                                <div className="product-return-checkbox">
                                                    <Checkbox value={item.productId} onChange={addProductToReturn} />
                                                </div>
                                                <div className="product-return-code">
                                                    {item.vendorCode}
                                                </div>
                                                <div className="product-return-name">
                                                    {item.name}
                                                </div>
                                                <div className="product-return-count">
                                                    <ProductCountReturn
                                                        counterStore={item.count}
                                                        onChangeProductCount={(counter: number) => changeProductCount(item.productId, counter)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> : null }


                            <div className="product-return-comment">
                                <Input as="textarea" rows={3} placeholder="Комментарий" onChange={createComment} />
                            </div>

                            <div className="btn-wrapper product-return-btn">
                                <button
                                    disabled={productsForReturn.length == 0}
                                    onClick={sendReturnData}
                                    className="btn btn-lg"
                                    >
                                    {t('createReturn.applyReturn')}
                                </button>
                            </div>

                        </div> : null}

                    </div>

                    <div className="white-box text-description">
                        <h2>{t('return.returnTitle')}</h2>

                        <div dangerouslySetInnerHTML={{__html: content}}></div>

                    </div>


                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default CreateReturnPage