import {ManagerProfile} from "../interfaces/ManagerProfile";
import ApiHttpHandler from "./apiHttpHandler";

const handler = new ApiHttpHandler();

const getClientManager = (): Promise<ManagerProfile[]> => {
    return handler.get(`client-managers`)
}

const deleteClientManager = (id: number) => {
    return handler.delete(`client-managers/${id}`)
}

const addClientManager = (contactPersonModel: ManagerProfile) => {
    return handler.post(`client-managers`, contactPersonModel).then()
}

const editClientManager = (id: number, contactPersonModel: ManagerProfile) => {
    return handler.put(`client-managers/${id}`, contactPersonModel)
}

export default {
    getClientManager,
    deleteClientManager,
    addClientManager,
    editClientManager
}