import {AppDispatch} from "../../store";
import productService from "../../services/productService";
import {setCategories} from "../categorySlice";
import {setRecentlyViewedCategory} from "../recentlyViewedCategorySlice";

export const setCategoriesAsync = () => (dispatch: AppDispatch) => {
        productService.getCategories().then(data => {
            dispatch(setCategories(data.catalogTree));
            dispatch(setRecentlyViewedCategory(data.viewedCategories))
        })
}
