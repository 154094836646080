import React, {useEffect, useState} from "react";
import Header from "../../../blocks/Header/Header";
import GenericButton from "../../../components/Buttons/GenericButton";
import SearchBlock from "../../../components/SearchBlock/SearchBlock";
import MyOrdersTable from "../../../components/MyOrdersTable/MyOrdersTable";
import TableBottom from "../../../components/TableBottom/TableBottom";
import Footer from "../../../blocks/Footer";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import GetOrderModel from "../../../dto/order/GetOrderModel";
import orderService from "../../../services/orderService";
import adminServices from "../../../services/adminServices";

const AllOrders:React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [myOrders, setMyOrders] = useState<GetOrderModel[]>([])

    const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([])

    const [search, setSearch] = useState('')
    const [downloadState, setDownloadState] = useState(true)


    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const changePageSize = (key: number) => {
        setPageSize(key)
    }
    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }

    useEffect(() => {
        adminServices.getAllOrders(search, currentPage, pageSize).then(data => {
            setMyOrders(data.items);
            setTotalItems(data.totalItemCount);
        })
    }, [pageSize, currentPage])

    const searchMyOrders = (value: string, event: any) => {
        setCurrentPage(1)
        adminServices.getAllOrders(value, currentPage, pageSize, false).then(data =>{
            setMyOrders(data.items)
            setTotalItems(data.totalItemCount);
        })

    }

    const downloadOrders = () => {

        orderService.getOrdersExel(selectedOrderIds).then(blob => {

            const url = window.URL.createObjectURL(
                blob,
            );
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "orders.xlsx");
            document.body.appendChild(link); // Required for FF
            link.click();
            document.body.removeChild(link);
        })

    }
    return (
        <div className="my-orders-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <h2>Admin: Все заказы</h2>

                    <SearchBlock
                        search={searchMyOrders}
                        searchPlaceHolder={t('myOrders.search')}
                        buttonClick={downloadOrders}
                        buttonText={t('order.downloadOrders')}
                        buttonState={downloadState}
                    />

                    <div className="main-table">
                        <MyOrdersTable data={myOrders} onOrderSelectionChange={ ids => setSelectedOrderIds(ids)  } orderType={"order"} setButtonState={setDownloadState} />
                    </div>

                    <TableBottom
                        onPageSizeChange={changePageSize}
                        onActivePageChange={changeActivePage}
                        totalItemCount={totalItems}
                        pageSize={pageSize}
                        currentPage={currentPage}
                    />

                </div>
            </main>
        </div>
    )
}
export default AllOrders;