import React from "react";
import {useTranslation} from "react-i18next";

const ProductAvailable:React.FC<{count: number, dateAvailable: string | null}> = ({count, dateAvailable}) => {
    const { t } = useTranslation();

    let info = '';

    if (count > 20){
        info = '>20';
    }
    else if (count === 0 && dateAvailable){
        info = t('waitingDate') + "<br>" + dateAvailable
    }
    else {
        info = count.toString();
    }

    return (
        <span dangerouslySetInnerHTML={{__html: info}}></span>
    )

}

export default ProductAvailable