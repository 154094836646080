import React, {lazy, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useAppSelector} from "../hooks";

import Header from "../blocks/Header/Header";
import Filters from "../blocks/Filters/Filters"
import Sidebar from "../blocks/Sidebar/Sidebar";
import Footer from "../blocks/Footer";

import ProductList from "../components/ProductList/ProductList";

import productService from "../services/productService";

import Product from "../interfaces/Product/Product";
import Pagination from "../interfaces/Pagination";
import {SelectedProperty} from "../interfaces/Filters/SelectedFilters";

import {setProducts} from "../slices/productSlice";
import {setFilters} from "../slices/filterSlice";
import {setPagination} from "../slices/paginationSlice";

import '../App.sass'
import {useTranslation} from "react-i18next";
import {ProductEntity} from "../interfaces/Product/ProductEntity";
import Loading from "../components/Loading/Loading";

/*const ProductList = lazy(() => import('../components/ProductList/ProductList'));*/


function Home () {
    let {categoryId} = useParams();
    const { t } = useTranslation();

    const products = useAppSelector<ProductEntity[]>((state) => state.products.value);
    /*const paging = useAppSelector<Pagination>((state) => state.pagination.value);*/
    /*const dispatch = useAppDispatch();*/
    /*useEffect(() => {

        productService.getProducts({
            fromPrice: null,
            toPrice: null,
            categoryId: null,
            page: paging.currentPageNumber,
            itemsPerPage: paging.pageSize,
            propertyFilters: []
        }).then(data => {
            const products = data.products.items.map((item) => {
                let prod: Product = {
                    name: item.name,
                    id: item.id,
                    vendorCode: item.vendorCode,
                    url: item.photoUrl,
                    lastPurchase: item.lastPurchaseData,
                    left: item.lastPurchaseCount,
                    rrc: item.recommendedRetailPrice,
                    price: item.price,
                    rest: item.available,
                    toReserve: "В резерв",
                    toCart: "В корзину",
                    totalPrice: item.price,
                    income: item.recommendedRetailPrice - item.price,
                    compare: ""
                }
                return prod
            })
            dispatch(setProducts(products));

            dispatch(setPagination({
                "totalPageCount": data.products.totalPageCount,
                "totalItemCount": data.products.totalItemCount,
                "currentPageNumber": paging.currentPageNumber,
                "pageSize": paging.pageSize,
            }))

            dispatch(setFilters({
                priceFrom: data.filters.priceFrom,
                priceTo: data.filters.priceTo,
                properties: data.filters.properties,
                customFilters: data.filters.customFilters
            }))
        })

    }, [categoryId, paging.pageSize, paging.currentPageNumber, paging.totalPageCount]);*/

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)

    const [isMobileView, setIsMobileView] = useState(true)

    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])


    const [expanded, setExpanded] = useState(true);

    return (
        <div className="main-page">
            <Header></Header>
            <main>
                <Filters></Filters>

                <div className={"innerBlock"}>
                    <ProductList products={products}/>
                </div>
                {isMobileView ? '' : <Sidebar></Sidebar>}

            </main>
            {/*{isMobileView ? '' : <Footer />}*/}
        </div>
    )
}
export default Home;