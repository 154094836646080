import ApiHttpHandler from "./apiHttpHandler";
import {Transaction} from "../dto/transactions/Transaction";

const handler = new ApiHttpHandler();

const getTransactions = (from: string, to: string, page: number, pageSize: number): Promise<Transaction> => {
    return handler.get(`transactions?from=${from}&to=${to}&page=${page}&pageSize=${pageSize}`)
};

const getTransactionsExel = (ids: number[], fromDate: string | null, toDate: string | null): Promise<Blob> => {
    return handler.getFile(`transactions/excel-export`, 'POST', {ids: ids, fromDate: fromDate, toDate: toDate})
}

export default {
    getTransactions,
    getTransactionsExel
}