import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import Product from "../interfaces/Product/Product";
import ComparisonCategory from "../interfaces/ComparisonCategory";

const initialVal: ComparisonCategory[] = [];

export const compareSlice = createSlice({
    name: 'compare',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setCompareList: (state, action: PayloadAction<ComparisonCategory[]>) => {
            state.value = action.payload
        }
    }
});
export const { setCompareList } = compareSlice.actions;

export default compareSlice.reducer