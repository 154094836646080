import React from "react";

import GenericButton from "../../Buttons/GenericButton";

import {ContactPerson} from "../../../interfaces/ContactPerson";

import "./ProfileMyManagerItem.sass"
import {ManagerProfile} from "../../../interfaces/ManagerProfile";

const ProfileMyManagerItem: React.FC<{isEditManagerData: Function, managerInfo: ManagerProfile, deleteItem: Function}> = (
    {isEditManagerData, managerInfo, deleteItem}) => {


    return (
        <div className="profile-my-managers-item">
            <div className="name">
                {managerInfo.name}
            </div>
            <div className="phone">
                {managerInfo.phone}
            </div>
            <div className="email">{managerInfo.email}</div>
            <div className="edit">
                <GenericButton generic={"edit"} onClick={() => isEditManagerData(managerInfo)} isDisabled={false} showText={true} />
            </div>
            <div className="delete">
                <GenericButton generic={"delete"} onClick={() => deleteItem(managerInfo.id)} isDisabled={false} showText={false} />
            </div>

        </div>
    )
}
export default ProfileMyManagerItem