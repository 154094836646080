import React from "react"

import "./StatusReturn.sass"
import {ReturnStatus} from "../../enums/ReturnStatus";
import {useTranslation} from "react-i18next";

const StatusReturn:React.FC<{status: number}> = ({status}) => {
    const {t} = useTranslation();
    return (
        <div className={"status-" + status}>
            {status == ReturnStatus.New ? t('statuses.new') : null}
            {status == ReturnStatus.InProcessing ? t('statuses.inProgress') : null}
            {status == ReturnStatus.Closed ? t('statuses.closed') : null}
        </div>
    )
}
export default StatusReturn