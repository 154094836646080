import React, {useEffect, useState} from "react";
import ProductCountReturn from "../../../components/ProductCountReturn/ProductCountReturn";
import GenericButton from "../../../components/Buttons/GenericButton";
import {Input, Uploader, Form} from "rsuite";
import AuthProvider from "../../../services/AuthProvider";
import {FileType} from "rsuite/Uploader";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import {useTranslation} from "react-i18next";

const CreateReclamationProduct:React.FC<{product: {
        id: number,
        count: number,
        name: string,
        vendorCode: string,
        images: {
            "googleId": string,
            "url": string
        }[],
        comment: string
    }, changeProductCount: Function,
    deleteProductFromProductsForReturn: Function,
    onSuccessUpload: Function,
    createComment: Function,
    buttonState: boolean}> = (
        {product,
            changeProductCount,
            deleteProductFromProductsForReturn,
            onSuccessUpload,
            createComment,
            buttonState}) => {

    const {t} = useTranslation();
    const commentText = t('createReclamation.comment')


    const [comment, setComment] = useState(false)

    const onChangeComment = (productId: number, value: string) => {


        if(value){
            createComment(productId, value)
            setComment(false)
        }
        else {
            createComment(productId, '')
            setComment(true)
        }
    }

    const [isUploadedFile, setIsUploadedFile] = useState(false)

    const onUpload = (response: object, file: FileType, productId: number) => {
        setIsUploadedFile(false)
        onSuccessUpload(response, file, productId)

    }

    useEffect(() => {
        if (buttonState){
            if(product.comment){
                setComment(false)
            }
            else {
                setComment(true)
            }

            if(product.images.length === 0){
                setIsUploadedFile(true)
            }
            else {
                setIsUploadedFile(false)
            }
        }

    }, [buttonState])


    return (
        <div>
            <div className="product-return-item">
                <div className="product-return-code">
                    {product.vendorCode}
                </div>
                <div className="product-return-name">
                    {product.name}
                </div>
                <div className="product-return-count">
                    <ProductCountReturn
                        counterStore={product.count}
                        onChangeProductCount={(counter: number) => changeProductCount(product.id, counter)}
                    />
                </div>
                <div className="product-return-delete">
                    <GenericButton
                        generic={"delete"}
                        onClick={() => deleteProductFromProductsForReturn(product.id)}
                        isDisabled={false}
                        showText={false}
                    />
                </div>
            </div>

            <Uploader
                data={{vendorCode: product.vendorCode}}
                multiple
                draggable
                headers={{authorization: 'bearer ' + AuthProvider.getAuthToken()}}
                listType="picture"
                action={process.env.REACT_APP_API_URL! + 'reclamations/file'}
                onSuccess={	(response: object, file: FileType) => onUpload(response, file, product.id)}
                onError={() => setIsUploadedFile(true)}
            >
                <button>
                    <CameraRetroIcon />
                </button>
            </Uploader>

            <div className="uploader-photo">
                {t('createReclamation.addPhoto')}
                <Form.ErrorMessage show={isUploadedFile} placement={'bottomStart'}>
                    {t('form.requiredFieldMsg')}
                </Form.ErrorMessage>
            </div>

            <div className="product-return-comment">
                <Input
                    onChange={(value) => onChangeComment(product.id, value)}
                    as="textarea"
                    rows={3}
                    placeholder={commentText}  />
                <Form.ErrorMessage show={comment} placement={'bottomStart'}>
                    {t('form.requiredFieldMsg')}
                </Form.ErrorMessage>
            </div>


        </div>
    )
}
export default CreateReclamationProduct