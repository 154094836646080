import ApiHttpHandler from "./apiHttpHandler";
import PaginatedResult from "../dto/PaginatedResult";
import ShortReturnModel from "../dto/ShortReturnModel";
import Return from "../interfaces/Return";

import ReturnModel from "../dto/return/Return";

const handler = new ApiHttpHandler();


const getReturns = (term: string, page: number, pageSize: number ): Promise<PaginatedResult<ShortReturnModel>> => {
    return handler.get(`returns?term=${term}&page=${page}&pageSize=${pageSize}`)
}

const addReturn = (returns: Return) => {
    return handler.post(`returns`, returns)
}

const getReturn = (id: number): Promise<ReturnModel> => {
    return handler.get(`returns/${id}`)
}

export default {
    getReturns,
    addReturn,
    getReturn
}
