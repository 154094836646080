import React, {useState} from "react"
import SubscribeModal from "../SubscribeModal/SubscribeModal";

import "./Subscription.sass"
import {useTranslation} from "react-i18next";

const Subscription: React.FC<{disableNewProducts: boolean}> = ({disableNewProducts}) => {

    const {t} = useTranslation();

    const [openSubscription, setOpenSubscription] = useState(false);
    const handleOpenSubscription = () => setOpenSubscription(true);
    const handleCloseSubscription = () => setOpenSubscription(false);

    return (
        <div className={"subscription-wrapper"}>
            <button onClick={handleOpenSubscription} className={"btn-usual"}>{t('subscribeNewProducts')}</button>

            <SubscribeModal disableNewProducts={disableNewProducts} openModal={openSubscription} handleCloseSubscription={handleCloseSubscription} />
        </div>
    )
}
export default Subscription