import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Schema} from "rsuite";
import {ManagerDetails, Managers} from "../../../interfaces/Admin/Managers";
import {useTranslation} from "react-i18next";
import adminServices from "../../../services/adminServices";

function TextField(props: any) {
    const { fieldName, value, setValue, label, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                {...rest}
            />
        </Form.Group>
    );
}

const ManagersForm:React.FC<{manager: Managers}> = ({manager}) => {

    const {t} = useTranslation();

    const formRef = useRef<any>(null);

    const [isManagerUpdated, setManagerUpdated] = useState(false)

    const [name, setName] = useState(manager.bankDetails?.name)
    const [accountNumber, setAccountNumber] = useState(manager.bankDetails?.accountNumber)
    const [taxNumber, setTaxNumber] = useState(manager.bankDetails?.taxNumber)
    const [bankNumber, setBankNumber] = useState(manager.bankDetails?.bankNumber)


    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState<Record<string, any>>({
        name: name,
        accountNumber: accountNumber,
        taxNumber: taxNumber,
        bankNumber: bankNumber
    });

    const errorField = t('form.requiredFieldMsg')

    const nameLabel = t('form.firstName')
    const accountNumberLabel = t('manager.accountNumber')
    const taxNumberLabel = t('manager.taxNumber')
    const bankNumberLabel = t('manager.bankNumber')

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        name: StringType().isRequired(errorField),
        accountNumber: StringType().isRequired(errorField),
        taxNumber: StringType().isRequired(errorField),
        bankNumber: StringType().isRequired(errorField)
    });

    const [bankDetails, setBankDetails] = useState<ManagerDetails>(manager.bankDetails ?? {
        "name": '',
        "accountNumber": '',
        "taxNumber": '',
        "bankNumber": '',
        "id": 0
    })


    useEffect(() => {
        setBankDetails(manager.bankDetails ?? {
            "name": '',
            "accountNumber": '',
            "taxNumber": '',
            "bankNumber": '',
            "id": 0
        })

        setManagerUpdated(false)

        if(formRef.current) {
            formRef.current.cleanErrors()
        }

        if (manager.bankDetails){
            setName(manager.bankDetails?.name)
            setAccountNumber(manager.bankDetails?.accountNumber)
            setTaxNumber(manager.bankDetails?.taxNumber)
            setBankNumber(manager.bankDetails?.bankNumber)

            setFormValue({
                name: manager.bankDetails?.name,
                accountNumber: manager.bankDetails?.accountNumber,
                taxNumber: manager.bankDetails?.taxNumber,
                bankNumber: manager.bankDetails?.bankNumber
            })

        }
        else {
            setName('')
            setAccountNumber('')
            setTaxNumber('')
            setBankNumber('')

            setFormValue({
                name: '',
                accountNumber: '',
                taxNumber: '',
                bankNumber: ''
            })

        }

    }, [manager])



    const saveManagerData = () => {

        if (formRef.current!.check()) {
            if(manager.bankDetails) {
                let updatedManagerData: ManagerDetails = {
                    id: manager.bankDetails.id,
                    name: name!,
                    accountNumber: accountNumber!,
                    taxNumber: taxNumber!,
                    bankNumber: bankNumber!
                }

                adminServices.updateManager(updatedManagerData).then(data => {
                    setManagerUpdated(true)
                })
            }


        }
    }


    return (
        <div>
            {isManagerUpdated ? <h5>{t('manager.savedMsg')}</h5> : <Form
                ref={formRef}
                model={model}
                formValue={formValue}
                onCheck={setFormError}
                onChange={(fv: Record<string, any>, event?: React.SyntheticEvent) => setFormValue(fv)}
                onSubmit={saveManagerData}
            >
                <TextField
                    fieldName="name"
                    value={name}
                    setValue={setName}
                    key={'firstName'}
                    label={t('form.firstName')}
                />

                <TextField
                    fieldName="accountNumber"
                    value={accountNumber}
                    setValue={setAccountNumber}
                    key={'accountNumber'}
                    label={t('manager.accountNumber')}
                />

                <TextField
                    fieldName="taxNumber"
                    value={taxNumber}
                    setValue={setTaxNumber}
                    key={'taxNumber'}
                    label={t('manager.taxNumber')}
                />

                <TextField
                    fieldName="bankNumber"
                    value={bankNumber}
                    setValue={setBankNumber}
                    key={'bankNumber'}
                    label={t('manager.bankNumber')}
                />

                <button
                    type="submit"
                    className={"btn-lg btn"}
                >{t('order.save')}</button>

            </Form>}

        </div>
    )
}
export default ManagersForm