import React, {useEffect, useState} from "react";

import "./ProductCountReturn.sass"

const ProductCountReturn:React.FC<{counterStore: number, onChangeProductCount: Function}> = ({counterStore, onChangeProductCount}) => {

    const maxCounter = counterStore

    const [counter, setCounter] = useState(counterStore)
    const [hideDecrease, setHideDecrease] = useState(counter <= 1)
    const [hideIncrease, setHideIncrease] = useState(counter >= maxCounter)

    useEffect(() => {
        setHideDecrease(counter <= 1)
        setHideIncrease(counter >= maxCounter)

        onChangeProductCount(counter)

    }, [counter] )



    return (
        <div className={"wrapperCounter product-count-return-wrapper"}>
            <button disabled={hideDecrease} onClick={() => setCounter(counter - 1)}></button>
            <input type="number" onChange={(e) => setCounter(+e.target.value)} value={counter} />
            <button disabled={hideIncrease} onClick={() => setCounter(counter + 1)}></button>
        </div>
    )
}
export default ProductCountReturn