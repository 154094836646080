import React, {useEffect, useState} from "react";

import Header from "../../blocks/Header/Header";
import Sidebar from "../../blocks/Sidebar/Sidebar";
import ProductDescription from "../../blocks/ProductDescription/ProductDescription"
import Footer from "../../blocks/Footer";

import '../../App.sass'
import './ProductPage.sass'

function ProductPage () {

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)

    const [isMobileView, setIsMobileView] = useState(false)
    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    return (
        <div className="product-page">
            <Header></Header>
            <main>
                <div className={"inner-block"}>
                    <ProductDescription />
                </div>
                {isMobileView ? null : <Sidebar></Sidebar>}
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default ProductPage;