import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Col, Grid, Row, Toggle} from "rsuite";
import {NavLink} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../hooks";

import comparisonService from "../../services/comparisonService";

import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";
import Breadcrumbs from "../../blocks/Breadcrumbs/Breadcrumbs";
import TileProduct from "../../components/Tile/TileProduct";
import ComparisonPropertiesRow from "../../components/ComparisonPropertiesRow/ComparisonPropertiesRow";

import ComparisonCategory from "../../interfaces/ComparisonCategory"
import {ProductEntity} from "../../interfaces/Product/ProductEntity";


import {setCompareList} from "../../slices/compareSlice";

import "./ComparisonPage.sass"



const ComparisonPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();



    const products = useAppSelector<ComparisonCategory[]>((state) => state.compare.value)

    const [categoriesLength, setCategoriesLength] = useState(false)


    const [activeCategory, setActiveCategory] = useState(false)
    const categoryMenu = useRef<any>(null)
    const closeOpenMenus = (e: MouseEvent)=>{
        if(categoryMenu.current && !categoryMenu.current?.contains(e.target)){
            setActiveCategory(false)
        }
    }
    const toggleCategory = () => {
        setActiveCategory(activeCategory => !activeCategory)
    }
    const [toggleShowTabs, setToggleShowTabs] = useState(false)
    const showTabs = () => {
        setToggleShowTabs(true)
    }

    const [activeCategoryName, setActiveCategoryName] = useState('')
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        comparisonService.getComparison().then(data => {
            dispatch(setCompareList(data))
            if (data != 0){
                setActiveCategoryName(data[0].categoryName)
                if (data.length > 5){
                    setCategoriesLength(true)
                }
            }

        })

        document.addEventListener('click', closeOpenMenus,false)
        return () => {
            document.removeEventListener("click", closeOpenMenus, false);
        };
    }, [])

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)
    const [isMobileView, setIsMobileView] = useState(false)
    useEffect(() => {
        if(deviceWidth <= 1024) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])
    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])


    const deleteCompareItem = (id: number) => {
        comparisonService.deleteCompareItem(id).then((data) => {
            dispatch(setCompareList(data))
            if(data){
                if((!data[tabIndex] || data[tabIndex].items.length == 0) && tabIndex != 0){
                    setTabIndex(0)
                }
            }
            }
        )
    }


    const [showDifference, setShowDifference] = useState(false)
    const toggleDifference = (checked: boolean, event: any) => {
        setShowDifference(checked)
    }


    const deleteCategory = () => {
        if (products) {
            comparisonService.deleteCompareCategory(products[tabIndex].categoryId).then((data) => {
                dispatch(setCompareList(data))
            })
        }
        setTabIndex(0)
    }

    const selectTab = (index: number) => {
        setTabIndex(index)
        if (products){
            setActiveCategoryName(products[index].categoryName)
        }
        setActiveCategory(false)

    }


    const comparisonRef = useRef<any>(null);
    const [stickyState, setStickyState] = useState(false)

    const handleScroll = () => {
        if (comparisonRef.current.scrollTop === 0) {
            setStickyState(false)
        } else {
            setStickyState(true)
        }
    };

    useEffect(() => {

        if(comparisonRef.current){
            comparisonRef.current.addEventListener('scroll', handleScroll);

            return () => {
                if(comparisonRef.current) {
                    comparisonRef.current.removeEventListener('scroll', handleScroll);
                }
            };
        }

    }, [tabIndex]);

    let breadcrumbs = [
        {'link': '/',
            'linkText': t('main')
        },
        {
            'link': null,
            'linkText': t('compare.pageTitle')
        }
    ]


    return (
        <div className={"comparisonPage"}>
            <Header></Header>
            <main>
                <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
                <div className="comparisonPageHeaderWrapper">
                    <h1>{t('compare.pageTitle')}</h1>
                    {products && products.length != 0 ? <button className={"btn-usual"} onClick={deleteCategory}>{t('compare.clear')}</button> : null}
                </div>

                <div className="comparison-body-wrapper">
                    {products && products.length != 0 ? <Tabs selectedIndex={tabIndex} onSelect={(index) => selectTab(index)}>
                        <div className="comparison-header tabs-header-view">

                            {categoriesLength || isMobileView ? <div className="category-list-wrapper" ref={categoryMenu}>
                                <div className={"menu-category-list"}>

                                    <button className={"openDropdownCategory"} onClick={toggleCategory}>{t('compare.chooseCategory')}</button>
                                    {activeCategory ? <TabList className="dropdown">
                                        {products?.map((categories, index) => {
                                            return (

                                                <Tab key={index}>{categories.categoryName} <span>({categories.items.length})</span></Tab>

                                            )
                                        })}
                                    </TabList> : null}

                                </div>
                                <div className="chosen-category">
                                    {activeCategoryName}
                                </div>

                            </div>  : <TabList>
                                {products?.map((categories, index) => {
                                    return (

                                        <Tab key={index}><span className="category-name-tab">{categories.categoryName}</span> <span>({categories.items.length})</span></Tab>

                                    )
                                })}
                            </TabList>}




                            <div className="toggle-difference">
                                <Toggle onChange={toggleDifference} className="comparison-difference" />
                                {t('compare.difference')}
                            </div>

                        </div>
                        <div className={stickyState ? "compare-body-products sticky" : "compare-body-products"} ref={comparisonRef}>
                            {products?.map((category, index) => {
                                let props = [...category.properties]
                                props = props.sort()
                                return (

                                    <TabPanel key={index}>
                                        <Grid fluid>
                                            <Row className={stickyState ? "comparison-tile-product sticky-row" : "comparison-tile-product "}>

                                                {category.items.map((product, idx) => {
                                                        return (

                                                            <Col key={idx} xs={24} sm={8} md={6}>

                                                                <TileProduct
                                                                    product={ProductEntity.CreateProductEntityComparison(product)}
                                                                    showAdditionalControls={true}
                                                                    deleteFromCompareList={() => deleteCompareItem(product.id)}
                                                                />
                                                            </Col>

                                                        )
                                                    }
                                                )}
                                            </Row>

                                            {props.map((propertyName, n) => {
                                                return (
                                                    <ComparisonPropertiesRow
                                                        key={n}
                                                        propertyName={propertyName}
                                                        category={category.items}
                                                        propertyIndex={n}
                                                        showDifference={showDifference}
                                                    />
                                                )


                                            })}

                                        </Grid>
                                    </TabPanel>

                                )
                            })}
                        </div>
                    </Tabs> : <div className="empty-comparison">
                        <h2>{t('compare.emptyComparison')}</h2>
                        <NavLink to="/" className="btn-usual">{t('compare.goToMainPage')}</NavLink>
                    </div>}

                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}

export default ComparisonPage