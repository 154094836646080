import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import UserModel from "../../dto/login/UserModel";
import {setUser} from "../../slices/userSlice";
import {setCompareList} from "../../slices/compareSlice";
import {setRate} from "../../slices/rateSlice";
import {setCart} from "../../slices/cartSlice";
import authService from "../../services/authService";
import AuthProvider from "../../services/AuthProvider";
import userService from "../../services/userService";
import store from "../../store";
import {setWSRate} from "../../slices/wsRateSlice";

const AppInit: React.FC<{children: React.ReactNode}> = ({children}: {children: React.ReactNode}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const language = useAppSelector((state) => state.currentLang.value)
    const [isInitialized, setIsInitialized] = useState(false)


    const saveData = (user: UserModel) => {
        const cart = user.cartOrders.map((order) => {
            return {
                orderId: order.id,
                orderItems: order.items.map((item) => {
                    return {
                        product: {
                            id: item.productId,
                            vendorCode: item.vendorCode,
                            photoUrl: '',
                            name: item.name,
                            lastPurchaseDate: '',
                            available: item.available,
                            recommendedRetailPrice: item.recommendedRetailPrice,
                            price: item.price,
                            lastPurchaseCount: 0,
                            categoryId: 0
                        },
                        count: item.count
                    }
                })
            }

        })

        dispatch(setUser(user))
        dispatch(setWSRate(user.exchangeRateWS))
        dispatch(setCompareList(user.comparisonItems))
        dispatch(setRate(user.exchangeRate))
        dispatch(setCart(cart))
    }

    const loadInitialData = () => {
        userService.getMyProfile().then(data => {
            saveData(data);
            setIsInitialized(true);
        });
    }



    useEffect(() => {
        const init = AuthProvider.init();
        if (init === 2 && window.location.pathname.indexOf('/order/track/') == -1){
            setIsInitialized(true);
            navigate('/login');
        } else {
            if (init === 1) {
                authService.refreshAuthToken(AuthProvider.getRefreshToken()!)
                    .then(data => {
                        AuthProvider.setAuthToken({token: data.access.accessToken, expiration: data.access.accessExpiresOn});
                        AuthProvider.setRefreshToken({token: data.access.refreshToken, expiration: data.access.refreshExpiresOn});
                        AuthProvider.updateUser(data.user);
                        saveData(data.user as UserModel);
                        setIsInitialized(true);
                    })
                    .catch(() => {
                        setIsInitialized(true);
                        navigate('/login');
                    });
            }
            if (init === 0){
                loadInitialData()
            }
        }
    }, [language])

    return <>{isInitialized ? children : null}</>
}

export default AppInit;
