import productReducer from "./productSlice";
import cartReducer from "./cartSlice";
import reserveReducer from "./reserveSlice"
import activeOrderReducer from "./activeOrderSlice";
import activeSidebarTabReducer from "./activeSidebarTabSlice";
import rateReducer from "./rateSlice";
import compareReducer from "./compareSlice";
import categoryReducer from "./categorySlice";
import currentLangReducer from "./currentLangSlice";
import paginationReducer from "./paginationSlice";
import filterReducer from "./filterSlice"
import productOrderReducer from "./productOrderSlice"
import filterStateReducer from "./filterStateSlice";
import fetchStateReducer from "./fetchStateSlice";
import userReducer from "./userSlice";
import orderReducer from "./orderSlice"
import chosenFilterReducer from "./chosenFiltersSlice";
import categoryNameReducer from "./categoryNameSlice";
import productCounterReducer from "./productCounterSlice";
import recentlyViewedCategoryReducer from "./recentlyViewedCategorySlice";
import wsRateReducer from "./wsRateSlice";

export default {
    products: productReducer,
    filters: filterReducer,
    chosenFilters: chosenFilterReducer,
    filterState: filterStateReducer,
    cart: cartReducer,
    reserve: reserveReducer,
    activeOrder: activeOrderReducer,
    activeTab: activeSidebarTabReducer,
    rate: rateReducer,
    compare: compareReducer,
    categories: categoryReducer,
    currentLang: currentLangReducer,
    pagination: paginationReducer,
    productOrder: productOrderReducer,
    fetchState: fetchStateReducer,
    user: userReducer,
    order: orderReducer,
    categoryName: categoryNameReducer,
    productCounter: productCounterReducer,
    recentlyViewedCategory: recentlyViewedCategoryReducer,
    wsRate: wsRateReducer
}