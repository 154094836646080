import React, {useEffect, useState} from "react";

import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";

import "./HelpPage.sass"

import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks";
import pageServices from "../../services/pageServices";


const HelpPage = () => {
    const {t} = useTranslation();
    const currentLang = useAppSelector((state) => state.currentLang.value)

    const [content, setContent] = useState<string>('')

    useEffect(() => {
        pageServices.getPage('help').then(data => {
            setContent(data.content)
            })
    }, [currentLang, content])

    return (
        <div className="help-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-container">
                    <h1>{t('help.help')}</h1>

                    <div className="white-box" dangerouslySetInnerHTML={{__html: content}}>

             {/*           {content}*/}

                        {/*{currentLang == 'uk' ? <div>
                            <ul>
                                <li><a href="https://youtu.be/p7I7Uqf3TtQ/" target="_blank">Як користуватись особистим кабінетом?</a></li>
                                <li><a href="https://www.youtube.com/watch?v=iSbj0ZuDRro/" target="_blank">Про бренди Velotrade</a></li>
                                <li><a href="https://www.youtube.com/watch?v=HbZY1JOd2cE/" target="_blank">Як збирають велосипеди на заводі Velotrade?</a></li>
                                <li><a href="https://youtu.be/Nkj23p_ua3I/" target="_blank">Як зібрати велосипед із коробки?</a></li>
                                <li><a href="https://youtu.be/KQu5xeP-1zM/" target="_blank">Як продавати велосипеди?</a></li>
                                <li><a href="https://youtu.be/jV2Vw-efFcw/" target="_blank">Як працювати із запереченнями?</a></li>
                                <li><a href="https://youtu.be/pVyKzVqdhVY/" target="_blank">Як оформити дизайн магазину?</a></li>
                            </ul>

                            <ul>
                                <li><a
                                    href="https://old.velotrade.com.ua/prom-ua-uk.xml"
                                    target="_blank"
                                >
                                    XML-файл для сайтів на prom.ua
                                </a></li>
                                <li><a
                                    href="https://old.velotrade.com.ua/shop-price-link-uk.xml"
                                    target="_blank"
                                >
                                    XML-файл для сайтів укр.
                                </a></li>
                                <li><a
                                    href="https://drive.google.com/drive/folders/1HbEKi1zZS6-AhDcB_LAgrxXMbhGgL9Ht"
                                    target="_blank">Фото, відео, специфікації на товар
                                </a></li>
                                <li><a
                                    href="https://app.box.com/s/99whx3s77fh6ljhiv8n8wp2qukdfmc34"
                                    target="_blank">Посібник з роздрібного продажу
                                </a></li>

                                <li><a
                                    href="https://drive.google.com/open?id=1l6sK5ymslxSJDWh3lX2O8x9JR7tFbD_M"
                                    target="_blank">Тарифи доставки
                                </a></li>
                            </ul>
                        </div> : <div>
                            <ul>
                                <li><a href="https://youtu.be/p7I7Uqf3TtQ/" target="_blank">Как пользоваться личным кабинетом?</a></li>
                                <li><a href="https://www.youtube.com/watch?v=iSbj0ZuDRro/" target="_blank">Про бренды Velotrade</a></li>
                                <li><a href="https://www.youtube.com/watch?v=HbZY1JOd2cE/" target="_blank">Как собирают велосипеды на заводе Velotrade?</a></li>
                                <li><a href="https://youtu.be/Nkj23p_ua3I/" target="_blank">Как собрать велосипед из коробки?</a></li>
                                <li><a href="https://youtu.be/KQu5xeP-1zM/" target="_blank">Как продавать велосипеды?</a></li>
                                <li><a href="https://youtu.be/jV2Vw-efFcw/" target="_blank"> Как работать с возражениями?</a></li>
                                <li><a href="https://youtu.be/pVyKzVqdhVY/" target="_blank">Как оформить дизайн розничного магазина?</a></li>
                            </ul>

                            <ul>
                                <li><a
                                    href="https://old.velotrade.com.ua/prom-ua-uk.xml"
                                    target="_blank"
                                >
                                    XML файл для сайтов на prom.ua
                                </a></li>
                                <li><a
                                    href="https://old.velotrade.com.ua/shop-price-link-uk.xml"
                                    target="_blank"
                                >
                                    XML файл для сайтов укр.
                                </a></li>
                                <li><a
                                    href="https://drive.google.com/drive/folders/1HbEKi1zZS6-AhDcB_LAgrxXMbhGgL9Ht"
                                    target="_blank">Фото, видео, спецификации на товар
                                </a></li>
                                <li><a
                                    href="https://app.box.com/s/99whx3s77fh6ljhiv8n8wp2qukdfmc34"
                                    target="_blank">Руководство по розничной продаже
                                </a></li>

                                <li><a
                                    href="https://drive.google.com/open?id=1l6sK5ymslxSJDWh3lX2O8x9JR7tFbD_M"
                                    target="_blank">Тарифы доставки
                                </a></li>
                            </ul>
                        </div>}*/}



                    </div>

                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default HelpPage