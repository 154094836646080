import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import Product from "../interfaces/Product/Product";
import {CartProduct, CartOrder} from "../interfaces/CartProduct";

const initialVal: CartOrder[] = [];

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setCart: (state, action: PayloadAction<CartOrder[]>) => {
            state.value = action.payload
        },
        addToCart: (state, action: PayloadAction<{product: Product, activeOrder: number}>) => {
            const cartProduct: CartProduct = {count: 1, product: action.payload.product}
            state.value[action.payload.activeOrder].orderItems.push(cartProduct)
        },
        removeFromCart: (state, action: PayloadAction<{productId: number, activeOrder: number}>) => {
            state.value[action.payload.activeOrder].orderItems = state.value[action.payload.activeOrder].orderItems.filter((s,i) => s.product.id != action.payload.productId)
        },
        updateCount: (state, action: PayloadAction<{productId: number, activeOrder: number, count: number}>) => {
            if(state.value.length != 0){
                const orderItem = state.value[action.payload.activeOrder].orderItems.find((item) => item.product.id == action.payload.productId)

                if (orderItem){
                    orderItem.count = action.payload.count
                }
            }

        },
        addOrder: (state, action: PayloadAction<number>) => {
            state.value.push({orderId: action.payload, orderItems: []})
        },
        removeOrder: (state, action: PayloadAction<{orderId: number}>) => {
            state.value = state.value.filter(order => order.orderId != action.payload.orderId)
        }

    }
});
export const { setCart, addToCart, removeFromCart, updateCount, addOrder, removeOrder } = cartSlice.actions;

export default cartSlice.reducer