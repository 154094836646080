import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {Grid, Row, Col} from "rsuite";

import TotalOrder from "../../../components/TotalOrder/TotalOrder";

import './OrderFinished.sass'
import {CarrierType} from "../../../enums/CarrierType";
import DeliverySavedAddress
    from "../../../components/OrderProducts/OrderProductsDelivery/DeliverySavedAddress/DeliverySavedAddress";
import CartOrderProducts from "../../../components/CartOrderProducts/CartOrderProducts";
import GetOrderModel from "../../../dto/order/GetOrderModel";
import {useTranslation} from "react-i18next";
import User from "../../../interfaces/User";
import DeliveryPayerComponent from "../../../components/DeliveryPayerComponent/DeliveryPayerComponent";


const OrderFinished: React.FC<{order: GetOrderModel, user: User, productsCount: number}> = ({order, user, productsCount}) => {
    const {t} = useTranslation();

/*    const user = useAppSelector<User>(state => state.user.value)
    const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)

    let totalProducts = 0;

    if (productsCart.length != 0) {
        productsCart[activeOrder].orderItems.forEach((product) => {
            totalProducts += product.count
        })
    }*/
    /*const [productsCount, setProductsCount] = useState(totalProducts)*/

    /*const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)

    let totalProducts = 0;

    if (productsCart.length != 0) {
        productsCart[activeOrder].orderItems.forEach((product) => {
            totalProducts += product.count
        })
    }

    const [productsCount, setProductsCount] = useState(totalProducts)*/
    return (
        <Grid>
            <Row>
                <Col sm={24} md={8} lg={16}>
                    <div className="order-finished-contact_data">
                        <div className="order-finished-number">
                            {t('finishedOrders.thaksOrder')} №{order.id} {t('finishedOrders.done')}!
                        </div>
                        <h2 className="order-finished-info">
                            {t('finishedOrders.lookForOrder')} <NavLink to={'/my-orders'}> {t('finishedOrders.myOrders')}</NavLink>.
                        </h2>
                        <div className="order-finished-data_info">
                            <h3>
                                {t('finishedOrders.orderInfo')}
                            </h3>
                            <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.contactData')}</span>
                                <span className="order-finished-data_item-value"> {order.contactName}</span>
                            </div>

                            <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.phone')}</span>
                                <span className="order-finished-data_item-value"> {order.contactPhone}</span>
                            </div>

                            <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.delivery')}</span>
                                <span className="order-finished-data_item-value">
                                    {order.deliveryAddress && order.deliveryAddress.carrierType == CarrierType.NewPost ? <span>{t('carrierType.NewPost')}</span> : null}
                                    {order.deliveryAddress && order.deliveryAddress.carrierType == CarrierType.MeestExpress ? <span>{t('carrierType.MeestExpress')}</span> : null}
                                    {order.deliveryAddress && order.deliveryAddress.carrierType == CarrierType.Delivery ? <span>{t('carrierType.Delivery')}</span> : null}
                                    {order.deliveryAddress && order.deliveryAddress.carrierType == CarrierType.UkrPost ? <span>{t('carrierType.UkrPost')}</span> : null}
                                    {order.isPickup ? <span>{t('carrierType.PickUp')}</span> : null}
                                </span>
                            </div>

                            <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.deliveryAddress')}</span>
                                <span className="order-finished-data_item-value">
                                    <DeliverySavedAddress savedAddress={order.deliveryAddress}
                                        paymentPickupData={order.isPickup? {deliveryPayer: order.deliveryPayer,
                                            orderToPay:order.cashOnDeliveryAmount,
                                            carModel: order.carModel,
                                            carNumber: order.carNumber,
                                            pickupDate: order.pickupDate,
                                            pickupTime: order.pickupTime}: null} labels={false} />
                                </span>
                            </div>

                            <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.contactPerson')}</span>
                                <span className="order-finished-data_item-value">
                                    {order.isUserReceiver? user.lastName: order.receiver.lastName}&nbsp;
                                    {order.isUserReceiver? user.firstName: order.receiver.firstName}&nbsp;
                                    {order.isUserReceiver? user.middleName: order.receiver.middleName}
                                </span>
                            </div>

                            <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.contactPhone')}</span>
                                <span className="order-finished-data_item-value"> {order.isUserReceiver? user.phone: order.receiver.phone}</span>
                            </div>

                            {order.isPickup? null: <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.deliveryPayer')}</span>
                                <span className="order-finished-data_item-value">
                                    <DeliveryPayerComponent deliveryPayer={order.deliveryPayer} />
                                </span>
                            </div>}

                            {order.isPickup || !order.cashOnDeliveryAmount? null: <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.cod')}</span>
                                <span className="order-finished-data_item-value">{order.cashOnDeliveryAmount}</span>
                            </div>}

                            <div className="order-finished-data_item">
                                <span className="order-finished-data_item-key">{t('finishedOrders.comments')}</span>
                                <span className="order-finished-data_item-value">{order.comment}</span>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={24} md={8} lg={8}>
                    <h3>{t('order.yourOrder')}</h3>
                    <div className="order-list-details-more">
                        <CartOrderProducts countButton={false} order={{orderId:order.id, orderItems: order.items.map(item => {
                                return {
                                    product: {
                                        id: item.productId,
                                        vendorCode: item.vendorCode,
                                        photoUrl: '',
                                        name: item.productName,
                                        lastPurchaseDate: '',
                                        available: 0,
                                        recommendedRetailPrice: item.recommendedRetailPrice,
                                        price: item.price,
                                        lastPurchaseCount: 0,
                                        categoryId: 0
                                    },
                                    count: item.count
                                }
                            })}}
                        missingItems={null}
                        />
                    </div>
                    <div className="order-finished-order">
                        <TotalOrder reserve={{orderId:order.id, orderItems: order.items.map(item => {
                                return {
                                    product: {
                                        id: item.productId,
                                        vendorCode: item.vendorCode,
                                        photoUrl: '',
                                        name: item.productName,
                                        lastPurchaseDate: '',
                                        available: 0,
                                        recommendedRetailPrice: item.recommendedRetailPrice,
                                        price: item.price,
                                        lastPurchaseCount: 0,
                                        categoryId: 0
                                    },
                                    count: item.count
                                }
                            })}} />
                    </div>
                </Col>
            </Row>
        </Grid>
    )
}

export default OrderFinished