import ShippingDetailsResponseDataModel from "../dto/novaPoshta/ShippingDetailsResponseDataModel";
import deliveryHttpHandler from "./deliveryHttpHandler";

const handler = new deliveryHttpHandler();

const getCulture = (lang: string) => {

    switch (lang){
        case 'uk': return 'uk-UA'
            break;
        case 'ru': return 'ru-RU'
            break;
        case 'eu': return 'en-US'
            break;
        default: return 'uk-UA'
    }
}

const getAreas = (lang: string): Promise<ShippingDetailsResponseDataModel[]> => {
    let culture = getCulture(lang)

    return handler.get(`GetRegionList?culture=${culture}&country=1`).then((data: any[]) => {
        return data? data.map(item => {
            return {id: item.id.toString(), name: item.name, uid: item.externalId}
        }): []
    });
}

const getCities = (areaId: string, lang: string): Promise<ShippingDetailsResponseDataModel[]> => {
    if (areaId == null || areaId == '') return new Promise(() => []);

    let culture = getCulture(lang)

    return handler.get(`GetAreasList?culture=${culture}&fl_all=true&regionId=${areaId}&country=1`).then((data: any[]) => {
        return data? data.map(item => {
            return {id: item.id, name: item.name}
        }): []
    });
}

const getBranches = (cityId: string, searchTerm: string, lang: string): Promise<ShippingDetailsResponseDataModel[]> => {
    if (cityId == null || cityId == '') return new Promise(() => []);

    let culture = getCulture(lang)

    return handler.get(`GetWarehousesList?culture=${culture}&includeRegionalCenters=true&CityId=${cityId}&country=1`).then((data: any[]) => {
        return data? data.map(item => {
            return {id: item.id , name: item.name + ', ' + item.address}
        }): []
    });
}






export default {
    getCities,
    getAreas,
    getBranches
}