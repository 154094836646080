import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initValue: {productId: number, count: number}[] = []

export const productCounterSlice = createSlice({
    name: 'productCounter',
    initialState: {
        value: initValue
    },
    reducers: {
        setProductCount: (state, action: PayloadAction<{productId: number, count: number}>) => {

            const findProduct = state.value.find(item => item.productId == action.payload.productId)

            if (findProduct){
                findProduct.count = action.payload.count
            }
            else {
                state.value.push({productId: action.payload.productId, count: action.payload.count })
            }


        }
    }
})
export const { setProductCount } = productCounterSlice.actions

export default productCounterSlice.reducer