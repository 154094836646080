import React, {MouseEventHandler} from "react";

import "./GenericButton.sass"
import {Button} from "rsuite";
import {useTranslation} from "react-i18next";

const GenericButton: React.FC<{generic: string, onClick: MouseEventHandler, isDisabled: boolean, showText: boolean}> = ({generic, onClick, isDisabled, showText}) => {
    const {t} = useTranslation();
    const textBtn = t('order.' + generic)
    return (
        <Button
            appearance="link"
            className={"generic-btn btn-" + generic}
            disabled={isDisabled}
            onClick={onClick}>
            {showText ? textBtn : null}
        </Button>
    )
}
export default GenericButton