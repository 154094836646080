import React, {useEffect, useState} from "react";


import Header from "../../blocks/Header/Header";
import GenericButton from "../../components/Buttons/GenericButton";
import {Button, Dropdown, FlexboxGrid, Input, InputGroup, Pagination} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import MyOrdersTable from "../../components/MyOrdersTable/MyOrdersTable";
import Footer from "../../blocks/Footer";
import {useTranslation} from "react-i18next";
import GetOrderModel from "../../dto/order/GetOrderModel";
import orderService from "../../services/orderService";
import Paging from "../../interfaces/Pagination";
import SearchBlock from "../../components/SearchBlock/SearchBlock";
import {OrderStatus} from "../../enums/OrderStatus";

import "./MyReserve.sass"
import TableBottom from "../../components/TableBottom/TableBottom";
import MyReserveTable from "../../components/MyOrdersTable/MyReserveTable";

const MyReserve:React.FC = () => {
    const {t} = useTranslation();

    const [myOrders, setMyOrders] = useState<GetOrderModel[]>([])
    const [paging, setPaging] = useState<Paging>({
        totalPageCount: 1,
        totalItemCount: 1,
        currentPageNumber: 1,
        pageSize: 25
    })
    const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([])
    //let selectedOrderIds: number[] = [];

    const [downloadButtonState, setDownloadButtonState] = useState(true)

    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const changePageSize = (key: number) => {
        setPageSize(key)
    }
    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }

    useEffect(() => {
        orderService.getOrders(search, currentPage, pageSize, true).then(data => {
            setMyOrders(data.items)
            setPaging({
                totalPageCount: data.totalPageCount,
                totalItemCount: data.totalItemCount,
                currentPageNumber: data.currentPageNumber,
                pageSize: data.pageSize
            })
            setTotalItems(data.totalItemCount);
        })
    }, [pageSize, currentPage, search])

    const setActivePage = (page: number) => {
        setPaging({...paging, currentPageNumber: page})
    }

    const searchMyOrders = (value: string, event: any) => {
        setSearch(value)
    }

    const downloadOrders = () => {

        orderService.getOrdersExel(selectedOrderIds).then(blob => {

            const url = window.URL.createObjectURL(
                blob,
            );
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "reserves.xlsx");
            document.body.appendChild(link); // Required for FF
            link.click();
            document.body.removeChild(link);
        })

        /*const selectedOrders = myOrders.filter(order => selectedOrderIds.includes(order.id));
        let csv = "data:text/csv;charset=utf-8,";
        const universalBOM = "\uFEFF";
        csv += universalBOM + `${t('orderCsv.orderNumber')},${t('orderCsv.orderDate')},${t('orderCsv.orderStatus')},${t('orderCsv.orderAmount')},${t('orderCsv.orderProfit')},${t('orderCsv.orderShipping')},${t('orderCsv.orderShippingInvoice')}\n`;
        csv += selectedOrders.map(order => {
            const profit = order.totalProfitUSD * order.exchangeRate;
            const status = order.status as OrderStatus;
            let shipping = order.deliveryAddress.state ? order.deliveryAddress.state + ',' : '' +
            order.deliveryAddress.district ? order.deliveryAddress.district + ',' : '' +
            order.deliveryAddress.city ? order.deliveryAddress.city + ',' : '' +
            order.deliveryAddress.carrierBranch ? order.deliveryAddress.carrierBranch + ',' : '' +
            order.deliveryAddress.street ? order.deliveryAddress.street + ',' : '' +
            order.deliveryAddress.house ? order.deliveryAddress.house + ',' : '' +
            order.deliveryAddress.apartment ? order.deliveryAddress.apartment + ',' : '' +
            order.carModel ? order.carModel + ',' : '' +
            order.carNumber ? order.carNumber + ',' : '' +
            order.pickupDate ? order.pickupDate + ',' : '' +
            order.pickupTime ? order.pickupTime : '';
            let shippingArray = Array.from(shipping);
            shipping = shippingArray[shippingArray.length - 1] == ','? shippingArray.slice(0, -1).join(''):
                shippingArray.join('');
            return `${order.id},${order.orderDate} ${order.orderTime},${status},${order.totalAmount},${profit},"${shipping}",${order.shippingInvoiceNumber ? order.shippingInvoiceNumber: ''}`;
        }).join("\n");
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "orders.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);*/
    }

    const deleteItem = (id: number) => {
        orderService.cancelOrder(+id).then(() => {

            orderService.getOrders(search, currentPage, pageSize, true).then(data => {
                setMyOrders(data.items)
                setPaging({
                    totalPageCount: data.totalPageCount,
                    totalItemCount: data.totalItemCount,
                    currentPageNumber: data.currentPageNumber,
                    pageSize: data.pageSize
                })
                setTotalItems(data.totalItemCount);
            })
        })
    }

    return (
        <div className="my-orders-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <h2>{t('myOrders.myReserveTitle')}</h2>

                    <SearchBlock
                        search={searchMyOrders}
                        searchPlaceHolder={t('myOrders.search')}
                        buttonClick={downloadOrders}
                        buttonText={t('order.downloadOrders')}
                        buttonState={downloadButtonState}
                    />
                    <div className="main-table">
                        <MyReserveTable
                            data={myOrders}
                            onOrderSelectionChange={ ids => setSelectedOrderIds(ids) }
                            setButtonState={setDownloadButtonState}
                            deleteItem={deleteItem}
                        />
                    </div>

                    <TableBottom
                        onPageSizeChange={changePageSize}
                        onActivePageChange={changeActivePage}
                        totalItemCount={totalItems}
                        pageSize={pageSize}
                        currentPage={currentPage}
                    />

                    {/*<FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="show-items-wrapper">
                            {t('order.showProducts')}:
                            <Dropdown onSelect={onChangePageSizeKey} activeKey={pageSize} title={paging.pageSize}>
                                <Dropdown.Item eventKey={25} onSelect={onChangePageSizeKey}>25</Dropdown.Item>
                                <Dropdown.Item eventKey={50} onSelect={onChangePageSizeKey}>50</Dropdown.Item>
                                <Dropdown.Item eventKey={75} onSelect={onChangePageSizeKey}>75</Dropdown.Item>
                                <Dropdown.Item eventKey={100} onSelect={onChangePageSizeKey}>100</Dropdown.Item>
                                <Dropdown.Item eventKey={125} onSelect={onChangePageSizeKey}>125</Dropdown.Item>
                            </Dropdown>
                        </FlexboxGrid.Item>

                        <Pagination
                            prev
                            last
                            next
                            first
                            maxButtons={5}
                            className={"pagination-wrapper"}
                            total={paging.totalItemCount}
                            limit={paging.pageSize}
                            activePage={paging.currentPageNumber}
                            onChangePage={setActivePage} />
                    </FlexboxGrid>*/}

                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default MyReserve