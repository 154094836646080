import React from "react";

import "./ProfileContactsPersons.sass"
import GenericButton from "../Buttons/GenericButton";
import {ContactPerson} from "../../interfaces/ContactPerson";
import contactPersonServices from "../../services/contactPersonServices";

const ProfileContactsPersons: React.FC<{isEditContactData: Function, contactPersonInfo: ContactPerson, deleteItem: Function}> = (
    {isEditContactData, contactPersonInfo, deleteItem}) => {

    return (
        <div className="profile-contact_person-item">
            <div className="name">
                {contactPersonInfo.lastName}&nbsp;
                {contactPersonInfo.firstName}&nbsp;
                {contactPersonInfo.middleName}
            </div>
            <div className="phone">
                {contactPersonInfo.phone}
            </div>
            <div className="edit">
                <GenericButton generic={"edit"} onClick={() => isEditContactData(contactPersonInfo)} isDisabled={false} showText={true} />
            </div>
            <div className="delete">
                <GenericButton generic={"delete"} onClick={() => deleteItem(contactPersonInfo.id)} isDisabled={false} showText={false} />
            </div>

        </div>
    )
}
export default ProfileContactsPersons