import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialVal: number = 28;

export const rateSlice = createSlice({
        name: 'rate',
        initialState: {
            value: initialVal,
        },
        reducers: {
            setRate: (state, action: PayloadAction<number>) => {
                state.value = action.payload
            }
        }
    }
);

export const { setRate } = rateSlice.actions

export default rateSlice.reducer