import React, {useEffect, useRef, useState} from "react";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable, VisibilityState} from "@tanstack/react-table";
import {Button, Col, FlexboxGrid, Modal, Row} from "rsuite";
import {useTranslation} from "react-i18next";
import {NavLink, useSearchParams} from "react-router-dom";
import ProductCountTable from "../ProductCountTable/ProductCountTable";
import AddToComare from "../AddToComare/AddToComare";
import { Pagination } from 'rsuite';

import {useAppDispatch, useAppSelector} from "../../hooks";

import Paging from "../../interfaces/Pagination"

import {setPagination} from "../../slices/paginationSlice";
import {ProductEntity} from "../../interfaces/Product/ProductEntity";

import "./Table.sass";

import NoImage from '../../assets/img/no-img_small.svg';
import ProductCountSum from "../ProductCountSum/ProductCountSum";
import tableSettingsService from "../../services/tableSettingsService";
import User from "../../interfaces/User";
import {TableSettings} from "../../interfaces/TableSettings";
import ProductSlider from "../../blocks/ProductDescription/ProductSlider/ProductSlider";
import ProductAvailable from "../ProductAvailable/ProductAvailable";

const columnHelper = createColumnHelper<ProductEntity>();

const Table: React.FC<{data: ProductEntity[], openModal: boolean, handleClose: Function | null, pagination: boolean}> = ({data, openModal, handleClose, pagination}) => {


    const paging = useAppSelector<Paging>((state) => state.pagination.value);
    const userTableSettings = useAppSelector<VisibilityState>(state => state.user.value.tableSettings)
    const wsRate = useAppSelector<number>((state) => state.wsRate.value);

    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const setActivePage = (page: number) => {
        setSearchParams("page=" + page)
        dispatch(setPagination({
            "totalPageCount": paging.totalPageCount,
            "totalItemCount": paging.totalItemCount,
            "currentPageNumber": page,
            "pageSize": paging.pageSize,
        }))
    }


    const { t } = useTranslation();
    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)
    const [isMobileView, setIsMobileView] = useState(false)
    const [paginationMaxButtons, setPaginationMaxButtons] = useState(5)
    useEffect(() => {
        if(deviceWidth <= 1024) {
            setIsMobileView(true)
            setPaginationMaxButtons(3)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])


    const tableRef = useRef<any>(null);
    const tableRefMobile = useRef<any>(null);
    useEffect(()=> {
        isMobileView ? tableRefMobile.current.scrollIntoView() : tableRef.current.scrollIntoView()
    },[data])


    const columnsCell = [
        columnHelper.accessor('vendorCode', {
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.vendorCode')}</span>,
        }),
        columnHelper.accessor(row => row.photoUrl, {
            id: 'url',
            cell: info => <div className="img-zoom">{info.getValue() != null ?
                <div><span></span><img src={info.getValue()} width={"38px"} /></div>
                :
                <img src={NoImage} width={"38px"} />}</div>,
            header: () => <span>{t('productsTableHeader.url')}</span>,
        }),
        columnHelper.accessor(row => row.name, {
            id: 'name',
            cell: info => <NavLink to={'/product/' + info.row.original.id} className={"name"}>{info.getValue()}</NavLink>,
            header: () => <span>{t('productsTableHeader.name')}</span>,
        }),
        columnHelper.accessor(row => row.lastPurchaseDate, {
            id: 'lastPurchase',
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.lastPurchase')}</span>,
        }),
        columnHelper.accessor(row => row.recommendedRetailPrice, {
            id: 'rrc',
            cell: info => <span>{Math.round(info.getValue() * wsRate)} {t('uah')}</span>,
            header: () => <span>{t('productsTableHeader.rrc')}</span>,
        }),
        columnHelper.accessor(row => row.price, {
            id: 'price',
            cell: info => <span>{Number(info.getValue()).toFixed(2)} $</span>,
            header: () => <span>{t('productsTableHeader.price')}</span>,
        }),
        columnHelper.accessor(row => row.available, {
            id: 'available',
            cell: info => <ProductAvailable count={info.row.original.available} dateAvailable={info.row.original.dateAvailable}></ProductAvailable>,
            header: () => <span>{t('productsTableHeader.left')}</span>,
        }),
        columnHelper.accessor(row => row.toCart, {
            id: 'toCart',
            cell: info => <ProductCountTable buttonName={t('add')} appointment={"cart"} product={info.row.original}></ProductCountTable>,
            header: () => <span>{t('productsTableHeader.toCart')}</span>,
        }),
        /*columnHelper.accessor(row => row.toReserve, {
            id: 'toReserve',
            cell: info => <ProductCountTable buttonName={t('add')} appointment={"reserve"} product={info.row.original}></ProductCountTable>,
            header: () => <span>{t('productsTableHeader.toReserve')}</span>,

        }),*/
        columnHelper.accessor(row => row.totalPrice, {
            id: 'totalPrice',
            cell: info => <ProductCountSum price={info.getValue()} id={info.row.original.id}></ProductCountSum>,
            header: () => <span>{t('productsTableHeader.totalPrice')}</span>,
        }),
        columnHelper.accessor(row => row.income, {
            id: 'income',
            cell: info => <ProductCountSum price={info.getValue()} id={info.row.original.id}></ProductCountSum>,
            header: () => <span>{t('productsTableHeader.income')}</span>,
        }),
        columnHelper.accessor(row => row.compare, {
            id: 'compareHeader',
            cell: info => <AddToComare chosenProduct={info.row.original}></AddToComare>,
            header: () => <span></span>,
        }),
    ]

    const [columns] = useState<typeof columnsCell>(() => [
        ...columnsCell,
    ])

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        "vendorCode": true,
        "url": true,
        "name": true,
        "lastPurchase": true,
        "rrc": true,
        "price": true,
        "available": true,
        /*"toReserve": false,*/
        "toCart": true,
        "totalPrice": true,
        "income": false,
        "compareHeader": true
    })

    useEffect(() => {

        if(userTableSettings) {
            setColumnVisibility(userTableSettings)
            table.setColumnVisibility(userTableSettings)
        }

    }, [userTableSettings])


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {columnVisibility},
        onColumnVisibilityChange: setColumnVisibility,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    })

    const saveTableSettings = () => {

        if(handleClose) handleClose()

        tableSettingsService.updateTableSettings({...columnVisibility, ...table.getState().columnVisibility})
    }

    const [openImage, setOpenImage] = useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => {
        setImagesForSlider([])
        setOpenImage(false)
    }
    const [imagesForSlider, setImagesForSlider] = useState<string[]>([])

    const setImage = (imageUrl: string) => {
        setOpenImage(true)
        setImagesForSlider(prevState => ([...prevState, imageUrl]))
    }

    const [currentSlideNumber, setCurrentSlideNumber] = useState(0);

    return(
<div className={"table-wrapper"} ref={tableRefMobile}>
    {handleClose ? <Modal className={"table_settings-wrapper"} open={openModal} onClose={saveTableSettings}>
            <Modal.Header>
                <Modal.Title>{t('tableSettings')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="inline-block">

                    {table.getAllLeafColumns().map(column => {
                        return (
                            <div key={column.id} className="px-1">
                                {column.id != 'compareHeader' ? <label>
                                    <input
                                        {...{
                                            type: 'checkbox',
                                            checked: column.getIsVisible(),
                                            onChange: column.getToggleVisibilityHandler(),
                                        }}
                                    />{' '}
                                    <span className={"checkbox"}>{t('productsTableHeader.' + column.id)}</span>
                                </label> : null}

                            </div>
                        )
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={saveTableSettings} appearance="primary">
                    {t('filters.use')}
                </Button>
            </Modal.Footer>
        </Modal> : null}


    <Modal open={openImage} onClose={handleCloseImage} className="modal-slider">
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
            <ProductSlider
                openModalSlider={handleOpenImage}
                images={imagesForSlider}
                setInitialSlide={setCurrentSlideNumber}
                initialSlide={currentSlideNumber}
            ></ProductSlider>
        </Modal.Body>
    </Modal>

    {isMobileView ? <div className={"table-mobile-view"}>
        {data.map((product, index) =>
            <div className={"product-item"} key={index}>
                <FlexboxGrid className={"product-info"}>
                    <FlexboxGrid.Item className={"product-info-vendorCode"}>
                        <div className="value vendorCode">{product.vendorCode}</div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="product-info-image">
                        <div className="value">
                            <img
                                onClick={() => setImage(product.photoUrl ? product.photoUrl : NoImage)}
                                src={product.photoUrl ? product.photoUrl : NoImage}
                                alt={product.name}
                            />
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="product-info-name">
                        <div className="value">
                            <NavLink to={'/product/' + product.id}>{product.name}</NavLink>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <Row className={"product-info"}>
                    <Col xs={6}>
                        <div className="title">{t('productsTableHeader.lastPurchase')}</div>
                        <div className="value">{product.lastPurchaseDate}</div>
                    </Col>
                    <Col xs={6}>
                        <div className="title">{t('productsTableHeader.rrc')}</div>
                        <div className="value">{product.recommendedRetailPrice} $</div>
                    </Col>
                    <Col xs={6}>
                        <div className="title">{t('productsTableHeader.price')}</div>
                        <div className="value bold">{Number(product.price).toFixed(2)} $</div>
                    </Col>
                    <Col xs={6}>
                        <div className="title">{t('productsTableHeader.left')}</div>
                        <div className="value"><ProductAvailable count={product.available} dateAvailable={product.dateAvailable}></ProductAvailable></div>
                    </Col>
                </Row>
                <FlexboxGrid  className={"product-actions"}>
                    {/*<FlexboxGrid.Item>
                        <div className="title">{t('productsTableHeader.toReserve')}</div>
                        <div className="value">
                            <ProductCountTable buttonName={t('add')} appointment={"reserve"} product={product}></ProductCountTable>
                        </div>
                    </FlexboxGrid.Item>*/}
                    <FlexboxGrid.Item >
                        <div className="title">{t('productsTableHeader.toCart')}</div>
                        <div className="value">
                            <ProductCountTable buttonName={t('add')} appointment={"cart"} product={product}></ProductCountTable>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item >
                        <div className="title">{t('productsTableHeader.totalPrice')}</div>
                        <div className="value">
                            <ProductCountSum price={product.totalPrice} id={product.id}></ProductCountSum> $
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <div className="title">{t('productsTableHeader.income')}</div>
                        <div className="value">
                            <ProductCountSum price={product.income} id={product.id}></ProductCountSum> $</div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className={"compare-wrapper"}>
                        <div className="value">
                            <AddToComare chosenProduct={product}></AddToComare>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        )}

    </div> : <div className={"table-responsive"} >
        <table ref={tableRef}>
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <th className={header.id} key={header.id}>
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className={cell.column.id + ' index-row__' + row.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    </div>}

    {paging.totalPageCount > 1 && pagination ? <Pagination
        prev
        last
        next
        first
        maxButtons={paginationMaxButtons}
        className={"pagination-wrapper"}
        total={paging.totalItemCount}
        limit={paging.pageSize}
        activePage={paging.currentPageNumber}
        onChangePage={setActivePage} /> : null}


</div>
    )
}

export default Table