import React from "react";

import "./KeyValuePaire.sass"
import {DeliveryPayer} from "../../enums/DeliveryPayer";

const KeyValuePaire:React.FC<{label: string, value: string | number | DeliveryPayer}> = ({label, value}) => {
    return (
        <div className="key-value-info">
            <div className="label">{label}</div>
            <div className="value">
                {value}
            </div>
        </div>
    )
}
export default KeyValuePaire