import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialVal: number = 30;

export const wsRateSlice = createSlice({
        name: 'rate',
        initialState: {
            value: initialVal,
        },
        reducers: {
            setWSRate: (state, action: PayloadAction<number>) => {
                state.value = action.payload
            }
        }
    }
);

export const { setWSRate } = wsRateSlice.actions

export default wsRateSlice.reducer