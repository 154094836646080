import React from "react";

import "./ProfileShippingAdressItem.sass"
import {useTranslation} from "react-i18next";
import GenericButton from "../Buttons/GenericButton";
import ShippingAddress from "../../interfaces/ShippingAddress";
import {CarrierType} from "../../enums/CarrierType";

import NewPost from "../../assets/img/new_post_sm.png";
import Delivery from "../../assets/img/delivery_sm.png";
import Ukrpost from "../../assets/img/ukrpost_sm.png";
import PickUp from "../../assets/img/pickup.svg";
import {DeliveryType} from "../../enums/DeliveryType";

const ProfileShippingAdressItem: React.FC<{isEditShippingData: Function, addressInfo: ShippingAddress, deleteShipping: Function}> = (
    {isEditShippingData, addressInfo, deleteShipping}) => {
    const { t } = useTranslation();

    return (
        <div className="profile-shipping-address-item">
            <div className="logo">
                {addressInfo.carrierType == CarrierType.NewPost ? <img src={NewPost} alt="NewPost" width="24" height="24" />  : null}
                {addressInfo.carrierType == CarrierType.Delivery ? <img src={Delivery} alt="Meest" width="24" height="24" />  : null}
                {addressInfo.carrierType == CarrierType.UkrPost ? <img src={PickUp} alt="Address Shipping" width="24" height="24" />  : null}
                {addressInfo.carrierType == CarrierType.PickUp ? <img src={PickUp} alt="PickUp" width="24" height="24" />  : null}
            </div>
            <div className="storage">
                {addressInfo.deliveryType == DeliveryType.CourierDelivery ? t('delivery.addressDelivery') : null}
                {addressInfo.deliveryType == DeliveryType.BranchDelivery ? t('delivery.stockDelivery') : null}
            </div>
            <div className="address">
                {addressInfo.street ? addressInfo.street +', ' : null}
                {addressInfo.house ? addressInfo.house +', ' : null}
                {addressInfo.apartment ? addressInfo.apartment +', ' : null}
                {addressInfo.city ? addressInfo.city +', ' : null}
                {addressInfo.state ? addressInfo.state : null}
                {addressInfo.carrierBranch ? ', ' + addressInfo.carrierBranch : null}
            </div>
            <div className="info">
                По умолочанию
            </div>
            <div className="edit">
                <GenericButton generic={"edit"} onClick={() => isEditShippingData(addressInfo)} isDisabled={false} showText={true} />
            </div>
            <div className="delete">
                <GenericButton generic={"delete"} onClick={() => deleteShipping(addressInfo.id)} isDisabled={false} showText={false} />
            </div>
        </div>
    )
}
export default ProfileShippingAdressItem