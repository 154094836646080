import {ViewedCategories} from "../interfaces/Category";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initVal: ViewedCategories[] = []

export const recentlyViewedCategorySlice = createSlice({
    name: 'recentlyViewedCategory',
    initialState: {
        value: initVal,
    },
    reducers: {
        setRecentlyViewedCategory: (state, action: PayloadAction<ViewedCategories[]>) => {
            state.value = action.payload
        }
    }
})

export const { setRecentlyViewedCategory } = recentlyViewedCategorySlice.actions

export default recentlyViewedCategorySlice.reducer