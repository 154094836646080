import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NavLink} from "react-router-dom";

import {Category} from "../../interfaces/Category";

import {useAppDispatch, useAppSelector} from "../../hooks";
import {setChosenFiltersObj} from "../../slices/chosenFiltersSlice";

import './CategoriesList.sass'
import {setPagination} from "../../slices/paginationSlice";
import Pagination from "../../interfaces/Pagination";

const CategoriesList: React.FC<{
    children: Category[],
    level: number,
    closeMenu: Function,
    mobileView: boolean,
    parentCat?: string | null,
    parentId?: number| null,
    parentSlug?: string | null,
    closeLevelMenuCallback?: (parentId: number | null) => void,
    goToFirstLevel: Function
}> = (
    {children,
        level,
    closeMenu,
    mobileView,
        parentCat,
        parentId,
        closeLevelMenuCallback,
        goToFirstLevel}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    let hasChildren = ''
    let slug = ''



    const [liItemState, setLiItemState] = useState(false)

    const [items, setItems] = useState(children)


    useEffect(() => {
        setItems(children)
    }, [children])

    const pagination = useAppSelector<Pagination>(state => state.pagination.value);

    const clearChosenFilters = () => {
        dispatch(setChosenFiltersObj([]))

        dispatch(setPagination({
            "totalPageCount": pagination.totalPageCount,
            "totalItemCount": pagination.totalItemCount,
            "currentPageNumber": 1,
            "pageSize": pagination.pageSize,
        }))
    }

    const liClick = (item: Category) => {


        if (mobileView) {

            if(!item.children){
                closeMenu(false)
            }
            let itemId = items.map(itemMenu => itemMenu.id).indexOf(item.id)

            if (itemId != -1){

                const itemActive = {...items[itemId], isActive: true}

                if(parentId) {
                    const itemActiveParent = {...items[parentId], isActive: false}
                }

                let fakeItems = [...items]
                fakeItems.splice(itemId, 1, itemActive)

                setItems([...fakeItems])
            }


            setLiItemState(true)
        }

    }


    const closeLevelMenu = (parentId: number | null) => {
        if (mobileView && parentId) {


            let itemId = items.map(itemMenu => itemMenu.id).indexOf(parentId)

            if (itemId != -1){

                const itemActive = {...items[itemId], isActive: false}
                let fakeItems = [...items]
                fakeItems.splice(itemId, 1, itemActive)
                setItems(fakeItems)
            }

            setLiItemState(true)
        }
        else {
            closeMenu(false)
        }
    }

    const onClickItemFilterMenu = () => {
        closeMenu(true)
        dispatch(setPagination({
            "totalPageCount": pagination.totalPageCount,
            "totalItemCount": pagination.totalItemCount,
            "currentPageNumber": 1,
            "pageSize": pagination.pageSize,
        }))
    }

    return (

             <ul>
                {items.map((item, index) => {
                    if (item.children) {hasChildren = 'has-children'} else {hasChildren = 'no-children'}
                    if (item.slug) {slug = item.slug} else {slug = ''}
                    const parentCat = item.name
                    const parentSlug = item.slug
                    let st: any = {}
                    st[`f${parentId}`] = item.name
                     return (
                    <li key={index}
                        className={item.isActive ? hasChildren + " child-li-"+level + " active " + slug : hasChildren + " child-li-"+level + " " + slug}>


                        {item.id == 89
                            ?
                            !item.children
                                ?
                                <NavLink
                                to={`/category/${item.id}?f${parentId}=${item.name}`}
                                state={st}
                                onClick={onClickItemFilterMenu}
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                <span>{item.name}</span>
                            </NavLink>
                                :
                                <span className={mobileView && hasChildren ? 'has-button' : ''}>
                                <NavLink
                                to={'/category/89'}
                                onClick={onClickItemFilterMenu}
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                <span>{item.name}</span>
                            </NavLink>
                                    {mobileView && hasChildren ? <button className="innerCategory" onClick={() => liClick(item)}></button> : null}
                                </span>
                            :
                            !item.children
                                ?
                                <NavLink
                            to={'/category/' + item.id }
                            onClick={clearChosenFilters}
                            className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                        }
                            >
                            <span>{item.name}</span>
                                    {/*{mobileView ? <button className="innerCategory" onClick={() => liClick(item)}>333</button> : null}*/}
                            </NavLink>
                                :
                                item.id == +process.env.REACT_APP_BICYCLE_BRAND_FILTER_ID! ||
                                item.id == +process.env.REACT_APP_BICYCLE_TYPE_FILTER_ID! ?
                                    <span className={mobileView && hasChildren ? 'has-button' : ''}>
                                        <NavLink
                                            to={`/`}
                                            onClick={(e) => { e.preventDefault()}}
                                            className={({ isActive, isPending }) =>
                                                isPending ? "pending" : isActive ? "active" : ""
                                            }
                                        >
                                            <span>{item.name}</span>

                                        </NavLink>
                                        {mobileView && hasChildren ? <button className="innerCategory" onClick={() => liClick(item)}></button> : null}
                                    </span>
                                    :
                                    <span className={mobileView && hasChildren ? 'has-button' : ''}>
                                        <NavLink
                                            to={'/category/' + item.id }
                                            onClick={clearChosenFilters}
                                            className={({ isActive, isPending }) =>
                                                isPending ? "pending" : isActive ? "active" : ""
                                            }
                                        >
                                            <span>{item.name}</span>

                                        </NavLink>
                                        {mobileView && hasChildren ? <button className="innerCategory" onClick={() => liClick(item)}></button> : null}
                                    </span>

                        }

                        {/*{!item.children ? <NavLink
                            to={'/category/' + item.id }
                            onClick={clearChosenFilters}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""
                            }
                        >
                            <span>{item.name}</span>
                        </NavLink> : <div onClick={() => liClick(item)} className={item.isActive ? "no-category active" : "no-category"}><span>{item.name}</span>
                        </div>}*/}
                        {item.children && <div className={"category-menu child-"+ (level + 1)}>

                            {mobileView && parentCat ? <div className="contorl-category-wrapper" onClick={() => closeLevelMenu(item.id)}>
                                <span className="parent-category-name">{parentCat}</span>
                                <button onClick={() => goToFirstLevel()} className="btn btn-usual">{t('allCategories')}</button>
                            </div> : null}  <CategoriesList
                            children={item.children}
                            level={level+1}
                            closeMenu={closeMenu}
                            mobileView={mobileView}
                            parentCat={parentCat}
                            parentId={item.id}
                            parentSlug={parentSlug}
                            closeLevelMenuCallback={closeLevelMenu}
                            goToFirstLevel={goToFirstLevel}
                        ></CategoriesList> </div>}
                    </li>
                    )
                })}
            </ul>
    )
}
export default CategoriesList