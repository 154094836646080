import React from "react";
import {OrderStatus} from "../../enums/OrderStatus";
import {useTranslation} from "react-i18next";

const OrderTypes:React.FC<{status: number}> = ({status}) => {
    const {t} = useTranslation();
    return(
        <div className={"my-order-single-created " + "status-" + status}>
                        <span>
                            {status == OrderStatus.New ? t('orderSingle.state.created') : null}
                            {status == OrderStatus.Shipped ? t('orderSingle.state.shipped') : null}
                            {status == OrderStatus.Processing ? t('orderSingle.state.gettingReady') : null}
                            {status == OrderStatus.Confirmed ? t('orderSingle.state.confirmed') : null}
                            {status == OrderStatus.Cancelled ? t('orderSingle.state.deleted') : null}
                            {status == OrderStatus.InReserve ? t('orderSingle.state.reserve') : null}
                        </span>
        </div>
    )
}
export default OrderTypes