import React, {useRef, useState} from "react";
import {Form, Modal, Schema, Uploader, Input, Toggle} from "rsuite";
import {useTranslation} from "react-i18next";
import AuthProvider from "../../services/AuthProvider";

import "./ExelOrderModal.sass"
import {FileType} from "rsuite/Uploader";
import ApiResponseModel from "../../dto/ApiResponseModel";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {setCart} from "../../slices/cartSlice";
import CartOrdersModel from "../../dto/login/CartOrdersModel";


const TextField = React.forwardRef((props: any, ref: any) => {
    const { name, label, message, accepter, ...rest } = props;
    return (
        <Form.Group controlId={`${name}`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const UploadField = React.forwardRef((props: any, ref: any) => {
    const { name, message, accepter, draggable, ...rest } = props;
    const { t } = useTranslation();


    const [fileList, setFileList] = useState<FileType[]>([])

    return (
        <div className="uploader-fake-wrapper">
            <Form.Group controlId={`${name}`}>
                <div className="uploader">
                    <Form.Control name={name} accepter={accepter} onChange={(val) => setFileList([...val])}  {...rest} />
                </div>
                <div className="uploader-fake-wrapper">
                    <Uploader draggable ref={ref} fileList={fileList} {...rest} >
                        <div style={{ height: 80, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, textAlign: "center" }}>
                            <span>{t('uploadExel.dragDrop')}</span>
                        </div>
                    </Uploader>
                </div>
                <Form.HelpText>{message}</Form.HelpText>
            </Form.Group>
        </div>

    );
});

const ExelOrderModal:React.FC<{openModalExel: boolean, handleCloseExelModal: Function}> = (
    {openModalExel, handleCloseExelModal}) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});
    const errorField = t('form.requiredFieldMsg')
    const language = useAppSelector((state) => state.currentLang.value)

    const { NumberType, ObjectType } = Schema.Types;
    const model = Schema.Model({
        column: NumberType().isRequired(errorField),
        count: NumberType().isRequired(errorField),
        field: ObjectType().isRequired(errorField)
    });


    const uploader = useRef<any>();
    const [columnVal, setColumnVal] = useState<number | null>(null)
    const [countVal, setCountVal] = useState<number | null>(null)

    const setColumn = (value: number , event: any) => {
        value = event.target.value.replace(/[^0-9]/g, "")
        setColumnVal(value)
    }


    const setCount = (value: number , event: any) => {
        value = event.target.value.replace(/[^0-9]/g, "")
        setCountVal(value)
    }

    const [firstRow, setFirstRow] = useState(false)
    const checkFirstRow = (checked: boolean, event: any) => {
        setFirstRow(checked => !checked)
    }

    const [isOrderSuccess, setIsOrderSuccess] = useState(false)

    const submitOrder = () => {
        if (formRef.current!.check() && uploader.current) {
            uploader.current.start();

        }
    }

    const onFileUploaded = (response: ApiResponseModel, file: FileType) => {
        dispatch(setCart(response.data.map((order: CartOrdersModel) => {
            return {
                orderId: order.id,
                orderItems: order.items.map((item) => {
                    return {
                        product: {
                            id: item.productId,
                            vendorCode: item.vendorCode,
                            photoUrl: '',
                            name: item.name,
                            lastPurchaseDate: '',
                            available: item.available,
                            recommendedRetailPrice: item.recommendedRetailPrice,
                            price: item.price,
                            lastPurchaseCount: 0,
                            categoryId: 0
                        },
                        count: item.count
                    }
                })
            }

        })))
        setIsOrderSuccess(true)
    }

    return (
        <div className="exel-order-modal-wrapper">
            <Modal className={"exel-order-wrapper"} open={openModalExel} onClose={() => handleCloseExelModal()}>
                <Modal.Header>
                    <Modal.Title>
                        {t('uploadExel.uploadExel')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border border-black shadow rounded">
                        {isOrderSuccess ? t('uploadExel.success') : <Form ref={formRef}
                                                   model={model}
                                                   onCheck={setFormError} >

                            <UploadField
                                data={{ProductColIndex: columnVal ? (columnVal - 1) : null, CountColIndex: countVal ? (countVal - 1) : null, ContainsHeader: firstRow}}
                                headers={{authorization: 'bearer ' + AuthProvider.getAuthToken(), "Accept-Language": language}}
                                autoUpload={false}
                                name="field"
                                accepter={Uploader}
                                action={process.env.REACT_APP_API_URL! + 'cart/from-excel'}
                                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={uploader}
                                onSuccess={onFileUploaded}
                            />

                            <div className="order-status-state">
                                <Toggle checked={firstRow} onChange={checkFirstRow} className="comparison-difference" />
                                Первая строка содержит заголовок
                            </div>


                            <div className="input-wrapper">
                                <TextField
                                    name="column"
                                    className="column"
                                    label={t('uploadExel.column')}
                                    accepter={Input}
                                    value={columnVal}
                                    onChange={setColumn}
                                    min={1}
                                />
                            </div>

                            <div className="input-wrapper">
                                <TextField
                                    name="count"
                                    className="count"
                                    label={t('uploadExel.count')}
                                    accepter={Input}
                                    value={countVal}
                                    onChange={setCount}
                                    min={1}
                                />
                            </div>

                            <button disabled={Object.keys(formError).length ? true: false}
                                    type="submit"
                                    className="btn btn-lg"
                                    onClick={submitOrder}
                            >{t('uploadExel.submit')}</button>

                        </Form>}


                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default ExelOrderModal