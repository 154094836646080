import ShippingDetailsResponseDataModel from "../dto/novaPoshta/ShippingDetailsResponseDataModel";
import ukrpostHttpHandler from "./ukrpostHttpHandler";
import ApiHttpHandler from "./apiHttpHandler";

const handler = new ApiHttpHandler();

/*const getCulture = (lang: string) => {

    switch (lang){
        case 'uk': return 'uk-UA'
            break;
        case 'ru': return 'ru-RU'
            break;
        case 'eu': return 'en-US'
            break;
        default: return 'uk-UA'
    }
}*/

const getAreas = (): Promise<{id: number, name: string}[]> => {
    return handler.get(`ukrposhta/areas`)
}

const getDistricts = (areaId: number): Promise<{id: number, name: string}[]> => {
    return handler.get(`ukrposhta/areas/${areaId}/districts`)
}
const getCities = (districtId: number): Promise<{id: number, name: string}[]> => {
    return handler.get(`ukrposhta/districts/${districtId}/cities`)
}

const getStreets = (cityId: number): Promise<{id: number, name: string}[]> => {
    return handler.get(`ukrposhta/cities/${cityId}/streets`)
}

export default {
    getAreas,
    getDistricts,
    getCities,
    getStreets
}