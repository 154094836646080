import moment from 'moment';
import store from "../store";
import User from "../interfaces/User";
import {setUser} from "../slices/userSlice";
import {setCurrentLanguage} from "../slices/currentLangSlice";
import {setWSRate} from "../slices/wsRateSlice";

const AuthProvider = () => {
    let authToken: string | null = null;
    let authExpirationStr: string | null = null;
    let authExpiration: string | moment.Moment;
    let refreshToken: string | null = null;
    let refreshExpirationStr: string | null = null;
    let refreshExpiration: string | moment.Moment;
    let permissions: string[];
    let roles: string[];

    const init = () => {
        let res = 0;
        const lang = localStorage.getItem('language');
        if (lang) {
            store.dispatch(setCurrentLanguage(lang))
        }
        if (authToken == null){
            authToken = localStorage.getItem('authToken');
            authExpirationStr = localStorage.getItem('authExpiration');
            authExpiration = authExpirationStr != null? moment.utc(authExpirationStr, 'DD.MM.YYYY hh:mm:ss'): '';
            if (authToken == null) {
                res = 1;
            }
        }
        if (refreshToken == null){
            refreshToken = localStorage.getItem('refreshToken');
            refreshExpirationStr = localStorage.getItem('refreshExpiration');
            refreshExpiration = refreshExpirationStr != null? moment.utc(refreshExpirationStr, 'DD.MM.YYYY hh:mm:ss'): '';
            if (refreshToken == null){
                res = 2;
            }
        }
        if (moment.utc().isAfter(authExpiration)) {
            res = 1;
            localStorage.removeItem('authToken');
            localStorage.removeItem('authExpiration');
            authToken = null;
            authExpiration = '';
            authExpirationStr = null;
        }
        if (moment.utc().isAfter(refreshExpiration)) {
            res = 2;
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('refreshExpiration');
            refreshToken = null;
            refreshExpiration = '';
            refreshExpirationStr = null;
        }

        if (res === 0 || res === 1){
            const userStr = localStorage.getItem('user')
            if(userStr != null) {
                const user = JSON.parse(userStr) as User
                store.dispatch(setUser(user))
                store.dispatch(setWSRate(user.exchangeRateWS))
                permissions = user.userPermissions
                roles = user.roles
            }

        }

        return res;
    }

    const getAuthToken = () => {
        if (moment.utc().isAfter(authExpiration) || authToken == null) {
            //window.location.href = '/login';
            return null;
        }
        return authToken;
    }

    const setAuthToken = (auth: {token: string | null, expiration: string | null}) => {
        if(auth.token != null && auth.expiration != null) {
            authToken = auth.token;
            authExpiration = moment.utc(auth.expiration, 'DD.MM.YYYY hh:mm:ss');
            localStorage.setItem('authToken', auth.token);
            localStorage.setItem('authExpiration', auth.expiration);
        } else {
            authToken = null;
            authExpirationStr = null;
            localStorage.removeItem('authToken');
            localStorage.removeItem('authExpiration');
        }
    }

    const getRefreshToken = () => {
        if (moment.utc().isAfter(refreshExpiration)) return null;
        return refreshToken;
    }

    const setRefreshToken = (refresh: {token: string, expiration: string}) => {
        refreshToken = refresh.token;
        refreshExpiration = moment.utc(refresh.expiration, 'DD.MM.YYYY hh:mm:ss');
        localStorage.setItem('refreshToken', refresh.token);
        localStorage.setItem('refreshExpiration', refresh.expiration);
    }

    const updateUser = (user: User) => {
        permissions = user.userPermissions;
        roles = user.roles;
        store.dispatch(setUser(user))
        localStorage.setItem('user', JSON.stringify(user))
    }

    const updateLanguage = (lang: string) => {
        localStorage.setItem('language', lang);
    }

    const getPermissions = () => {
        return permissions ?? [];
    }

    const getRoles = () => {
        return roles ?? [];
    }

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        localStorage.removeItem('authExpiration');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('refreshExpiration');
    }

    return {
        init,
        updateLanguage,
        getAuthToken,
        setAuthToken,
        getRefreshToken,
        setRefreshToken,
        updateUser,
        getPermissions,
        getRoles,
        logout
    }
}

export default AuthProvider();