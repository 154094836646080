import React, {useEffect, useState} from "react";

import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";

import "./SubscriptionPage.sass"
import SearchBlock from "../../components/SearchBlock/SearchBlock";
import {useTranslation} from "react-i18next";
import TableSubscriptions from "../../components/TableSubscriptions/TableSubscriptions";
import subscriptionservices from "../../services/subscriptionservices";
import Subscriptions from "../../dto/Subscriptions";
import TableBottom from "../../components/TableBottom/TableBottom";
import {useAppSelector} from "../../hooks";


const SubscriptionPage:React.FC = () => {

    const {t} = useTranslation();

    const currentLang = useAppSelector((state) => state.currentLang.value)


    const [subscriptions, setSubscriptions] = useState<Subscriptions[]>([])
    const [valueSearchSubscription, setValueSearchSubscription] = useState('')
    const [pageSize, setPageSize] = useState(25)
    const [totalItems, setTotalItems] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const changePageSize = (key: number) => {
        setPageSize(key)
    }

    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }

    useEffect(() => {
        subscriptionservices.getSubscriptions(valueSearchSubscription, currentPage, pageSize).then((data) => {
            setSubscriptions(data.items)
            setTotalItems(data.totalItemCount);
        })
    }, [valueSearchSubscription, currentPage, pageSize, currentLang])

    const deleteSubscription = (id: number) => {
        subscriptionservices.deleteSubscription(id).then(() => {
            subscriptionservices.getSubscriptions(valueSearchSubscription, currentPage, pageSize).then((data) => {
                setSubscriptions(data.items)
                setTotalItems(data.totalItemCount);
            })
            }
        )
    }


    const searchSubscriptions = () => {

    }


    return (
        <div className="subscription-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <h2>{t('subscriptions.subscriptions')}</h2>

                    <SearchBlock
                        search={searchSubscriptions}
                        searchPlaceHolder={t('subscriptions.search')}
                        buttonClick={false}
                        buttonText={''}
                        buttonState={false}
                    />

                    <TableSubscriptions data={subscriptions} deleteSubscription={deleteSubscription} />

                    <TableBottom
                        onPageSizeChange={changePageSize}
                        onActivePageChange={changeActivePage}
                        totalItemCount={totalItems}
                        pageSize={pageSize}
                        currentPage={currentPage}
                    />
                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default SubscriptionPage