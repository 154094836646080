import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import AddOrderModel from "../dto/order/AddOrderModel";

const initValue: AddOrderModel[] = []

export const orderSlice = createSlice({
    name: 'orderSlice',
    initialState: {
        value: initValue
    },
    reducers: {
        setOrders: (state, action: PayloadAction<AddOrderModel[]>) => {
            state.value = action.payload;
        },
        clearOrders: (state, action: PayloadAction) => {
            state.value = []
        },
        addOrder: (state, action: PayloadAction<AddOrderModel>) => {
            state.value.push(action.payload)
        },
        removeOrder: (state, action: PayloadAction<number>) => {
            const orderItem = state.value.map((item) => item.tempId).indexOf(action.payload)

            if (orderItem != -1){
                state.value.splice(orderItem, 1)
            }
        },
        updateOrder: (state, action: PayloadAction<AddOrderModel>) => {
            const orderItem = state.value.map((item) => item.tempId).indexOf(action.payload.tempId)

            if (orderItem != -1){
                state.value.splice(orderItem, 1, action.payload)
            }
        },
    }
})

export const { addOrder, removeOrder, clearOrders, updateOrder, setOrders } = orderSlice.actions

export default orderSlice.reducer