import ApiHttpHandler from "./apiHttpHandler";
import PaginatedResult from "../dto/PaginatedResult";
import Subscriptions from "../dto/Subscriptions";
import SubscriptionModel from "../interfaces/SubscriptionModel";

const handler = new ApiHttpHandler();

const getSubscriptions = (term: string, page: number, pageSize: number): Promise<PaginatedResult<Subscriptions>> => {
    return handler.get(`subscriptions?term=${term}&page=${page}&pageSize=${pageSize}`)
}

const deleteSubscription = (subscriptionId: number) => {
    return handler.delete(`subscriptions/${subscriptionId}`)
}

const deleteSubscriptionProduct = (subscriptionId: number, productId: number) => {
    return handler.delete(`subscriptions/${subscriptionId}/${productId}`)
}

const addSubscription = (data: SubscriptionModel) => {
    return handler.post(`subscriptions`, data)
}

export default {
    getSubscriptions,
    deleteSubscription,
    deleteSubscriptionProduct,
    addSubscription
}