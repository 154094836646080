import React from "react";
import {SubscriptionType} from "../../../../enums/SubscriptionType";
import {useTranslation} from "react-i18next";

const Type:React.FC<{type: SubscriptionType}> = ({type}) => {
    const {t} = useTranslation();
    return (
        <span>
            {type == SubscriptionType.NewProducts ? t('subscriptions.typeNewProducts') : null}
            {type == SubscriptionType.Availability ? t('subscriptions.typeAvailability') : null}
            {type == SubscriptionType.Both ? t('subscriptions.typeBoth') : null}
        </span>
    )
}
export default Type