import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Schema} from "rsuite";

import PhoneInput from "../PhoneInput/PhoneInput";

import './ContactData.sass'
import {useAppSelector} from "../../../hooks";
import User from "../../../interfaces/User";
import GenericButton from "../../Buttons/GenericButton";
import userService from "../../../services/userService";


function TextField(props: any) {
    const { fieldName, value, setValue, accepter, label, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                accepter={accepter} {...rest}
            />
        </Form.Group>
    );
}

const ContactData:React.FC<{
    editContactData: Function, title: string, profile: boolean}> = ({editContactData, title, profile}) => {
    const { t } = useTranslation();

    const user = useAppSelector<User>(state => state.user.value)
    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        email: user.email
    });


    const errorField = t('form.requiredFieldMsg')
    const errorEmail = t('form.validEmailAddress')

    const lastName = t('form.lastName')
    const firstName = t('form.firstName')
    const middleName = t('form.middleName')
    const email = t('form.email')

    const { StringType } = Schema.Types;
    const errorSymbols = t('form.errorSymbols')
    const model = Schema.Model({
        firstName: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
        lastName: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols).isRequired(errorField),
        middleName: StringType().addRule((value, data) => {
            return /^[A-Za-zА-Яа-яІіЇїЄєҐґ\-'`]+$/.test(value);
        }, errorSymbols),
        email: StringType()
            .isEmail(errorEmail)
            .isRequired(errorField),
    });

    const [contactDataFirstName, setContactDataName] = useState(user.firstName)
    const [contactDataLastName, setContactDataLastName] = useState(user.lastName)
    const [contactDataMiddleName, setContactDataMiddleName] = useState(user.middleName)
    const [contactDataPhone, setContactDataPhone] = useState(user.phone)
    const [contactDataEmail, setContactDataEmail] = useState(user.email)


    const [errorVisible, setErrorVisible] = useState(false);

    const [toggleEditContactData, setToggleEditContactData] = useState(false)
    const isEditContactData = () => {
        setToggleEditContactData(toggleEditContactData => !toggleEditContactData)
    }

    const saveEditContactData = () => {
        if (!errorVisible){
            let phone = contactDataPhone.replace(/[\(\)\-\+\_ ]/g, '');
            setContactDataPhone(phone)

            editContactData(contactDataFirstName, contactDataLastName, contactDataMiddleName, contactDataEmail, contactDataPhone)
            setToggleEditContactData(toggleEditContactData => !toggleEditContactData)

            userService.updateUserData(contactDataFirstName, contactDataLastName, contactDataMiddleName, contactDataPhone)
        }

    }

    useEffect(() => {

    let phone = contactDataPhone.replace(/[\(\)\-\+\_ ]/g, '');
        if(phone.length != 12){
            setErrorVisible(true)
        } else {
            setErrorVisible(false)
        }
    }, [contactDataPhone])

    return (
        <div className="contact-data">
            <Form model={model} ref={formRef} formDefaultValue={formValue} onCheck={setFormError}>
                <div className="contact-data-header">
                    <h3>{title}</h3>
                    {!profile ?
                        toggleEditContactData ? <GenericButton
                            generic={"save"}
                            onClick={saveEditContactData}
                            isDisabled={Object.keys(formError).length || errorVisible ? true: false}
                            showText={true}
                        />  : <GenericButton
                            generic={"edit"}
                            onClick={isEditContactData}
                            isDisabled={false}
                            showText={true}
                        />
                        : null}

                </div>

                {!toggleEditContactData ? <div className="contact-data-body">
                    
                    <div className="full-name">
                        {profile ? <span className="title">{t('contactData.name')}</span> : null}
                        <span className="contact-data_value contact-data_name">{contactDataLastName} </span>
                        <span className="contact-data_value contact-data_name">{contactDataFirstName} </span>
                        <span className="contact-data_value contact-data_name">{contactDataMiddleName}</span>
                    </div>
                    <div className="phone">
                        {profile ? <span className="title">{t('contactData.phone')}</span> : null}
                        <span className="contact-data_value contact-data_phone">{contactDataPhone.replace(/[\(\)\-\+\_ ]/g, '')}</span>
                    </div>
                    <div className="email">
                        {profile ? <span className="title">E-mail</span> : null}
                        <span className="contact-data_value contact-data_email">{contactDataEmail}</span>
                    </div>
                    {profile ? <div className="debt-limit">
                        <span className="title">{t('contactData.debtLimit')}</span>
                        <span className="contact-data_value">{user.creditLimit}</span>
                    </div> : null}
                </div> : <div>
                    <div className={formError.hasOwnProperty('firstName') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField
                            fieldName="firstName"
                            placeholder={firstName}
                            value={contactDataFirstName}
                            setValue={setContactDataName}
                            key={'firstName'}
                            label={t('form.firstName')}
                        />
                    </div>
                    <div className={formError.hasOwnProperty('lastName') ? "input-wrapper has-error" : "input-wrapper"}>
                        <TextField
                            fieldName="lastName"
                            placeholder={lastName}
                            value={contactDataLastName}
                            setValue={setContactDataLastName}
                            key={'lastName'}
                            label={t('form.lastName')}
                        />
                    </div>
                    <div className="input-wrapper">
                        <TextField
                            fieldName="middleName"
                            placeholder={middleName}
                            value={contactDataMiddleName}
                            setValue={setContactDataMiddleName}
                            key={'middleName'}
                            label={t('form.middleName')}
                        />
                    </div>
                    <div className={errorVisible ? "input-wrapper has-error" : "input-wrapper"}>
                        <Form.Group controlId={'contact-data_phone'}>
                            <PhoneInput
                                valueFunction={setContactDataPhone}
                                valuePhone={contactDataPhone.substring(2)}
                                showError={errorVisible}
                                label={true}
                            />
                        </Form.Group>
                    </div>
                    <div className={formError.hasOwnProperty('email') ? "input-wrapper has-error" : "input-wrapper"}>
                        {profile ? <TextField
                            name="email"
                            placeholder={email}
                            key={'email'}
                            readOnly
                            value={contactDataEmail}
                            setValue={setContactDataEmail}
                            label={t('form.email')}
                        /> : <TextField
                            name="email"
                            placeholder={email}
                            key={'email'}
                            value={contactDataEmail}
                            setValue={setContactDataEmail}
                            label={t('form.email')}
                        />}

                    </div>
                </div>
                }
            </Form>
        </div>
    )
}
export default ContactData