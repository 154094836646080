import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const fetchStateSlice = createSlice ({
        name: 'fetchState',
        initialState: {
            value: 0,
        },
        reducers: {
            setStateFetch: (state, action: PayloadAction<number>) => {
                state.value = action.payload
            }
        }
    }
);
export const { setStateFetch } = fetchStateSlice.actions

export default fetchStateSlice.reducer