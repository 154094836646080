import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Toggle, Pagination, Form, Schema, Col, Grid, Row} from "rsuite";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import {useAppDispatch, useAppSelector} from "../../hooks";

import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";

import {setUser, updateMessageStatus, updateUserNpClientName, updateUserNPKey} from "../../slices/userSlice";

import ProfileShippingAdressItem from "../../components/ProfileShippingAdressItem/ProfileShippingAdressItem";
import ProfileMyManagerItem from "../../components/ProfileMyManagerItem/ProfileMyManagerItem/ProfileMyManagerItem";
import ProfileMyManagerModal from "../../components/ProfileMyManagerItem/ProfileMyManagerModal/ProfileMyManagerModal";
import ProfileContactPerson from "../../components/ProfileMyManagerItem/ProfileContactPerson/ProfileContactPerson";
import ProfileContactsPersons from "../../components/ProfileContactsPersons/ProfileContactsPersons";
import ProfileShippingModal from "../../components/ProfileShippingAdressItem/ProfileShippingModal/ProfileShippingModal";
import SearchBlock from "../../components/SearchBlock/SearchBlock";
import ContactData from "../../components/Form/ContactData/ContactData";

import ShippingAddress from "../../interfaces/ShippingAddress";
import {ContactPerson} from "../../interfaces/ContactPerson";
import {ManagerProfile} from "../../interfaces/ManagerProfile";
import Paging from "../../interfaces/Pagination";
import User from "../../interfaces/User";

import AuthProvider from "../../services/AuthProvider";
import authService from "../../services/authService";
import contactPersonServices from "../../services/contactPersonServices";
import clientManagerService from "../../services/clientManagerService";
import deliveryAddressService from "../../services/deliveryAddressService";

import "./ProfilePage.sass"
import userService from "../../services/userService";

function TextField(props: any) {
    const { fieldName, label, value, setValue, accepter, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                accepter={accepter} {...rest}
            />
        </Form.Group>
    );
}

const ProfilePage: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)
    const [paginationMaxButtons, setPaginationMaxButtons] = useState(5)
    useEffect(() => {
        if(deviceWidth <= 1024) {
            setPaginationMaxButtons(3)
        }
        else {
            setPaginationMaxButtons(5)
        }

    }, [deviceWidth])

    const titleContactData = t('profile.personalData')

    const user = useAppSelector<User>(state => state.user.value)

    const [currentActivePage, setCurrentActivePage] = useState(1)

    const [tabIndex, setTabIndex] = useState(0);
    const searchPlaceholder = t('profile.search')

    const [pagingShipping, setPagingShipping] = useState<Paging>({
        "totalPageCount": 1,
        "totalItemCount": 1,
        "currentPageNumber": 1,
        "pageSize": 10
    })

    const setActivePage = (page: number) => {
        setPagingShipping({...pagingShipping, currentPageNumber: page})
    }

    const [pagingContactPersons, setPagingContactPersons] = useState<Paging>({
        "totalPageCount": 1,
        "totalItemCount": 1,
        "currentPageNumber": 1,
        "pageSize": 10
    })
    const setActiveContactPersonsPage = (page: number) => {
        setPagingContactPersons({...pagingContactPersons, currentPageNumber: page})
    }

    const editContactData = (firstName: string, lastName: string, middleName: string, email: string, phone: string) => {

        dispatch(setUser({...user,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            phone: phone,
            email: email}))
    }

    const logout = () => {
        AuthProvider.logout()
        authService.logout()
        navigate('/login')
    }

    const [openContactPerson, setOpenContactPerson] = useState(false);
    const handleOpenContactPerson = () =>{
        setOpenContactPerson(true)
    };
    const handleCloseContactPerson = (contactPerson: ContactPerson, isNewContact: boolean) => {
        if (contactPerson){
            if(isNewContact){

                setContactPersonsList((value) => [...value, contactPerson])
            }
            else {
                let index = contactPersonsList.map((contactPersonItem) => contactPersonItem.id).indexOf(contactPerson.id)
                contactPersonsList.splice(index, 1, contactPerson)
                setContactPersonsList((value) => [...contactPersonsList])
            }
        }
        setOpenContactPerson(false)
        setEditContactPersonData(null)
    };


    const [openShippingModal, setOpenShippingModal] = useState(false);
    const handleOpenShippingModal = () => setOpenShippingModal(true);

    const [deliveryAddressList, setDeliveryAddressList] = useState<ShippingAddress[]>([])
    const [searchTerm, setSearchTerm] = useState('')
    const searchShippingAddress = (value: string, event: any) => {
        if (value.length >= 2){
            setSearchTerm(value)
        } else {
            setSearchTerm('')
        }

    }
    useEffect(() => {
        deliveryAddressService.getDeliveryAddresses(searchTerm, pagingShipping.currentPageNumber, pagingShipping.pageSize).then(data => {
            setPagingShipping({
                "totalPageCount": data.totalPageCount,
                "totalItemCount": data.totalItemCount,
                "currentPageNumber": data.currentPageNumber,
                "pageSize": data.pageSize,
            })
            setDeliveryAddressList(data.items)

        })

    }, [searchTerm, pagingShipping.currentPageNumber])

    const [editShippingData, setEditShippingData] = useState<ShippingAddress | null>(null)

    const isEditShippingData = (shippingData: ShippingAddress) => {

        setEditShippingData(shippingData)
        setOpenShippingModal(true)

    }

    const handleCloseShippingModal = (shippingData: ShippingAddress, isNewShipping: boolean) => {
        setOpenShippingModal(false)
        setEditShippingData(null)

    };

    const onNewAddressChange = (data: ShippingAddress) => {
        if (data.id == null) {
            deliveryAddressService.addDeliveryAddress(data).then(() => {
                deliveryAddressService.getDeliveryAddresses(searchTerm, pagingShipping.currentPageNumber, pagingShipping.pageSize).then(data => {
                    setDeliveryAddressList(data.items)
                    setPagingShipping({
                        "totalPageCount": data.totalPageCount,
                        "totalItemCount": data.totalItemCount,
                        "currentPageNumber": data.currentPageNumber,
                        "pageSize": data.pageSize,
                    })
                })
            })
        } else {
            deliveryAddressService.editDeliveryAddress(data).then(() => {
                deliveryAddressService.getDeliveryAddresses(searchTerm, pagingShipping.currentPageNumber, pagingShipping.pageSize).then(data => {
                    setDeliveryAddressList(data.items)
                    setPagingShipping({
                        "totalPageCount": data.totalPageCount,
                        "totalItemCount": data.totalItemCount,
                        "currentPageNumber": data.currentPageNumber,
                        "pageSize": data.pageSize,
                    })
                })
            })
        }


    }

    const deleteShipping = (ShippingId: number) => {
        deliveryAddressService.deleteDeliveryAddress(ShippingId).then(() => {
            deliveryAddressService.getDeliveryAddresses(searchTerm, pagingShipping.currentPageNumber, pagingShipping.pageSize).then(data => {
                setDeliveryAddressList(data.items)
                setPagingShipping({
                    "totalPageCount": data.totalPageCount,
                    "totalItemCount": data.totalItemCount,
                    "currentPageNumber": data.currentPageNumber,
                    "pageSize": data.pageSize,
                })
            })
        })
    }

    const [contactPersonsList, setContactPersonsList] = useState<ContactPerson[]>([])
    useEffect(() => {
        contactPersonServices.getContactPersons(searchTerm, pagingContactPersons.currentPageNumber, pagingContactPersons.pageSize).then(data => {
            setContactPersonsList(data.items)
            setPagingContactPersons({
                "totalPageCount": data.totalPageCount,
                "totalItemCount": data.totalItemCount,
                "currentPageNumber": data.currentPageNumber,
                "pageSize": data.pageSize,
            })
        })
    }, [])


    const [editContactPersonData, setEditContactPersonData] = useState<ContactPerson | null>(null)


    const isEditContactData = (contactPersonData: ContactPerson) => {
        //setContactPersonsList((value) => [...value, contactPersonData])
        setOpenContactPerson(true)
        setEditContactPersonData(contactPersonData)
    }

    const deleteItem = (contactPersonId: number) => {
        contactPersonServices.deleteContactPersons(contactPersonId).then()

        let index = contactPersonsList.map((contactPersonItem) => contactPersonItem.id).indexOf(contactPersonId)
        contactPersonsList.splice(index, 1)
        setContactPersonsList((value) => [...contactPersonsList])
    }



    const [contactManagerList, setManagerPersonsList] = useState<ManagerProfile[]>([])


    useEffect(() => {
        clientManagerService.getClientManager().then(data => {
            setManagerPersonsList(data)
        })
    }, [])

    const [editClientManagerData, setEditClientManagerData] = useState<ManagerProfile | null>(null)

    const [openManagerModal, setOpenManagerModal] = useState(false);
    const handleOpenManagerModal = () => setOpenManagerModal(true);
    const handleCloseManagerModal = (managerData: ManagerProfile, isNewManager: boolean) => {
        if (managerData){
            if(isNewManager){
                setManagerPersonsList((value) => [...value, managerData])
            }
            else {
                let index = contactManagerList.map((managerItem) => managerItem.id).indexOf(managerData.id)
                contactManagerList.splice(index, 1, managerData)
                setManagerPersonsList((value) => [...contactManagerList])
            }
        }
        setOpenManagerModal(false)
        setEditClientManagerData(null)
    };

    const isEditClientManagerData = (clientManagerData: ManagerProfile) => {
        setOpenManagerModal(true)
        setEditClientManagerData(clientManagerData)
    }

    const deleteManager = (managerId: number) => {
        clientManagerService.deleteClientManager(managerId).then()

        let index = contactManagerList.map((managerItem) => managerItem.id).indexOf(managerId)
        contactManagerList.splice(index, 1)
        setManagerPersonsList((value) => [...contactManagerList])
    }

    const [showMessageStatus, setShowMessageStatus] = useState(user.isSendUpdates)
    const toggleMessageStatus = (checked: boolean, event: any) => {

        setShowMessageStatus(checked)

        userService.setNotificationsStatus(checked.toString())

        dispatch(updateMessageStatus(checked))

    }



    const [apiKeyValue, setApiKeyValue] = useState('')
    const [formError, setFormError] = useState({});
    const errorField = t('form.requiredFieldMsg')

    const [formValue, setFormValue] = useState({
        apiKey: apiKeyValue,
    });

    const { StringType } = Schema.Types;
    const errorSymbols = t('form.errorSymbols')
    const model = Schema.Model({
        apiKey: StringType().isRequired(errorField),
    });

    const [npClientName, setNpClientName] = useState(user.npClientName)
    const [isByDefault, setIsByDefault] = useState(false)

    useEffect(() => {
        if(user.npClientName !== null){
            setIsByDefault(true)
        }

    }, [user])

    const [isKeyValid, setIsKeyValid] = useState(false)

    useEffect(() => {
        setIsKeyValid(false)
    }, [apiKeyValue])

    const checkApiKey = (apiKeyValue: string) => {

        if(apiKeyValue != ''){
            userService.updateNPKey(apiKeyValue).then(data => {
                if (data.isKeyValid){
                    setIsKeyValid(false)
                    setIsByDefault(true)
                    setNpClientName(data.npClientName)
                    dispatch(updateUserNpClientName(data.npClientName))
                }
                else {
                    setIsKeyValid(true)

                }

            })
        }

    }

    const [isChangeBuDefault, setIsChangeBuDefault] = useState(user.useNPNameByDefault)

    const changeByDefault = (checked: boolean, event: any) => {

        setIsChangeBuDefault(current => !current)

        userService.updateUserNPKeyState(checked).then(data => {
            setIsChangeBuDefault(checked)

            dispatch(updateUserNPKey(checked))

        })

    }

    const deleteNpKey = () => {
        userService.deleteNpKey().then(data => {
            setIsByDefault(false)
            dispatch(updateUserNpClientName(null))
        })
    }

    return (
        <div className="profile-page page-wrapper">
            <Header></Header>
            <main>

                <div className="container">
                    <h1>{t('profile.title')}</h1>

                    <div className="profile-personal-data">

                        <ContactData
                            title={titleContactData}
                            editContactData={editContactData}
                            profile={true}
                        />

                        <div className="order-status-state">
                            <Toggle className="comparison-difference" checked={showMessageStatus} onChange={toggleMessageStatus} />
                            {t('profile.sendStatusOrder')}
                        </div>

                        <button className="btn-usual" onClick={logout}>{t('profile.logout')}</button>

                    </div>

                    <div className="profile-personal-data">
                        <h3>{t('profile.counterpartyParameters')}</h3>

                        {isByDefault ?
                            <div>
                                <h6 className='mt-15'><strong>{t('profile.counterparty')}:</strong> {npClientName}</h6>
                                <div className="mt-15">
                                    <span style={{paddingRight: "25px"}}>{t('profile.counterpartyParametersByDefault')}</span>
                                    <Toggle checked={isChangeBuDefault} onChange={changeByDefault} className="comparison-difference" />
                                    <Grid  className="mt-15" fluid>
                                        <Row>
                                            <Col xs={24} sm={12} md={5}>
                                                <button onClick={deleteNpKey}
                                                    className="btn btn-lg">
                                                    {t('profile.deleteApiNPKey')}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </div>
                            </div>
                            :
                            <div className='mt-15'>
                                <p></p>

                                <Form
                                    model={model}
                                    formDefaultValue={formValue}
                                    onCheck={setFormError}
                                    onSubmit={() => checkApiKey(apiKeyValue)}
                                    className='mt-15'
                                >
                                    <Grid fluid>
                                        <Row>
                                            <Col xs={24} sm={12} md={8}>
                                                <div className={formError.hasOwnProperty('apiKey') ? "input-wrapper has-error" : "input-wrapper"}>
                                                    <TextField
                                                        fieldName="apiKey"
                                                        label={t('profile.apiNPKey')}
                                                        value={apiKeyValue}
                                                        setValue={setApiKeyValue}
                                                        key={'apiKey'}
                                                    />
                                                </div>
                                                <Form.ErrorMessage show={isKeyValid} placement={'bottomStart'}>
                                                    {t('profile.wrongApiKey')}
                                                </Form.ErrorMessage>
                                            </Col>
                                            <Col xs={24} sm={12} md={5}>
                                                <button type="submit" className="btn btn-lg">{t('btn.save')}</button>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </Form>
                            </div>
                        }


                    </div>

                    <div>
                        <Tabs className="delivery-tab-services" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                            <div className="tabs-header-view">
                                <TabList>
                                    <Tab>{t('profile.shippingAddress')}</Tab>
                                    {AuthProvider.getRoles().indexOf('Manager') !== -1? null: <Tab>{t('profile.MyManagers')}</Tab>}
                                    <Tab>{t('profile.ContactPersons')}</Tab>
                                </TabList>
                            </div>
                            <TabPanel>

                                <SearchBlock
                                    search={searchShippingAddress}
                                    searchPlaceHolder={searchPlaceholder}
                                    buttonClick={handleOpenShippingModal}
                                    buttonText={t('profile.addAddress')}
                                    buttonState={false}
                                />

                                <div className="shipping-address-list">

                                    {deliveryAddressList.map((deliveryAddress, index) => {
                                        return <ProfileShippingAdressItem
                                            isEditShippingData={isEditShippingData}
                                            key={index}
                                            addressInfo={deliveryAddress}
                                            deleteShipping={deleteShipping}/>
                                    })}
                                    <Pagination
                                        prev
                                        last
                                        next
                                        first
                                        maxButtons={paginationMaxButtons}
                                        className={"pagination-wrapper"}
                                        total={pagingShipping.totalItemCount}
                                        limit={pagingShipping.pageSize}
                                        activePage={pagingShipping.currentPageNumber}
                                        onChangePage={setActivePage}
                                    />
                                </div>

                            </TabPanel>
                            {AuthProvider.getRoles().indexOf('Manager') !== -1? null: <TabPanel>
                                <button onClick={handleOpenManagerModal} className="btn btn-lg add">{t('profile.addManager')}</button>

                                {contactManagerList.map((managerItem, index) => {
                                    return <ProfileMyManagerItem
                                        isEditManagerData={isEditClientManagerData}
                                        key={index}
                                        managerInfo={managerItem}
                                        deleteItem={deleteManager}/>
                                })}

                            </TabPanel>}
                            <TabPanel>

                                <button onClick={handleOpenContactPerson} className="btn btn-lg add">{t('profile.add')}</button>

                                {contactPersonsList.map((contactPersonItem, index) => {
                                    return <ProfileContactsPersons
                                        isEditContactData={isEditContactData}
                                        key={index}
                                        contactPersonInfo={contactPersonItem}
                                        deleteItem={deleteItem}
                                    />
                                })}
                                <Pagination
                                    prev
                                    last
                                    next
                                    first
                                    maxButtons={paginationMaxButtons}
                                    className={"pagination-wrapper"}
                                    total={pagingContactPersons.totalItemCount}
                                    limit={pagingContactPersons.pageSize}
                                    activePage={pagingContactPersons.currentPageNumber}
                                    onChangePage={setActiveContactPersonsPage}
                                />
                            </TabPanel>
                        </Tabs>
                    </div>

                    {openShippingModal ? <ProfileShippingModal
                        editShippingData={editShippingData}
                        openModal={openShippingModal}
                        addShippingData={onNewAddressChange}
                        handleCloseShippingModal={handleCloseShippingModal}
                    /> : null}

                    {openManagerModal ? <ProfileMyManagerModal
                        editManagerData={editClientManagerData}
                        openModal={openManagerModal}
                        handleCloseManagerModal={handleCloseManagerModal}
                    /> : null}

                    {openContactPerson ? <ProfileContactPerson
                        editContactPersonData={editContactPersonData}
                        openModal={openContactPerson}
                        handleCloseContactPerson={handleCloseContactPerson}
                    /> : null}


                </div>

            </main>
            {/*<Footer />*/}

        </div>
    )
}
export default ProfilePage