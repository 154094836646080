import React from "react";

import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";

import './CartPage.sass'
import Sidebar from "../../blocks/Sidebar/Sidebar";


const CartPage:React.FC = () => {
    return (
        <div className="cart-page">
            <Header></Header>
            <main>
                <div className="container">
                    <Sidebar />
                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default CartPage