import PaginatedResult from "../dto/PaginatedResult";
import {ContactPerson} from "../interfaces/ContactPerson";
import ApiHttpHandler from "./apiHttpHandler";

const handler = new ApiHttpHandler();

const getContactPersons = (searchTerm: string, page: number, pageSize: number): Promise<PaginatedResult<ContactPerson>> => {
    return handler.get(`contact-persons?searchTerm=${searchTerm}&page=${page}&pageSize=${pageSize}`)
}

const deleteContactPersons = (id: number) => {
    return handler.delete(`contact-persons/${id}`)
}

const addContactPerson = (contactPersonModel: ContactPerson) => {
    return handler.post(`contact-persons`, contactPersonModel).then()
}

const editContactPersons = (id: number, contactPersonModel: ContactPerson) => {
    return handler.put(`contact-persons/${id}`, contactPersonModel)
}

export default {
    getContactPersons,
    deleteContactPersons,
    addContactPerson,
    editContactPersons
}