import React, {Suspense, useEffect, useState} from "react";
import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import productService from "../../services/productService";

import Table from "../../components/Table/Table";

import {ProductEntity} from "../../interfaces/Product/ProductEntity";
import TableBottom from "../../components/TableBottom/TableBottom";
import Loading from "../../components/Loading/Loading";
import Tile from "../../components/Tile/Tile";
import {FlexboxGrid} from "rsuite";

const SearchPage = () => {
    const {t} = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const[searchResults, setSearchResults] = useState<ProductEntity[]>([])
    const [pageSize, setPageSize] = useState(25)
    const [totalItems, setTotalItems] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [viewCatalog, setViewCatalog] = useState(true)
    const param = searchParams.get("query")

    useEffect(() => {
        const param = searchParams.get("view")
        if(param == 'tile'){
            setViewCatalog(false)
        }

    }, [])


    const viewCatalogTile = () => {
        setViewCatalog(false)
        setSearchParams(`query=${param}&view=tile`)
    }
    const viewCatalogTable = () => {
        setViewCatalog(true)
        setSearchParams(`query=${param}&view=table`)
    }


    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)

    const [isMobileView, setIsMobileView] = useState(true)

    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    const changePageSize = (key: number) => {
        setPageSize(key)
    }

    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }




    useEffect(() => {
        if (param){
        productService.getProductsSearch(param, currentPage, pageSize).then(data => {
            setSearchResults(data.items.map((item) => {
                let prod = ProductEntity.CreateProductEntityShort(item)
                return prod
            }))

            setTotalItems(data.totalItemCount)


        })
        }
    }, [param, pageSize, currentPage])

    return (
        <div className="search-page page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    {isMobileView ?
                        <h2>{t('autocomplete.searchResults')}: "{param}" <span>{t('autocomplete.foundCount')}: {totalItems}</span></h2>
                         : <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item >
                                <h2>{t('autocomplete.searchResults')}: "{param}" <span>{t('autocomplete.foundCount')}: {totalItems}</span></h2>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <FlexboxGrid.Item className={"view-wrapper"}>
                                    <button onClick={viewCatalogTable} className={viewCatalog ? "tableView active" : "tableView"}></button>
                                    <button onClick={viewCatalogTile} className={viewCatalog ? "tileView" : "tileView active"}></button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>}

                    {viewCatalog ? <Table data={searchResults} openModal={false} handleClose={null}  pagination={false} />  : <Suspense fallback={<Loading />}><Tile data={searchResults} pagination={false} /></Suspense> }

                    <TableBottom
                        onPageSizeChange={changePageSize}
                        onActivePageChange={changeActivePage}
                        totalItemCount={totalItems}
                        currentPage={currentPage} />
                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default SearchPage