import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import translation from "./locales/ru/translation.json";
import translationUK from "./locales/uk/translation.json";

export const defaultNS = "translation";
export const resources = {
    ru: {
        translation: translation,
    },
    uk: {
        translation: translationUK
    }
} as const;

i18next.use(initReactI18next).init({
    lng: "uk",
//    defaultNS,
    debug: true,
    resources
});
