import React, {useEffect, useRef, useState} from "react";
import Product from "../../interfaces/Product/Product";

import {Grid, Row, Col, Pagination} from 'rsuite';
import AddToComare from "../AddToComare/AddToComare";
import ProductCountTable from "../ProductCountTable/ProductCountTable";
import {useTranslation} from "react-i18next";

import "./Tile.sass"
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks";
import Paging from "../../interfaces/Pagination";
import {setPagination} from "../../slices/paginationSlice";
import {ProductEntity} from "../../interfaces/Product/ProductEntity";
import TileProduct from "./TileProduct";

const Tile: React.FC<{data: ProductEntity[], pagination: boolean}> = ({data, pagination}) => {
    const { t } = useTranslation();
    const paging = useAppSelector<Paging>((state) => state.pagination.value);
    const dispatch = useAppDispatch();
    const setActivePage = (page: number) => {
        dispatch(setPagination({
            "totalPageCount": paging.totalPageCount,
            "totalItemCount": paging.totalItemCount,
            "currentPageNumber": page,
            "pageSize": paging.pageSize,
        }))
    }

    const tileWrapperRef = useRef<any>(null)
    useEffect(()=> {
        tileWrapperRef.current.scrollIntoView()
    },[data])

    return (
        <div className={"tile"}>
            <Grid fluid>
                <Row className="show-grid">
                    <div ref={tileWrapperRef}>
                    {data.map((product, index) =>
                        <Col key={index} xs={24} sm={8} md={6}>
                            <TileProduct
                                product={product}
                                showAdditionalControls={false}
                                deleteFromCompareList={null}
                            />
                        </Col>
                    )}
                    </div>
                </Row>
            </Grid>
            {paging.totalPageCount > 1 && pagination ? <Pagination
                prev
                last
                next
                first
                maxButtons={5}
                className={"pagination-wrapper"}
                total={paging.totalItemCount}
                limit={paging.pageSize}
                activePage={paging.currentPageNumber}
                onChangePage={setActivePage} /> : null}

        </div>
    )
}
export default Tile