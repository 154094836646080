import React, {useEffect, useState} from "react";
import ProductCount from "../ProductCount/ProductCount";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {addToReserve, removeFromReserve, updateCountReserve} from "../../slices/reserveSlice";
import Product from "../../interfaces/Product/Product";
import {use} from "i18next";
import {CartOrder} from "../../interfaces/CartProduct";
import {changeActiveTab} from "../../slices/activeSidebarTabSlice";
import {addCartOrderAsync, addProductToCartAsync, updateProductCountAsync} from "../../slices/actions/cartAsyncActions";
import cartServices from "../../services/cartServices";
import {changeActiveOrder} from "../../slices/activeOrderSlice";
import {removeFromCart, removeOrder} from "../../slices/cartSlice";

const ProductCountTable: React.FC<{
    product: Product,
    buttonName: string,
    appointment: string}> = ({ product, buttonName, appointment}) => {

    const [showCount, setShowCount] = useState(false);

    const products = useAppSelector<Product[]>((state) => state.products.value);
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)
    const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const productsReserve = useAppSelector<CartOrder>((state) => state.reserve.value);
    const productCount = useAppSelector<{productId: number, count: number}[]>(state => state.productCounter.value)
    const dispatch = useAppDispatch();
    const [count, setCount] = useState<number>(0)
    const [disableAdd, setDisableAdd] = useState(false)

    /*useEffect(() => {
        if(product.available == 0){
            setDisableAdd(true)
        }
    }, [])*/


    useEffect( () => {

        if(appointment == "cart") {

            if (productsCart.length != 0) {
                const orderItem = productsCart[activeOrder].orderItems.find(item => item.product.id == product.id)
                if(orderItem) {
                    setCount(orderItem.count)
                    setShowCount(true);
                } else {
                    setShowCount(false);
                }
            } else {
                setShowCount(false)
            }
        }
        else if (appointment == "reserve"){

            const orderItem = productsReserve.orderItems.find(item => item.product.id == product.id)
            if(orderItem) {
                setCount(orderItem.count)
                setShowCount(true);
            } else {
                setShowCount(false)
            }
        }



    }, [activeOrder, productsCart, productsReserve, product])

    const activeCartReserve = useAppSelector<number>(state => state.activeTab.value)

    const productCountChange = (productId: number, count: number) => {
        if (count != 0){
            if (appointment == 'cart') {
                dispatch(updateProductCountAsync(productId, activeOrder, count));
            } else {
                dispatch(updateCountReserve({productId: productId, activeOrder: 0, count: count}));
            }
        }
        else {
            if (appointment == 'cart') {
                cartServices.deleteCartProduct(productsCart[activeOrder].orderId, productId).then(data => {
                    if (productsCart[activeOrder].orderItems.length == 1) {
                        dispatch(changeActiveOrder(0))
                        dispatch(removeOrder({orderId: productsCart[activeOrder].orderId}));
                    } else {
                        dispatch(removeFromCart({productId: productId, activeOrder: activeOrder}));
                    }
                })
            }
            else {
                dispatch(removeFromReserve({productId: productId, activeOrder: activeOrder}))
            }
        }

    }

    const hideBtn = () => {
        //const chosenProduct = products.find((item) =>  item.id === product.id);

        if (product){
            if(appointment == "cart") {

                    //dispatch(addToCart({product: product, activeOrder: activeOrder}))
                    dispatch(changeActiveTab(0))
                    dispatch(addProductToCartAsync(product))

            }
            else if (appointment == "reserve"){
                dispatch(addToReserve({product: product, activeOrder: activeOrder}))
                dispatch(changeActiveTab(1))
            }

        }
    }

    useEffect(() => {
        if(product.available == 0){
            setDisableAdd(true)
        } else {
            const findProduct = productCount.find(item => item.productId == product.id)
            if (findProduct) {
                if (findProduct.count == 0){
                    setDisableAdd(true)
                }
                else {
                    setDisableAdd(false)
                }
            } else {
                setDisableAdd(false)
            }
        }

    }, [productCount, product.available])


    return (
        <div className={"product-count-table"}>
            {showCount
            ? <ProductCount product={product} count={count} onCountChange={productCountChange} ></ProductCount>
            : <button disabled={disableAdd} onClick={hideBtn} className={"btn-usual"}>{buttonName}</button>}
        </div>
    )
}
export default ProductCountTable;