import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import returnServices from "../../../services/returnServices";

import Header from "../../../blocks/Header/Header";
import Footer from "../../../blocks/Footer";

import TableCreatedReturn from "../../../components/TableCreatedReturn/TableCreatedReturn";
import KeyValuePaire from "../../../components/KeyValuePaire/KeyValuePaire";

import Return from "../../../dto/return/Return";

import "./CreatedReturnPage.sass"
import {useAppSelector} from "../../../hooks";


const CreatedReturnPage:React.FC = () => {
    const {t} = useTranslation();
    const currentLang = useAppSelector((state) => state.currentLang.value)

    let {returnId} = useParams();

    const id = returnId == undefined ? 0 : +returnId

    const [returnData, setReturnData] = useState<Return>()

    useEffect(() => {
        returnServices.getReturn(id).then(data => {
            setReturnData(data)
        })
    }, [currentLang])

    return (
        <div className="return-page-wrapper my-orders-page-wrapper created-return-page page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <div className="back-link">
                        <NavLink to='/return?activetab=1'>{t('createdReturn.returningList')}</NavLink>
                    </div>
                    <h2>№{returnData?.orderId} от {returnData?.dateCreated}</h2>

                    <div className={"white-box return-status status-" + returnData?.status}>

                        <div className="created-point">
                            <span></span>
                            {t('createdReturn.created')}
                        </div>
                        <div className="looking-point">
                            <span></span>
                            {t('createdReturn.underConsideration')}
                        </div>
                        <div className="closed-point">
                            <span></span>
                            {t('createdReturn.closed')}
                        </div>

                    </div>

                    <div className="white-box">
                        {returnData ?

                            returnData.diagnosticsResult || returnData.reclamationAction || returnData.reclamationCause || returnData.reclamationType ?
                                    <div className="decision">
                                        <h3>{t('createdReturn.decisionReturn')}</h3>
                                        <div className="decision-info">
                                            {returnData.diagnosticsResult ? <KeyValuePaire label={'Результат диагностики'} value={returnData.diagnosticsResult} /> : null}
                                            {returnData.reclamationAction ? <KeyValuePaire label={'Действия по устранению рекламации'} value={returnData.reclamationAction} /> : null}
                                            {returnData.reclamationCause ? <KeyValuePaire label={'Причина рекламации'} value={returnData.reclamationCause} /> : null}
                                            {returnData.reclamationType ? <KeyValuePaire label={'Вид рекламации'} value={returnData.reclamationType} /> : null}
                                        </div>
                                    </div>

                                    : null

                            : null}

                        <h3>{t('createdReturn.detailsReturn')}</h3>
                        <div className="title">{t('createdReturn.comment')}</div>
                        <p>{returnData?.comment}</p>
                    </div>

                    <h2>{t('createdReturn.productsForReturn')}</h2>

                    {returnData ? <div className="main-table"><TableCreatedReturn data={returnData.items} /></div> : null }


                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default CreatedReturnPage