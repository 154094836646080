import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialVal: string = 'uk';

export const currentLangSlice = createSlice({
    name: 'currentLang',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setCurrentLanguage: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        },
    }
});
export const { setCurrentLanguage } = currentLangSlice.actions;

export default currentLangSlice.reducer