import React, {useEffect, useState} from "react";
import Header from "../../../blocks/Header/Header";
import {useTranslation} from "react-i18next";
import {SelectPicker} from "rsuite";
import adminServices from "../../../services/adminServices";
import {ManagerDetails, Managers} from "../../../interfaces/Admin/Managers";
import ManagersForm from "./ManagersForm";

import "./ManagersPage.sass"



const ManagerBankDetails: React.FC = () => {
    const {t} = useTranslation();

    const [managers, setManagers] = useState<Managers[]>()
    const [isShowForm, setShowForm] = useState(false)

    useEffect(() => {
        adminServices.getManagers().then(data => {
            setManagers(data)
        })
    }, [])

    const [manager, setManager] = useState<Managers>()

    const selectManager = (item: ManagerDetails) => {

        setShowForm(true)

        const findManager = managers?.find(manager => manager.id == +item)

        setManager(findManager)
    }

    const ClearManager = () => {
        setShowForm(false)
    }


    return (
        <div className="help-page-wrapper page-wrapper manager-page">
            <Header></Header>
            <main>
                <div className="page-container">
                    <h1>Admin: {t('manager.manager')}</h1>

                    <div className="white-box">

                        {managers ?
                        <SelectPicker
                            onSelect={(value, item, event) => selectManager(value)}
                            onClean={ClearManager}
                            data={managers?.map(name => ({label: name.name, value: name.id}))}
                            style={{ width: 300 }} />
                            : null}

                        {isShowForm && manager ? <ManagersForm manager={manager}  /> : null}

                    </div>
                </div>
            </main>
        </div>
    )
}

export default ManagerBankDetails