import React, {useEffect, useState} from "react";

import { Panel, PanelGroup, Placeholder } from 'rsuite';

import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks";

import {CartOrder} from "../../interfaces/CartProduct";

import {removeFromCart, removeOrder} from "../../slices/cartSlice";
import {changeActiveOrder} from "../../slices/activeOrderSlice"
import CartOrderProducts from "../../components/CartOrderProducts/CartOrderProducts";
import {addCartOrderAsync, updateProductCountAsync} from "../../slices/actions/cartAsyncActions";
import cartServices from "../../services/cartServices";


const Cart: React.FC<{totalProductCart: number}> = ({totalProductCart}) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)

    const removeProduct = (productId: number) => {

        cartServices.deleteCartProduct(productsCart[activeOrder].orderId, productId).then(data => {
            if(productsCart[activeOrder].orderItems.length == 1) {
                dispatch(changeActiveOrder(0))
                dispatch(removeOrder({orderId: productsCart[activeOrder].orderId}));
            } else {
                dispatch(removeFromCart({productId: productId, activeOrder: activeOrder}));
            }
        })

    }

    const addOrderAction = () => {
        let ordersCount = productsCart.length;
        dispatch(addCartOrderAsync())
        dispatch(changeActiveOrder(ordersCount))
    }

    const productCountChange = (productId: number, count: number) => {
        if(count == 0 ){
            removeProduct(productId)
        }
        else {
            dispatch(updateProductCountAsync(productId, activeOrder, count));
        }

    }

    const deleteCurrentOrder = (orderId: number) => {
        dispatch(changeActiveOrder(0))
        cartServices.deleteCartOrder(orderId).then(data => {
                dispatch(removeOrder({orderId}))

            }
        )
    }

    return (
        <div className={"tabPanelWrapper"}>
            <PanelGroup accordion defaultActiveKey={0} activeKey={activeOrder}>
                {productsCart.map((order, index) => {
                        return (

                            <Panel
                                expanded={index == activeOrder}
                                collapsible={false}
                                key={index}
                                header={<div
                                    onClick={() => dispatch(changeActiveOrder(index))}>
                                    {t('basket.order')} {index + 1}
                                </div>}
                                eventKey={index}
                                id={"panel" + index}
                            >
                                <CartOrderProducts
                                    key={index}
                                    countButton={true}
                                    order={order}
                                    removeProduct={removeProduct}
                                    onProductCountChange={productCountChange}
                                    missingItems={null}
                                />
                                <button
                                    onClick={() => deleteCurrentOrder(order.orderId)}
                                    className="btn-usual delete-active_order">
                                    <span>{t('basket.deleteOrder')} {index + 1}</span>
                                </button>
                                {/*<ExchangeRates />
                                <TotalOrder totalProductCart={totalProductCart} appointment="cart" />*/}
                            </Panel>
                        )
                    }
                )}
            </PanelGroup>
           {/* <ExchangeRates />
            <TotalOrder totalProductCart={totalProductCart} appointment="cart" />
            <button className={"btn-usual add-order"} onClick={addOrderAction}>{t('basket.add_order')}</button>
            <button className={"btn btn-lg checkout"} >{t('basket.checkout')}</button>*/}

        </div>
    )
}
export default Cart;