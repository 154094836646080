import PaginatedResult from "../dto/PaginatedResult";
import ApiHttpHandler from "./apiHttpHandler";
import AddOrderModel from "../dto/order/AddOrderModel";
import GetOrderModel from "../dto/order/GetOrderModel";
import PreOrderResponseWrapper, {PreOrderResponse} from "../dto/order/PreOrderResponseWrapper";
import AddReserveModel from "../dto/order/reserve/AddReserveModel";
import OrderForReturnModel from "../dto/order/OrderForReturnModel";
import WorkingDayModel from "../dto/order/WorkingDayModel";
import {AddOrdersResultModel} from "../dto/order/AddOrdersResultModel";

const handler = new ApiHttpHandler();

const getOrders = (searchTerm: string, page: number, pageSize: number, getReserves: boolean = false): Promise<PaginatedResult<GetOrderModel>> => {
    if(searchTerm){
        return handler.get(`orders/search?term=${searchTerm}&page=${page}&pageSize=${pageSize}`)
    } else {
        return handler.get(`orders?page=${page}&pageSize=${pageSize}&getReserves=${getReserves}`)
    }
}

const preOrder = (orderIds: number[]): Promise<PreOrderResponseWrapper> => {
    return handler.post(`orders/preorder`, orderIds)
}

const addReserve = (model: AddReserveModel): Promise<PreOrderResponse> => {
    return handler.post(`orders/reserve`, model)
}

const copyOrder = (orderId: number): Promise<GetOrderModel> => {
    return handler.post(`orders/copy/${orderId}`)
}

const getOrder = (id: number): Promise<GetOrderModel> => {
    return handler.get(`orders/${id}`)
}

const cancelOrder = (id: number) => {
    return handler.delete(`orders/${id}`)
}

const addOrders = (model: AddOrderModel[]): Promise<AddOrdersResultModel> => {
    return handler.post(`orders`, model)
}

const updateOrder = (id: number, model: AddOrderModel) => {
    return handler.put(`orders/${id}`, model)
}

const getOrderForReturn = (): Promise<OrderForReturnModel[]> => {
    return handler.get(`orders/for-return`)
}

const getWorkingHours = (): Promise<WorkingDayModel[]> => {
    return handler.get('orders/working-days')
}

const trackOrderStatus = (trackId: string): Promise<GetOrderModel> => {
    return handler.get(`orders/track/${trackId}`)
}

const getOrdersExel = (ids: number[]): Promise<Blob> => {
    return handler.getFile(`orders/excel-export`, 'POST', {ids: ids})
}

const getOrderExel = (id: number): Promise<Blob> => {
    return handler.getFile(`orders/excel-export/${id}`, 'POST')
}

export default {
    preOrder,
    addReserve,
    copyOrder,
    getOrders,
    getOrder,
    cancelOrder,
    addOrders,
    updateOrder,
    getOrderForReturn,
    getWorkingHours,
    trackOrderStatus,
    getOrdersExel,
    getOrderExel
 }