import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import DeliveryNewPost from "./DeliveryNewPost/DeliveryNewPost";
import DeliveryUkrpost from "./DeliveryUkrpost/DeliveryUkrpost";

import NewPost from "../../../assets/img/new_post.png";
import Delivery from "../../../assets/img/delGroup.svg";
import Pickup from "../../../assets/img/pickup.svg"

import DeliveryPickup from "./DeliveryPickup/DeliveryPickup";
import ShippingAddress from "../../../interfaces/ShippingAddress";
import {CarrierType} from "../../../enums/CarrierType";


import PaymentAndPickupData from "../../../interfaces/PaymentAndPickupData";
import DeliveryDelivery from "./DeliveryDelivery/DeliveryDelivery";

import "./OrderProductsDelivery.sass"


const OrderProductsDelivery: React.FC<{
    onChange: Function,
    shippingData?: ShippingAddress | null,
    onPaymentPickupDataChange?: (data: PaymentAndPickupData) => void,
    paymentPickupData?: PaymentAndPickupData,
    showPayOnDelivery: boolean,
    weight: number }> = (
    {onChange,
        shippingData,
        onPaymentPickupDataChange,
        paymentPickupData,
        showPayOnDelivery,
        weight}) => {
    const {t} = useTranslation();

    const [tabIndex, setTabIndex] = useState(0);

    return (
        <div>
                <Tabs className="delivery-tab-services" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                        {shippingData == null || shippingData?.carrierType == CarrierType.NewPost ? <Tab>
                            <div className="logo-delivery new-post_tab">
                                <img src={NewPost} alt="NewPost"/>
                            </div>
                        </Tab> : null}

                        {shippingData == null || shippingData?.carrierType == CarrierType.Delivery ? <Tab>
                            <div className="logo-delivery delivery_tab">
                                <img src={Delivery} alt="Delivery"/>
                            </div>
                        </Tab> : null}
                        {shippingData == null || shippingData?.carrierType == CarrierType.UkrPost ? <Tab>
                            <div className="logo-delivery ukr-post_tab">
                                <span className="img"><img src={Pickup} alt="Pickup"/></span>
                                <span className="value">{t('carrierType.UkrPost')}</span>
                            </div>
                        </Tab> : null}
                            {onPaymentPickupDataChange ? <Tab>
                            <div className="logo-delivery pickup_tab">
                                <span className="img"><img src={Pickup} alt="Pickup"/></span>
                                <span className="value">{t('delivery.pickupDelivery')}</span>
                            </div>
                        </Tab> : null}
                    </TabList>

                    {shippingData == null || shippingData?.carrierType == CarrierType.NewPost ? <TabPanel>
                        <DeliveryNewPost onChange={onChange} shippingAddress={shippingData} onPaymentAndPickupDataChange={onPaymentPickupDataChange} showPayOnDelivery={showPayOnDelivery} weight={weight} />
                    </TabPanel> : null }
                    {shippingData == null || shippingData?.carrierType == CarrierType.Delivery ? <TabPanel>
                        <DeliveryDelivery onChange={onChange} shippingAddress={shippingData} onPaymentAndPickupDataChange={onPaymentPickupDataChange} showPayOnDelivery={showPayOnDelivery} />
                    </TabPanel> : null}
                        {shippingData == null || shippingData?.carrierType == CarrierType.UkrPost ? <TabPanel>
                        <DeliveryUkrpost onChange={onChange} shippingAddress={shippingData} onPaymentAndPickupDataChange={onPaymentPickupDataChange} showPayOnDelivery={showPayOnDelivery} />
                    </TabPanel> : null}
                            {onPaymentPickupDataChange ? <TabPanel>
                        <DeliveryPickup onChange={onPaymentPickupDataChange} paymentAndPickupData={paymentPickupData} />
                    </TabPanel> : null}
                </Tabs>

        </div>
    )
}
export default OrderProductsDelivery