import ApiHttpHandler from "./apiHttpHandler";
//import store from "../store";
//import {setStateFetch} from "../slices/fetchStateSlice";

const handler = new ApiHttpHandler();

const postComparison = (id: number) => {
    return handler.post(`comparison/${id}`)
}

const getComparison = () => {
    return handler.get('comparison')
}

const deleteCompareItem = (id: number) => {
    return handler.delete(`comparison/${id}`)
}

const deleteCompareCategory = (categoryId: number) => {
    return handler.delete(`comparison/delete-category/${categoryId}`)
}

export default {
    postComparison,
    getComparison,
    deleteCompareItem,
    deleteCompareCategory
}