import ApiHttpHandler from "./apiHttpHandler";
import PaginatedResult from "../dto/PaginatedResult";
import ShortReturnModel from "../dto/ShortReturnModel";
import AddReclamationModel from "../dto/reclamations/AddReclamationModel";
import GetReclamationModel from "../dto/reclamations/GetReclamationModel";
import GetOrderModel from "../dto/order/GetOrderModel";

const handler = new ApiHttpHandler();


const getReclamations = (term: string, page: number, pageSize: number ): Promise<PaginatedResult<ShortReturnModel>> => {
    return handler.get(`reclamations?term=${term}&page=${page}&pageSize=${pageSize}`)
}

const addReclamation = (reclamation: AddReclamationModel) => {
    return handler.post(`reclamations`, reclamation)
}

const getReclamation = (id: number): Promise<GetReclamationModel> => {
    return handler.get(`reclamations/${id}`)
}

const getOrderForReclamation = (term?: string): Promise<GetOrderModel[]> => {
    if (term){
        return handler.get(`orders/for-reclamation?vendorCode=${term}`)
    }
    else {
        return handler.get(`orders/for-reclamation`)
    }

}

export default {
    getReclamations,
    addReclamation,
    getReclamation,
    getOrderForReclamation
}
