import React, {useEffect, useState} from "react";
import AddRecipient from "../../Form/AddRecipient/AddRecipient";
import {useTranslation} from "react-i18next";
import {Input, InputGroup} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import Pagination from "../../../interfaces/Pagination";
import contactPersonServices from "../../../services/contactPersonServices";
import {ContactPerson} from "../../../interfaces/ContactPerson";
import "./OrderRecipientList.sass"
import {ShippingReceiver} from "../../../interfaces/ShippingReceiver";

const OrderRecipientList: React.FC<{chosenRecipient: Function, onNewRecipient: (receiver: ShippingReceiver) => void}> = ({chosenRecipient, onNewRecipient}) => {

    const {t} = useTranslation();
    const chooseRecipientPlaceholder = t('order.chooseRecipient')

    const [toggleChooseRecipient, setToggleChooseRecipient] = useState(false)
    const [openChosenRecipient, setOpenChosenRecipient] = useState(false)
    const [selectedContact, setSelectedContact] = useState<ContactPerson | null>(null)
    const [toggleAddRecipient, setToggleAddRecipient] = useState(false)

    const [searchTerm, setSearchTerm] = useState('')


    const chooseRecipient = (value: string, event: any) => {
        setToggleChooseRecipient(toggleChooseRecipient => !toggleChooseRecipient)
        if (value.length >= 2){
            setSearchTerm(value)
        }
    }

    const addNewRecipient = (receiver: ShippingReceiver) => {
        onNewRecipient(receiver)
        setSelectedContact({...receiver, id:0})
        setOpenChosenRecipient(true)
        setToggleAddRecipient(false)
        setToggleChooseRecipient(false)
    }

    const [contactPersonList, setContactPersonList] = useState<ContactPerson[]>([])
    let addressPagination: Pagination = {
        "totalPageCount": 1,
        "totalItemCount": 50,
        "currentPageNumber": 1,
        "pageSize": 10,
    }

    useEffect(() => {
        contactPersonServices.getContactPersons(searchTerm, addressPagination.currentPageNumber, addressPagination.pageSize).then(data => {
            setContactPersonList(data.items)
        })

    }, [searchTerm])


    const addRecipient = () => {
        setToggleAddRecipient(toggleAddRecipient => !toggleAddRecipient)
    }

    const openRecipientList = () => {
        setToggleChooseRecipient(true)
        setOpenChosenRecipient(false)
    }

    const chooseRecipientPerson = (person: ContactPerson) => {
        chosenRecipient(person.id)
        setSelectedContact(person)

        setOpenChosenRecipient(true)
        setToggleChooseRecipient(false)

    }

    return(

        <div className="choose-recipient_wrapper">
            <InputGroup inside>
                <Input placeholder={chooseRecipientPlaceholder} onChange={chooseRecipient} onFocus={openRecipientList} />
                <InputGroup.Button>
                    <SearchIcon  color="#0090D4" />
                </InputGroup.Button>
            </InputGroup>

            {toggleChooseRecipient ? <div className={toggleAddRecipient ? "choose-recipient_list add-recipient-box" : "choose-recipient_list"}>

                {!toggleAddRecipient ? <div>
                    <button className="add-recipient" onClick={addRecipient}>{t('order.addRecipient')}</button>
                    {contactPersonList.map((contactPerson, index) => {
                        return (
                            <div onClick={() => chooseRecipientPerson(contactPerson)} className="choose-recipient_item" key={index}>
                                <div className="choose-recipient_name">{contactPerson.lastName} {contactPerson.firstName}</div>
                                <div className="choose-recipient_phone">{contactPerson.phone}</div>
                            </div>
                        )
                    }) }
                </div> : null}


                {toggleAddRecipient ? <AddRecipient onNewRecipient={addNewRecipient}/> : null}

            </div> : null}

            {openChosenRecipient ? <div className="choose-recipient_item">
                <div className="choose-recipient_name">{selectedContact?.lastName} {selectedContact?.firstName} {selectedContact?.middleName}</div>
                <div className="choose-recipient_phone">{selectedContact?.phone}</div>
            </div> : null}

        </div>
    )
}

export default OrderRecipientList