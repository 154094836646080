import ShippingDetailsResponseDataModel from "../dto/novaPoshta/ShippingDetailsResponseDataModel";
import NovaPoshtaHttpHandler from "./npHttpHandler";
import ApiHttpHandler from "./apiHttpHandler";

const handler = new NovaPoshtaHttpHandler();
const apiHandler = new ApiHttpHandler();
const API_KEY = '3c33c1567a50011694fc5d19a33b2d64';
const cargoBranchType = '9a68df70-0267-42a8-bb5c-37f427e36ee4';

const getCities = (areaId: string): Promise<ShippingDetailsResponseDataModel[]> => {
    if (areaId == null || areaId == '') return new Promise(() => []);

    return apiHandler.get(`novaposhta/areas/${areaId}/cities`).then((data: any[]) => {
        return data.map(item => {
            return {id: item.id, name: item.name}
        })
    });
}

const getAreas = (): Promise<ShippingDetailsResponseDataModel[]> => {
    return apiHandler.get('novaposhta/areas').then((data: any[]) => {
       return data.map(item => {
           return {id: item.id, name: item.name}
       })
    });
}

const getBranches = (cityName: string, searchTerm: string, lang: string, weight: number = 0): Promise<ShippingDetailsResponseDataModel[]> => {
    if (cityName == null || cityName == '') return new Promise(() => []);

    const req = {
        "apiKey": API_KEY,
        "modelName": "Address",
        "calledMethod": "getWarehouses",
        "methodProperties": {
            "CityName" : cityName,
            "Language" : lang.toUpperCase(),
            "FindByString" : searchTerm,
            "TypeOfWarehouseRef": weight > 30? cargoBranchType: ''
        }
    }

    return handler.post('/', req).then((data: any[]) => {
        return data.map(item => {
            return {id: item.Ref as string, name: lang == 'ru'? item.DescriptionRu: item.Description}
        })
    });
}

const getStreets = (cityId: string, searchTerm: string, limit: number, page: number, lang: string): Promise<ShippingDetailsResponseDataModel[]> => {
    if (cityId == null || cityId == '') return new Promise(() => []);

    const req = {
        "apiKey": API_KEY,
        "modelName": "Address",
        "calledMethod": "getStreet",
        "methodProperties": {
            "CityRef" : cityId,
            "Language" : lang.toUpperCase(),
            "FindByString" : searchTerm,
            "Page" : page,
            "Limit" : limit
        }
    }

    return handler.post('/', req).then((data: any[]) => {
        return data.map(item => {
            return {id: item.Ref as string, name: `${item.StreetsType} ${item.Description}`}
        })
    });
}

export default {
    getCities,
    getAreas,
    getBranches,
    getStreets
}