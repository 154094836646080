import ApiHttpHandler from "./apiHttpHandler";
import {Complain} from "../interfaces/Complain";

const handler = new ApiHttpHandler();

const addComplain = (data: Complain) => {
    return handler.post(`complaints`, data)
}

export default {
    addComplain
}