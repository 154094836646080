import ApiHttpHandler from "./apiHttpHandler";
import {SavePage} from "../interfaces/Admin/EditPages";

const handler = new ApiHttpHandler();

const getPage = (slug: string): Promise<SavePage> => {
    return handler.get(`pages/${slug}`)
}

export default {
    getPage
}