import React, {useEffect, useState} from "react";
import './ProductCount.sass'
import Product from "../../interfaces/Product/Product";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {CartOrder} from "../../interfaces/CartProduct";
import {setProductCount} from "../../slices/productCounterSlice";
import cartServices from "../../services/cartServices";
import {changeActiveOrder} from "../../slices/activeOrderSlice";
import {removeFromCart, removeOrder} from "../../slices/cartSlice";
import {removeFromReserve} from "../../slices/reserveSlice";

const ProductCount: React.FC<{product: Product, count: number, onCountChange?: (productId: number, count: number) => void}> =
    ({product, count, onCountChange}) => {

        const dispatch = useAppDispatch();

        const [counter, setCounter] = useState(count)
        const [hideDecrease, setHideDecrease] = useState(counter <= 0 ? true : false)
        const [hideIncrease, setHideIncrease] = useState(counter >= product.available ? true : false)

        const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
        const productsReserve = useAppSelector<CartOrder>((state) => state.reserve.value);

        useEffect(() => {
            setCounter(count)
        }, [count])


        const [leftProducts, setLeftProducts] = useState(product.available)
        useEffect(() => {

            let productsInReserveCount = 0
            let productsInCart = 0

            const productsInReserve = productsReserve.orderItems.find(item => item.product.id == product.id)

            if(productsInReserve) {
                productsInReserveCount = +productsInReserve.count
            }

            productsCart.forEach((item, index) => {
                let productIn = item.orderItems.find(itemProduct => itemProduct.product.id == product.id)
                if (productIn){
                    productsInCart += +productIn.count
                }
            } )

            let leftProd = product.available - productsInReserveCount - productsInCart

            setLeftProducts(leftProd)

            dispatch(setProductCount({productId: product.id, count: leftProd}))


        }, [productsReserve, productsCart])



        const updateCounterDecr = (value: number) => {
            if(value >= 0) {
                if (onCountChange) {
                    onCountChange(product.id, value)
                }
            }
        }

        const updateCounterInc = (value: number) => {
            if(value > 0 && leftProducts > 0) {
                if (onCountChange) {
                    onCountChange(product.id, value)
                }
            }
        }

        const updateCounter = (value: number) => {
            if(value > 0 && (value - counter) <= leftProducts) {
                if (onCountChange) {
                    onCountChange(product.id, value)
                }
            }
        }

        useEffect(() => {
            setHideDecrease(counter <= 0 ? true : false)
            setHideIncrease(leftProducts <= 0 ? true : false)
        }, [counter, leftProducts] )

        return (
            <div className={"wrapperCounter"}>
                <button disabled={hideDecrease} onClick={() => updateCounterDecr(counter - 1)}></button>
                <input type="number" onChange={(e) => updateCounter(+e.target.value)} value={counter} />
                <button disabled={hideIncrease} onClick={() => updateCounterInc(counter + 1)}></button>
            </div>
        )
    }
export default ProductCount;