import React, {useEffect, useState} from "react";


import {Table} from "rsuite";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ShortReturnModel from "../../dto/ShortReturnModel";

import "./TableReturn.sass"
import StatusReturn from "../StatusReturn/StatusReturn";

const { Column, HeaderCell, Cell } = Table;


const CellDateTime:React.FC<{ rowData?: {[key: string]: number}, dataKey: string}> = (
    {rowData, dataKey,  ...props}) => (
    <Cell {...props}>
        <div className="date">{rowData![dataKey]}
            <span>{rowData!['deliveryTime']}</span>
        </div>
    </Cell>
)

const CellStatus:React.FC<{ rowData?: {[key: string]: number}, dataKey: string}> = (
    {rowData, dataKey,  ...props}) => (
    <Cell {...props}>
        <StatusReturn status={rowData![dataKey]} />
    </Cell>
)


const TableReturn:React.FC<{data: ShortReturnModel[], type: string}> = ({data, type}) => {
    const {t} = useTranslation();

    const [deviceHeight, setDeviceHeight] =  useState(window.innerHeight)

    const navigate = useNavigate()
    const [navLink, setNavLink] = useState<string>('/return/')

    useEffect(() => {
        let height = deviceHeight - 290
        setDeviceHeight(height)

        if (type === 'reclamations') {
            setNavLink('/reclamation/')
        } else {
            setNavLink('/return/')
        }
    }, [])


    return (
        <div className="table-return-wrapper">
            <div className="table-responsive">
                <Table
                    height={deviceHeight}
                    data={data}
                    id="table"
                    wordWrap="break-word"
                    onRowClick={data => {
                        navigate(navLink + data.id)
                    }}
                >

                    <Column width={110} verticalAlign="middle">
                        <HeaderCell>{t('tableHeader.orderId')}</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell>{type === 'reclamations' ? t('tableHeader.orderDateReclamation') : t('tableHeader.orderDateReturn')}</HeaderCell>
                        <CellDateTime dataKey="date" />
                    </Column>

                    <Column flexGrow={1} verticalAlign="middle">
                        <HeaderCell>{t('tableHeader.status')}</HeaderCell>
                        <CellStatus dataKey="status" />
                    </Column>

                    <Column flexGrow={4} verticalAlign="middle">
                        <HeaderCell>{t('tableHeader.comment')}</HeaderCell>
                        <Cell dataKey="comment" />
                    </Column>

                </Table>
            </div>
        </div>
    )
}
export default TableReturn