import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import Pagination from "../interfaces/Pagination";

const initialVal: Pagination = {
    "totalPageCount": 1,
    "totalItemCount": 25,
    "currentPageNumber": 1,
    "pageSize": 25
};

export const PaginationSlice = createSlice({
    name: 'pagination',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setPagination: (state, action: PayloadAction<Pagination>) => {
            state.value = action.payload
        }
    }
})
export const { setPagination } = PaginationSlice.actions


export default PaginationSlice.reducer