import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Category} from "../interfaces/Category";

const initialVal: Category[] = [];

export const categorySlice = createSlice({
    name: 'category',
    initialState: {
        value: initialVal,
    },
    reducers: {
        setCategories: (state, action: PayloadAction<Category[]>) => {
            state.value = action.payload
        }
    }
});
export const {setCategories} = categorySlice.actions



export default categorySlice.reducer