import React, {useEffect, useState} from "react";
import {addToCompareAsync} from "../../slices/actions/compareAsyncActions";
import {useAppDispatch, useAppSelector} from "../../hooks";
import Product from "../../interfaces/Product/Product";
import ComparisonCategory from "../../interfaces/ComparisonCategory";
import comparisonService from "../../services/comparisonService";
import {setCompareList} from "../../slices/compareSlice";
import {Tooltip, Whisper} from "rsuite";
import {useTranslation} from "react-i18next";

const AddToComare: React.FC<{chosenProduct: Product }> = ({chosenProduct}) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const compareProducts = useAppSelector<ComparisonCategory[]>((state) => state.compare.value)

    const [isAddedToCompare, setIsAddedToCompare] = useState(false)

    useEffect(() => {
        if (compareProducts.length != 0) {
            const category = compareProducts.find((item) =>  item.categoryId === chosenProduct.categoryId);
            if(category){
                const foundProduct = category.items.find((item) => item.id === chosenProduct.id)
                foundProduct ? setIsAddedToCompare(true) : setIsAddedToCompare(false)
            } else {
                setIsAddedToCompare(false)
            }

        }
    }, [])

    const toCompare = () => {

        if(isAddedToCompare){
            comparisonService.deleteCompareItem(chosenProduct.id).then((data) => {
                    dispatch(setCompareList(data))
                }
            )
        }
        else {
            dispatch(addToCompareAsync(chosenProduct))
        }

        setIsAddedToCompare(isAddedToCompare => !isAddedToCompare)

    }




    return(
        <Whisper
            trigger="hover"
            placement={'top'}
            speaker={
                <Tooltip>{isAddedToCompare ? t('compare.removeFromCompare') : t('compare.addToCompare')}</Tooltip>
            }
        >
            <button onClick={toCompare} className={isAddedToCompare ? "compare added" : "compare"}></button>
        </Whisper>
    )
}
export default AddToComare