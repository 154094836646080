import React, {MouseEventHandler} from "react";
import {Button, Input, InputGroup} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import {PrependParameters} from "rsuite/cjs/@types/utils";

import "./SearchBlock.sass"

const SearchBlock:React.FC<{
    search:  PrependParameters<React.ChangeEventHandler<HTMLInputElement>, [value: string]> | undefined,
    searchPlaceHolder: string,
    buttonClick: MouseEventHandler | false,
    buttonText: string,
    buttonState: boolean}> = ({search,searchPlaceHolder, buttonClick, buttonText, buttonState}) => {


    return (
        <div className="search-address-wrapper">
            <InputGroup inside>
                <InputGroup.Button>
                    <SearchIcon  color="#000" />
                </InputGroup.Button>
                <Input placeholder={searchPlaceHolder} onChange={search}/>
                {buttonClick ? <Button onClick={buttonClick} disabled={buttonState} className="btn btn-lg">{buttonText}</Button> : null}
            </InputGroup>
        </div>
    )
}
export default SearchBlock