import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialVal: number = 0;

export const activeSidebarTabSlice = createSlice({
    name: 'activeTab',
    initialState: {
        value: initialVal,
    },
    reducers: {
        changeActiveTab: (state, action: PayloadAction<number>) => {
            state.value = action.payload
        },
    }
});
export const { changeActiveTab } = activeSidebarTabSlice.actions;

export default activeSidebarTabSlice.reducer