import React, {useEffect, useState} from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
    RangeSlider,
    Input,
    Grid,
    Row,
    Col,
    PanelGroup,
    Panel,
    Sidenav,
    Form,
    DateRangePicker
} from 'rsuite';

import productService from "../../services/productService";

import {useAppDispatch, useAppSelector} from "../../hooks";

import FilterInterface from "../../interfaces/Filters/FilterInterface";
import {CustomFilters, SelectedFilters, SelectedProperty} from "../../interfaces/Filters/SelectedFilters"
import Pagination from "../../interfaces/Pagination";
import {ProductEntity} from "../../interfaces/Product/ProductEntity";
import {ProductOrderBy} from "../../enums/ProductOrder";

import {setProducts} from "../../slices/productSlice";
import {setPagination} from "../../slices/paginationSlice";
import {setFilters} from "../../slices/filterSlice";
import {setStateFilters} from "../../slices/filterStateSlice";
import {setChosenFiltersObj} from "../../slices/chosenFiltersSlice";

import "rsuite/dist/rsuite.css";
import "./Filters.sass"
import {DateRange} from "rsuite/DateRangePicker";
import moment from "moment/moment";
import transactionService from "../../services/transactionService";
import {setCategoryName} from "../../slices/categoryNameSlice";

const { afterToday } = DateRangePicker;

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

function Filters () {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    let { state } = useLocation();
    const {categoryId} = useParams();

    const filters = useAppSelector<FilterInterface>((state) => state.filters.value);
    const pagination = useAppSelector<Pagination>(state => state.pagination.value);
    const productOrderBy = useAppSelector<ProductOrderBy>(state => state.productOrder.value);
    const filterState = useAppSelector<boolean>(state => state.filterState.value);
    const currentLanguage = useAppSelector<string>(state => state.currentLang.value)
    const propertyFiltersStore = useAppSelector<SelectedProperty[]>(state => state.chosenFilters.value)

    const [searchParams, setSearchParams] = useSearchParams();
    const [initState, setInitState] = useState(false)

    useEffect(() => {
        const page = searchParams.get("page")
        if(page) {
            dispatch(setPagination({
                "totalPageCount": pagination.totalPageCount,
                "totalItemCount": pagination.totalItemCount,
                "currentPageNumber": page ? +page : 1,
                "pageSize": pagination.pageSize,
            }))
        }

        setInitState(true)


    }, [categoryId])

    useEffect(() => {
        setIsFiltersLoaded(false)
    }, [categoryId])

    const [chosenFilters, setChosenFilters] = useState<SelectedFilters>({propertyFilters: [],
            customFilters: {
                trended: null,
                similarClients: null,
                usuallyBoughtWith: null,
                similarClientsBoughtWith: null,
                newItemsForPeriod: null,
                iBoughtForPeriod: null,
                iBoughtInOrder: null,
                recentlyViewed: null
            }})

    const [isFiltersLoaded, setIsFiltersLoaded] = useState(false)

    const [isChosenFiltersLoaded, setIsChosenFiltersLoaded] = useState(false)

    useEffect(() => {

        if(!isFiltersLoaded) return;
        /*setChosenFilters({propertyFilters: [],
            customFilters: {
                trended: null,
                similarClients: null,
                usuallyBoughtWith: null,
                similarClientsBoughtWith: null,
                newItemsForPeriod: null,
                iBoughtForPeriod: null,
                iBoughtInOrder: null
            }})*/

        let filtersParams: SelectedProperty[] = []
        const customFilters: CustomFilters = {
            trended: null,
            similarClients: null,
            usuallyBoughtWith: null,
            similarClientsBoughtWith: null,
            newItemsForPeriod: null,
            iBoughtForPeriod: null,
            iBoughtInOrder: null,
            recentlyViewed: null
        }

        let keys = Array.from(searchParams.keys())

        for(const key of keys.filter((item, index) => keys.indexOf(item) === index)){

            let values = searchParams.getAll(key)

            let kerForSearch = key.slice(1)

            let propertyName = filters.properties.find((item) => item.id == +kerForSearch)

            if(key == 'recentlyViewed') {
                customFilters.recentlyViewed = true
            }

            if(propertyName) {
                let propertyItem = {id: +kerForSearch, values: values, name: propertyName.name}

                filtersParams.push(propertyItem)
            }

        }

        if(filtersParams.length != 0) {
            setChosenFilters({...chosenFilters, propertyFilters: filtersParams, customFilters: customFilters})
            dispatch(setChosenFiltersObj(filtersParams))
        } else {
            setChosenFilters({...chosenFilters, customFilters: customFilters})
        }

        setIsChosenFiltersLoaded(true)


    }, [isFiltersLoaded, state])

    const [isFiltersSelected, setIsFiltersSelected] = useState(false)

        useEffect(() => {
            const isCustomChosen = chosenFilters.customFilters.iBoughtForPeriod
                || chosenFilters.customFilters.newItemsForPeriod
                || chosenFilters.customFilters.iBoughtInOrder
                || chosenFilters.customFilters.trended
                || chosenFilters.customFilters.similarClients
                || chosenFilters.customFilters.similarClientsBoughtWith
                || chosenFilters.customFilters.usuallyBoughtWith
                || chosenFilters.customFilters.recentlyViewed;
            (propertyFiltersStore.length != 0 || isCustomChosen) ? setIsFiltersSelected(true) : setIsFiltersSelected(false)


            if (isChosenFiltersLoaded) {
                let stringParam = ''
                propertyFiltersStore.map((item) => {
                    item.values.map(property => {
                        stringParam += '&f' + item.id + '=' + property
                    })

                })
                setSearchParams(stringParam)
            }


        }, [propertyFiltersStore])


    let init: string[] = [];
    const [filtersObj, setFiltersObj] = useState(filters.properties)
    const [customFiltersObj, setCustomFiltersObj] = useState(filters.customFilters)

    useEffect(() => {
        setFiltersObj(filters.properties)
        setCustomFiltersObj(filters.customFilters)
    }, [filters.properties, filters.customFilters])


    const addFilter = (filterId: number, filterName: string, filterValue: string) => {
        let updatedPropertyFilters = [...propertyFiltersStore]
        let foundPropertyIndex = propertyFiltersStore.map(item => item.name).indexOf(filterName)

        if (foundPropertyIndex > -1) {

            let foundProperty = updatedPropertyFilters.splice(foundPropertyIndex, 1).pop();
            if(foundProperty) {
                foundProperty = {...foundProperty}
                foundProperty.values = [...foundProperty.values]
            }

            const foundValue = foundProperty!.values.indexOf(filterValue)

            if(foundValue > -1){
                foundProperty!.values.splice(foundValue, 1)
                if(foundProperty!.values.length != 0) {
                    updatedPropertyFilters.push(foundProperty!)
                }
            } else {
                foundProperty!.values = [...foundProperty!.values, filterValue]
                updatedPropertyFilters.push(foundProperty!)
            }


            /*setChosenFilters({...chosenFilters, propertyFilters: updatedPropertyFilters})*/

            dispatch(setChosenFiltersObj(updatedPropertyFilters))


        } else {
            updatedPropertyFilters.push({id: filterId, name: filterName, values: [filterValue]})
            /*setChosenFilters({...chosenFilters, propertyFilters: updatedPropertyFilters})*/
            dispatch(setChosenFiltersObj(updatedPropertyFilters))
        }

        dispatch(setPagination({
            "totalPageCount": pagination.totalPageCount,
            "totalItemCount": pagination.totalItemCount,
            "currentPageNumber": 1,
            "pageSize": pagination.pageSize,
        }))

    }


    const addCustomFilter = (filterName: string, filterValue: string | null, filterFrom: string | null, filterTo: string | null) => {
        const updatedFilters: CustomFilters = {
            trended: null,
            similarClients: null,
            usuallyBoughtWith: null,
            similarClientsBoughtWith: null,
            newItemsForPeriod: null,
            iBoughtForPeriod: null,
            iBoughtInOrder: null,
            recentlyViewed: null
        }

        if (filterName == 'similarClients' || filterName == 'trended') {
            if(chosenFilters.customFilters[filterName] === true){
                updatedFilters[filterName] = null;
            } else {
                updatedFilters[filterName] = true;
            }
        }

        if (filterName == 'recentlyViewed') {
            if(chosenFilters.customFilters[filterName] === true){
                updatedFilters[filterName] = null;
            } else {
                updatedFilters[filterName] = true;
            }
        }

        if (filterName == 'usuallyBoughtWith' || filterName == 'similarClientsBoughtWith') {
            updatedFilters[filterName] = filterValue;
        }

        if (filterName == 'iBoughtInOrder') {
            updatedFilters[filterName] = +filterValue!;
        }

        if (filterName == 'newItemsForPeriod' || filterName == 'iBoughtForPeriod') {
            updatedFilters[filterName] = {
                from: filterFrom!,
                to: filterTo!
            };
        }
        setIsFiltersSelected(true)
        setChosenFilters({...chosenFilters, customFilters: updatedFilters})
        //dispatch(setChosenFiltersObj(chosenFilters.customFilters))

        dispatch(setPagination({
            "totalPageCount": pagination.totalPageCount,
            "totalItemCount": pagination.totalItemCount,
            "currentPageNumber": 1,
            "pageSize": pagination.pageSize,
        }))

    }



    const isChecked = (filterName: string, filterValue: string) => {


        const foundProperty = propertyFiltersStore.find((item) => item.name == filterName)

        if (foundProperty) {

            const foundValue = foundProperty.values.indexOf(filterValue)

            return foundValue > -1;


        } else {
            return false
        }
    }

    const isCustomChecked = (filterName: keyof CustomFilters) => {
        return chosenFilters.customFilters[filterName] != null;
    }

    const removeFilter = (index: number, idx: number) => {
        let updatedFilters = [...propertyFiltersStore]
        let prop = updatedFilters.splice(index, 1).pop()
        if (prop) {
            prop = {...prop}
            prop.values = [...prop.values]
            prop.values.splice(idx, 1)
            if (prop.values.length != 0){
                updatedFilters.push(prop)
            }
        }


        /*setChosenFilters({...chosenFilters, propertyFilters: updatedFilters})*/
        dispatch(setChosenFiltersObj(updatedFilters))

    }

    const removeCustomFilter =  () => {
        const updatedFilters: CustomFilters = {
            trended: null,
            similarClients: null,
            usuallyBoughtWith: null,
            similarClientsBoughtWith: null,
            newItemsForPeriod: null,
            iBoughtForPeriod: null,
            iBoughtInOrder: null,
            recentlyViewed: null
        }
        setChosenFilters({...chosenFilters, customFilters: updatedFilters})

    }
    const clearAll = () => {
        const updatedFilters: CustomFilters = {
            trended: null,
            similarClients: null,
            usuallyBoughtWith: null,
            similarClientsBoughtWith: null,
            newItemsForPeriod: null,
            iBoughtForPeriod: null,
            iBoughtInOrder: null,
            recentlyViewed: null
        }
        dispatch(setChosenFiltersObj([]))
        setChosenFilters({propertyFilters: [], customFilters: updatedFilters})
    }


    const [maxPrice, setMaxPrice] = useState(filters.priceTo);
    const [minPrice, setMinPrice] = useState(filters.priceFrom)

    const initialRangeSlider: [number, number] = [filters.priceFrom, filters.priceTo]

    const [valueRangeSlider, setValueRangeSlider] = useState(initialRangeSlider)


    useEffect(() => {
        setValueRangeSlider([filters.priceFrom, filters.priceTo])
        setMaxPrice(filters.priceTo)
        setMinPrice(filters.priceFrom)
    }, [filters.priceFrom, filters.priceTo])
    const priceChange = (value: [number, number]) => {
        setValueRangeSlider([value[0], value[1]])
    }

    const CustomInput = ({ ...props }) => <Input {...props}  />;

    const updateProducts = () => {
        productService.getProducts({
            id: null,
            fromPrice: valueRangeSlider[0] == 0 ? minPrice : valueRangeSlider[0],
            toPrice: valueRangeSlider[1] == 0 ? maxPrice : valueRangeSlider[1],
            categoryId: categoryId == undefined ? null : +categoryId,
            page: pagination.currentPageNumber,
            itemsPerPage: pagination.pageSize,
            //propertyFilters: chosenFilters.propertyFilters,
            propertyFilters: propertyFiltersStore,
            sortOrder: productOrderBy,
            customFilters: chosenFilters.customFilters
        }).then(data => {
            const products = data.products.items.map((item) => {
                let prod = ProductEntity.CreateProductEntityShort(item)
                return prod
            })
            dispatch(setProducts(products));

            dispatch(setCategoryName(data.categoryName))


            dispatch(setPagination({
                "totalPageCount": data.products.totalPageCount,
                "totalItemCount": data.products.totalItemCount,
                "currentPageNumber": pagination.currentPageNumber,
                "pageSize": pagination.pageSize,
            }))


            dispatch(setFilters({
                priceFrom: data.filters.priceFrom,
                priceTo: data.filters.priceTo,
                properties: data.filters.properties,
                customFilters: data.filters.customFilters
            }))
            setIsFiltersLoaded(true)
        })
    }


    useEffect(() => {

        if(!initState){
            return
        }

        updateProducts()


    }, [categoryId, pagination.pageSize, pagination.currentPageNumber, chosenFilters, productOrderBy, initState, currentLanguage, propertyFiltersStore]);

    /*categoryId, pagination.pageSize, pagination.currentPageNumber, chosenFilters, productOrderBy, valueRangeSlider, currentLanguage*/

    const [expanded, setExpanded] = useState(true);


    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)

    const [isMobileView, setIsMobileView] = useState(true)

    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    const closeFiltersMobile = () => {
        dispatch(setStateFilters(false))
    }

    const setValueCustomFilter = (value: string) => {

    }

    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const dateRangeChange = (value: DateRange | null) => {
        if(value) {
            const from = moment(value[0]).toISOString();
            const to = moment(value[1]).toISOString();
            setFromDate(from);
            setToDate(to);
        }
    }

    const setMinRange = (value: number) => {
        setValueRangeSlider(v => [value, v[1]])
    }

    const setMaxRange = (value: number) => {
        setValueRangeSlider(prev =>[prev[0], value])
    }

    return (
            <Sidenav expanded={expanded} className={!filterState ? "filters-wrapper" : "filters-wrapper mobile"}>

                <Sidenav.Body>
                    <div>
                        {!isMobileView ? <div className={"title-wrapper"}>
                            {expanded ? <div className={"title"}>{t('filters.title')}</div>  : ''}
                            {!isMobileView ? <Sidenav.Toggle expanded={expanded} onToggle={expanded => setExpanded(expanded)} /> : null }
                        </div> : ''}

                        {expanded ? <div className={"block-filters-overflow"}>

                            {isFiltersSelected ? <div className={"block-filters"}>
                                <div className={"chosen-filters-wrapper"}>
                                    {
                                        propertyFiltersStore.map((propertyName, index) =>
                                            propertyName.values.map((property, idx) =>
                                                <button className={"remove-filter"} onClick={() => removeFilter(index, idx)} key={index + '_' + idx}>{property}</button>
                                            ))
                                    }

                                    {
                                        Object.keys(chosenFilters.customFilters).map((propertyName, index) => {
                                                const isSelected = chosenFilters.customFilters[propertyName as keyof CustomFilters] != null
                                                return isSelected ?(<button className={"remove-filter"} onClick={() => removeCustomFilter()}
                                                        key={index}>{t(`customFilters.${propertyName}`)}</button>): null
                                            }
                                        )
                                    }
                                </div>
                                <button className={"btn-usual clear-all"} onClick={clearAll}>{t('filters.clear')}</button>
                            </div> : ''}


                            <PanelGroup accordion>
                                <div className={"block-filters"}>
                                    <Panel header={<div className={"title"} >{t('filters.price')}</div>} defaultExpanded>
                                        <Grid fluid>
                                            <Row>
                                                <Col xs={12}>
                                                    <Input
                                                        size="lg"
                                                        min={minPrice}
                                                        value={valueRangeSlider[0]}
                                                        onChange={(value) => setMinRange(+value)}
                                                    />
                                                </Col>
                                                <Col xs={12}>
                                                    <Input
                                                        size="lg"
                                                        value={valueRangeSlider[1]}
                                                        onChange={(value) => setMaxRange(+value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Grid>
                                        <RangeSlider
                                            progress
                                            tooltip={false}
                                            max={maxPrice}
                                            value={valueRangeSlider}
                                            onChange={value => {
                                                setValueRangeSlider(value);
                                            }}
                                            className="range-slider"
                                        />
                                        <button onClick={updateProducts} className={"btn btn-lg"}>{t('filters.use')}</button>
                                    </Panel>
                                </div>
                                {
                                    filtersObj.map((filterType, index) =>
                                        <div className={"block-filters"} key={index}>
                                            <Panel header={<div className={"title"}>{filterType.name}</div>}>
                                                {filterType.values.map((item, idx) =>
                                                    <div key={idx}>
                                                        <button
                                                            className={isChecked(filterType.name, item.value) ? "checkbox active" : "checkbox"}
                                                            onClick={() => addFilter(filterType.id, filterType.name, item.value)}
                                                            disabled={item.count == 0 && !isChecked(filterType.name, item.value) ? true : false}
                                                        >
                                                        <span className={"value"}>
                                                            {item.value}
                                                            <span className={"count"}>{item.count}</span>
                                                        </span>

                                                        </button>
                                                    </div>
                                                )}
                                            </Panel>
                                        </div>
                                    )
                                }

                                {
                                    customFiltersObj.map((filterType, index) =>
                                        <div className={"block-filters"} key={index}>

                                            {filterType.name == 'usuallyBoughtWith' ||
                                            filterType.name == 'similarClientsBoughtWith' ||
                                            filterType.name == 'iBoughtInOrder' ?
                                                <Panel header={<div className={"title"}>{t(`customFilters.${filterType.name}`)}</div>} >

                                                <Form>
                                                    <div className="input-wrapper">
                                                        <Form.Group controlId={`${filterType.name}`} key={`${filterType.name}`}>
                                                            <Form.ControlLabel>{ filterType.name == 'iBoughtInOrder' ? t('customFilters.order') : t('customFilters.vendorCode')}</Form.ControlLabel>
                                                            <Form.Control
                                                                name={filterType.name}
                                                                onChange={(value) => addCustomFilter(filterType.name, value, null, null)}
                                                                key={`${filterType.name}`}
                                                                accepter={Input}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Form>
                                            </Panel> : null}

                                            {filterType.name == 'newItemsForPeriod' || filterType.name == 'iBoughtForPeriod' ? <div>
                                                <Panel header={<div className={"title"}>{t(`customFilters.${filterType.name}`)}</div>} >
                                                    <Form>
                                                    <div className="settlements-daterange">
                                                        <div className="date_range-wrapper">
                                                            <label className="date-range-label">{t('customFilters.dateRange')}</label>
                                                            <DateRangePicker
                                                                placement="topStart"
                                                                showOneCalendar
                                                                character={' - '}
                                                                format={('dd.MM.yyyy')}
                                                                placeholder={t('transactions.format')}
                                                                ranges={[]}
                                                                onChange={(value: DateRange | null) => addCustomFilter(filterType.name, null, value ? moment(value[0]).format('YYYY-MM-DD') : null, value ? moment(value[1]).format('YYYY-MM-DD') : null)}
                                                                disabledDate={afterToday!()} />

                                                        </div>
                                                    </div>
                                                    </Form>

                                                </Panel>
                                            </div> : null}

                                            {filterType.name == 'trended' || filterType.name == 'similarClients'  ? <div>
                                                <button
                                                    className={isCustomChecked(filterType.name as keyof CustomFilters) ? "checkbox active" : "checkbox"}
                                                    onClick={() => addCustomFilter(filterType.name, null, null, null)}
                                                >
                                                        <span className={"value"}>
                                                            {t(`customFilters.${filterType.name}`)}
                                                        </span>
                                                </button>
                                            </div> : null}

                                            {filterType.name == 'recentlyViewed' ? <div>
                                                <button
                                                    className={isCustomChecked(filterType.name as keyof CustomFilters) ? "checkbox active" : "checkbox"}
                                                    onClick={() => addCustomFilter(filterType.name, null, null, null)}
                                                >
                                                        <span className={"value"}>
                                                            {t('recentlyViewed')}
                                                        </span>
                                                </button>
                                            </div> : null}

                                        </div>
                                    )
                                }
                            </PanelGroup>

                        </div> : ''}

                    </div>
                </Sidenav.Body>
                {filterState ? <div>
                    <div className={"overflow filters"} onClick={closeFiltersMobile}></div>
                    <button className={"close-filters-mobile"}  onClick={closeFiltersMobile}><span></span></button>
                </div> : ''}
            </Sidenav>

    )
}
export default Filters;