import React, {useEffect, useState} from "react";

import "./MyOrdersPage.sass"
import Header from "../../blocks/Header/Header";
import GenericButton from "../../components/Buttons/GenericButton";
import {Button, Dropdown, FlexboxGrid, Input, InputGroup, Pagination} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import MyOrdersTable from "../../components/MyOrdersTable/MyOrdersTable";
import Footer from "../../blocks/Footer";
import {useTranslation} from "react-i18next";
import GetOrderModel from "../../dto/order/GetOrderModel";
import orderService from "../../services/orderService";
import Paging from "../../interfaces/Pagination";
import SearchBlock from "../../components/SearchBlock/SearchBlock";
import {OrderStatus} from "../../enums/OrderStatus";
import TableBottom from "../../components/TableBottom/TableBottom";
import {useNavigate} from "react-router-dom";

const MyOrdersPage:React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [myOrders, setMyOrders] = useState<GetOrderModel[]>([])

    const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([])

    //let selectedOrderIds: number[] = [];

    const [search, setSearch] = useState('')
    const [downloadState, setDownloadState] = useState(true)
    /*const [pageSize, setPageSize] = useState('')
    const onChangePageSizeKey = (key: string, event: any) => {
        setPaging({...paging, pageSize: +key})
        setPageSize(key)
    }*/

    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const changePageSize = (key: number) => {
        setPageSize(key)
    }
    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }

    useEffect(() => {
        orderService.getOrders(search, currentPage, pageSize).then(data => {
            setMyOrders(data.items);
            setTotalItems(data.totalItemCount);
        })
    }, [pageSize, currentPage])

    const help = () => {
        navigate('/help')
    }

    const searchMyOrders = (value: string, event: any) => {
            setCurrentPage(1)
            orderService.getOrders(value, currentPage, pageSize, false).then(data =>{
                setMyOrders(data.items)
                setTotalItems(data.totalItemCount);
            })

    }



    const downloadOrders = () => {
        //const selectedOrders = myOrders.filter(order => selectedOrderIds.includes(order.id));

        orderService.getOrdersExel(selectedOrderIds).then(blob => {

            const url = window.URL.createObjectURL(
                blob,
            );
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "orders.xlsx");
            document.body.appendChild(link); // Required for FF
            link.click();
            document.body.removeChild(link);
        })

        /*let csv = "data:text/csv;charset=utf-8,";
        const universalBOM = "\uFEFF";
        csv += universalBOM + `${t('orderCsv.orderNumber')},${t('orderCsv.orderDate')},${t('orderCsv.orderStatus')},${t('orderCsv.orderAmount')},${t('orderCsv.orderProfit')},${t('orderCsv.orderShipping')},${t('orderCsv.orderShippingInvoice')}\n`;
        csv += selectedOrders.map(order => {
            const profit = order.totalProfitUSD * order.exchangeRate;
            const status = order.status as OrderStatus;
            let shipping = order.deliveryAddress.state ? order.deliveryAddress.state + ',' : '' +
            order.deliveryAddress.district ? order.deliveryAddress.district + ',' : '' +
            order.deliveryAddress.city ? order.deliveryAddress.city + ',' : '' +
            order.deliveryAddress.carrierBranch ? order.deliveryAddress.carrierBranch + ',' : '' +
            order.deliveryAddress.street ? order.deliveryAddress.street + ',' : '' +
            order.deliveryAddress.house ? order.deliveryAddress.house + ',' : '' +
            order.deliveryAddress.apartment ? order.deliveryAddress.apartment + ',' : '' +
            order.carModel ? order.carModel + ',' : '' +
            order.carNumber ? order.carNumber + ',' : '' +
            order.pickupDate ? order.pickupDate + ',' : '' +
            order.pickupTime ? order.pickupTime : '';
            let shippingArray = Array.from(shipping);
            shipping = shippingArray[shippingArray.length - 1] == ','? shippingArray.slice(0, -1).join(''):
                shippingArray.join('');
            return `${order.id},${order.orderDate} ${order.orderTime},${status},${order.totalAmount},${profit},"${shipping}",${order.shippingInvoiceNumber ? order.shippingInvoiceNumber: ''}`;
        }).join("\n");
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "orders.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);*/
    }
    return (
        <div className="my-orders-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <h2>{t('myOrders.myOrdersTitle')}</h2>
                    <GenericButton generic={"help"} onClick={help} isDisabled={false} showText={true} />

                    <SearchBlock
                        search={searchMyOrders}
                        searchPlaceHolder={t('myOrders.search')}
                        buttonClick={downloadOrders}
                        buttonText={t('order.downloadOrders')}
                        buttonState={downloadState}
                    />

                    <div className="main-table">
                        <MyOrdersTable data={myOrders} onOrderSelectionChange={ ids => setSelectedOrderIds(ids)  } orderType={"order"} setButtonState={setDownloadState} />
                    </div>

                    <TableBottom
                        onPageSizeChange={changePageSize}
                        onActivePageChange={changeActivePage}
                        totalItemCount={totalItems}
                        pageSize={pageSize}
                        currentPage={currentPage}
                    />

                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default MyOrdersPage