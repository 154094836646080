import React, {useEffect, useState} from "react";
import Header from "../../../blocks/Header/Header";
import SearchBlock from "../../../components/SearchBlock/SearchBlock";
import MyReserveTable from "../../../components/MyOrdersTable/MyReserveTable";
import TableBottom from "../../../components/TableBottom/TableBottom";
import Footer from "../../../blocks/Footer";
import {useTranslation} from "react-i18next";
import GetOrderModel from "../../../dto/order/GetOrderModel";
import Paging from "../../../interfaces/Pagination";
import orderService from "../../../services/orderService";

const AllReserve:React.FC = () => {
    const {t} = useTranslation();

    const [myOrders, setMyOrders] = useState<GetOrderModel[]>([])
    const [paging, setPaging] = useState<Paging>({
        totalPageCount: 1,
        totalItemCount: 1,
        currentPageNumber: 1,
        pageSize: 25
    })
    const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([])

    const [downloadButtonState, setDownloadButtonState] = useState(true)

    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const changePageSize = (key: number) => {
        setPageSize(key)
    }
    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }

    useEffect(() => {
        orderService.getOrders(search, currentPage, pageSize, true).then(data => {
            setMyOrders(data.items)
            setPaging({
                totalPageCount: data.totalPageCount,
                totalItemCount: data.totalItemCount,
                currentPageNumber: data.currentPageNumber,
                pageSize: data.pageSize
            })
            setTotalItems(data.totalItemCount);
        })
    }, [pageSize, currentPage, search])

    const setActivePage = (page: number) => {
        setPaging({...paging, currentPageNumber: page})
    }

    const searchMyOrders = (value: string, event: any) => {
        setSearch(value)
    }

    const downloadOrders = () => {

        orderService.getOrdersExel(selectedOrderIds).then(blob => {

            const url = window.URL.createObjectURL(
                blob,
            );
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "reserves.xlsx");
            document.body.appendChild(link); // Required for FF
            link.click();
            document.body.removeChild(link);
        })

    }

    const deleteItem = (id: number) => {
        orderService.cancelOrder(+id).then(() => {

            orderService.getOrders(search, currentPage, pageSize, true).then(data => {
                setMyOrders(data.items)
                setPaging({
                    totalPageCount: data.totalPageCount,
                    totalItemCount: data.totalItemCount,
                    currentPageNumber: data.currentPageNumber,
                    pageSize: data.pageSize
                })
                setTotalItems(data.totalItemCount);
            })
        })
    }
    return(
        <div className="my-orders-page-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <h2>{t('myOrders.myReserveTitle')}</h2>

                    <SearchBlock
                        search={searchMyOrders}
                        searchPlaceHolder={t('myOrders.search')}
                        buttonClick={downloadOrders}
                        buttonText={t('order.downloadOrders')}
                        buttonState={downloadButtonState}
                    />
                    <div className="main-table">
                        <MyReserveTable
                            data={myOrders}
                            onOrderSelectionChange={ ids => setSelectedOrderIds(ids) }
                            setButtonState={setDownloadButtonState}
                            deleteItem={deleteItem}
                        />
                    </div>

                    <TableBottom
                        onPageSizeChange={changePageSize}
                        onActivePageChange={changeActivePage}
                        totalItemCount={totalItems}
                        pageSize={pageSize}
                        currentPage={currentPage}
                    />

                    {/*<FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="show-items-wrapper">
                            {t('order.showProducts')}:
                            <Dropdown onSelect={onChangePageSizeKey} activeKey={pageSize} title={paging.pageSize}>
                                <Dropdown.Item eventKey={25} onSelect={onChangePageSizeKey}>25</Dropdown.Item>
                                <Dropdown.Item eventKey={50} onSelect={onChangePageSizeKey}>50</Dropdown.Item>
                                <Dropdown.Item eventKey={75} onSelect={onChangePageSizeKey}>75</Dropdown.Item>
                                <Dropdown.Item eventKey={100} onSelect={onChangePageSizeKey}>100</Dropdown.Item>
                                <Dropdown.Item eventKey={125} onSelect={onChangePageSizeKey}>125</Dropdown.Item>
                            </Dropdown>
                        </FlexboxGrid.Item>

                        <Pagination
                            prev
                            last
                            next
                            first
                            maxButtons={5}
                            className={"pagination-wrapper"}
                            total={paging.totalItemCount}
                            limit={paging.pageSize}
                            activePage={paging.currentPageNumber}
                            onChangePage={setActivePage} />
                    </FlexboxGrid>*/}

                </div>
            </main>
        </div>
    )
}
export default AllReserve;